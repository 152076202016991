import styled from "styled-components"
import { Svg } from "./Svg"

interface Props {
    type?: 'text' | 'password',
    placeholder?: string
    children?: any
    icon?: 'email' | 'password' | 'none'
    onChange?: (value: string) => void
    required?: boolean
    invalid?: boolean
}
export const Input = ({ 
    type = 'text',
    placeholder = 'Placeholder',
    icon = 'none',
    onChange,
    required,
    invalid
}: Props) => {

    return (
        <StyledInput className={invalid ? '-invalid' : ''}>
            <input 
                type={type} 
                placeholder={placeholder}
                onChange={e => {
                    if (onChange) {
                        onChange(e.target.value)
                    }
                }} 
                required={required}
            />
            <Svg id={icon} />
        </StyledInput>
    )
}

const StyledInput = styled.div`
    width: 306px;
    height: 54px;
    padding: 15px 18px;
    position: relative;
    color: white;

    &.-invalid {
        input {
            border-color: #7f0000 !important;
        }
    }

    input {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        color: white;
        padding: 15px 18px;
        border: 1px solid #353535b3;
        border-radius: 3px;

        &:focus {
            border-color: #c8aa876e;
        }
    }

    svg {
        width: 26px;
        height: 26px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 24px;
        pointer-events: none;
    }
`