import { Text } from "@react-three/drei"

interface Props {
    children?: any
    position?: [number, number, number]
    fontSize?: number
    color?: number | string
    textAlign?: 'right' | 'left' | 'center' | 'justify'
    anchorX?: number | 'right' | 'center' | 'left'
    maxWidth?: number,
}
export const UiText = ({ 
    position = [0, 0, 0], 
    fontSize = 14,
    color = 'red',
    textAlign,
    children,
    anchorX,
    maxWidth
}: Props) => {
    return (
        <Text
            maxWidth={maxWidth}
            textAlign={textAlign}
            position={position}
            color={color} 
            fontSize={fontSize}
            anchorX={anchorX}
        >
            { children }
        </Text>
    )
}