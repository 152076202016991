import { Slots } from "../Slots/Slots"
import { MutableRefObject, useEffect, useMemo, useRef } from "react"
import { useFighter } from "views/Game/GamePlay/Fighter/useFighter"
import { UiText } from "../components/UiText"
import { useCloud } from "EventCloud/useCloud"
import { useUi } from "../useUi"
import { Plane, useTexture } from "@react-three/drei"
import { useSkillsSlotsTextures } from "./Skills/useSkillsTextures"
import * as THREE from 'three'

interface Props {
    relatedNodes?: Array<MutableRefObject<any>>
    position?: [number, number, number]
}
export const Consumables = ({ relatedNodes = [], position }: Props) => {
    const relatedNode = useRef<THREE.Group>()
    const figher = useFighter(state => state.fighter)
    const cellSize = 45
    const gap = 0
    const nPos: [number, number, number] = [-cellSize/2+10, cellSize/2-10, 0]
    const [consumeBackpackItem] = useCloud(state => [state.consumeBackpackItem])

    const maps = useSkillsSlotsTextures()
    const slotMaps = useMemo(() => ({
        COMMON: maps.skillSlot,
        INSERT_ALLOWED: maps.skillSlotHover,
        INSERT_DISALLOWED: maps.skillSlot,
        LAST_PLACEHOLDER: maps.skillSlot, 
    }), [maps])

    useEffect(() => {
        const node = useUi.getState().eventsNode.current
        if (!node) { return console.warn('[Consumables]: eventsNode not found') }
        node.addEventListener('keydown', subscribeKeys)
        function subscribeKeys(event: KeyboardEvent) {
            const cons = useFighter.getState().fighter.consumables
            const key = event.code.toLowerCase()
            if (key === 'keyq') {
                cons.Q && consumeBackpackItem(cons.Q.itemHash)
                return
            }
            if (key === 'keyw') {
                cons.W && consumeBackpackItem(cons.W.itemHash)
                return
            }
            if (key === 'keye') {
                cons.E && consumeBackpackItem(cons.E.itemHash)
                return
            }
            if (key === 'keyr') {
                cons.R && consumeBackpackItem(cons.R.itemHash)
                return
            }
        }
        return () => node.removeEventListener('keydown', subscribeKeys)
    }, [])

    return (
        <group ref={relatedNode} position={position}>
            <Slots
                id="ID_CONSUMABLES_Q"
                type='backpack'
                enableRender={true}
                cellWidth={cellSize}
                cellHeight={cellSize}
                position={[0, 0, 0]}
                grid={[[!!figher.consumables.Q]]}
                // @ts-expect-error
                items={figher.consumables.Q ? {'0,0': figher.consumables.Q} : {}}
                width={1}
                height={1}
                relatedNodes={[relatedNode, ...relatedNodes]}
                maps={slotMaps}
                itemBoxPlacement="top"
                events={[
                    { id: '', type: 'doubleClick', handler: (props) => consumeBackpackItem(props.item.itemHash) },
                ]}
            >
                <ConsInfo text="Q" />
            </Slots>
            <Slots
                id="ID_CONSUMABLES_W"
                type='backpack'
                enableRender={true}
                cellWidth={cellSize}
                cellHeight={cellSize}
                position={[cellSize + gap, 0, 0]}
                grid={[[!!figher.consumables.W]]}
                // @ts-expect-error
                items={figher.consumables.W ? {'0,0': figher.consumables.W} : {}}
                width={1}
                height={1}
                relatedNodes={[relatedNode, ...relatedNodes]}
                maps={slotMaps}
                itemBoxPlacement="top"
                events={[
                    { id: '', type: 'doubleClick', handler: (props) => consumeBackpackItem(props.item.itemHash) },
                ]}
            >
                <ConsInfo text="W" />
            </Slots>
            <Slots
                id="ID_CONSUMABLES_E"
                type='backpack'
                enableRender={true}
                cellWidth={cellSize}
                cellHeight={cellSize}
                position={[(cellSize + gap)*2, 0, 0]}
                grid={[[!!figher.consumables.E]]}
                // @ts-expect-error
                items={figher.consumables.E ? {'0,0': figher.consumables.E} : {}}
                width={1}
                height={1}
                relatedNodes={[relatedNode, ...relatedNodes]}
                maps={slotMaps}
                itemBoxPlacement="top"
                events={[
                    { id: '', type: 'doubleClick', handler: (props) => consumeBackpackItem(props.item.itemHash) },
                ]}
            >
                <ConsInfo text="E" />
            </Slots>
            <Slots
                id="ID_CONSUMABLES_R"
                type='backpack'
                enableRender={true}
                cellWidth={cellSize}
                cellHeight={cellSize}
                position={[(cellSize + gap)*3, 0, 0]}
                grid={[[!!figher.consumables.R]]}
                // @ts-expect-error
                items={figher.consumables.R ? {'0,0': figher.consumables.R} : {}}
                width={1}
                height={1}
                relatedNodes={[relatedNode, ...relatedNodes]}
                maps={slotMaps}
                itemBoxPlacement="top"
                events={[
                    { id: '', type: 'doubleClick', handler: (props) => consumeBackpackItem(props.item.itemHash) },
                ]}
            >
                <ConsInfo text="R" />
            </Slots>
        </group>
    )
}

interface ConsProps {
    width?: number
    height?: number
    circleWidth?: number
    circleHeight?: number
    text: string
}
const ConsInfo = ({
    width = 48,
    height = 48,
    circleWidth = 28,
    circleHeight = 28,
    text
}: ConsProps) => {
    const idBgMap = useTexture('/assets/bottom-menu/id-bg.png')
    return (
        <Plane position={[ /*-width/2+circleWidth/8*/0, height/2-circleHeight/8, 10 ]} args={[circleWidth, circleHeight]}>
        <meshBasicMaterial map={idBgMap} transparent={true} />
        <UiText position={[0, 0, 10]} fontSize={12} color={'#C2AD62'}>{ text }</UiText>
    </Plane>
    )
}