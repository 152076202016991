import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { EventCloudProvider } from 'EventCloud/EventCloud';

import { Loader } from "@react-three/drei"

import { Experience } from 'views/Game/Experience';

import styled from 'styled-components'
import { useLayout } from 'Layout';
import { useCloud } from 'EventCloud/useCloud';
import { Scene } from './Scene';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import { ItemAttributes } from 'interfaces/item.interface';

interface UseWiki {
    items: Record<string, ItemAttributes>
    setItems: (items: Record<string, ItemAttributes>) => void
}
export const useWiki = createWithEqualityFn<UseWiki>((set, get) => ({
    items: {},
    setItems: (items) => set({ items })
}), shallow)

export const Wiki = () => {
    const readyState = useCloud(state => state.readyState)
    const { sessionId } = useLayout()

    if (!sessionId) return null

    return (
		<EventCloudProvider>
			<DndProvider backend={HTML5Backend}>
                {
                    readyState ? (<>
                        <StyledScene id="scene" tabIndex={0}>
                            <Experience/>
                            <Scene/>
                            <Loader />
                        </StyledScene>
                    </>) : null
                }
			</DndProvider>
		</EventCloudProvider>
	);
}

const StyledScene = styled.div`
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border: none !important;
    outline: none !important;

    > div {
        width: 100%;
        height: 100%;
    }
`