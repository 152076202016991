import styles from './UserInterface2D.module.scss'

import { Leva } from 'leva'

import CommandLine from './CommandLine/CommandLine'
import Stats from './Stats/Stats'
import Settings from './Settings/Settings'

import OpenButton from 'core/OpenButton'
import { GoldEnter } from './GoldEnter/GoldEnter'

import { useOtherFighter } from 'views/Game/GamePlay/Fighter/Players/useOtherFighter'
import { useFighter } from 'views/Game/GamePlay/Fighter/useFighter'
import { useCore } from '../useCore'
import { Marketplace } from './Marketplace/Marketplace'
import { PartyIndicators } from './PartyIndicators/PartyIndicators'

const UserInterface2D = () => {
    const [otherFighterList] = useOtherFighter(state => [state.otherFighterList])
    const fighter = useFighter(state => state.fighter)
    const [worldSize, matrixCoordToWorld] = useCore(state => [state.worldSize, state.matrixCoordToWorld])

    // const handleClick = (e) => {
    //     e.preventDefault()
    //     e.stopPropagation()
    //     console.log('User Click')
    // }

    return (
        <div className={styles.UserInterface2D} >
            <Stats/>
            <CommandLine/>
            <Settings/>
            <GoldEnter/>
            <Marketplace/>

            {/* Auth Modal */}
            <OpenButton/>

            <PartyIndicators/>


            {/* For test */}
            {
                otherFighterList.length && (
                    <div className={styles.players}>
                        <p>Close Players({otherFighterList.length}):</p>
                        { otherFighterList.map(_ => (<p key={_.id}>{ `${_.name} [${_.coordinates.x}, ${_.coordinates.z}]` }<span></span></p>)) }
                    </div>
                )
            }
            <div className={styles.coordinates}>
                <div>World size [{worldSize}x{worldSize}]</div>
                {/* <div>Server coordinate [ X: {store.currentMatrixCoordinate?.x} Z: {store.currentMatrixCoordinate?.z} ]</div> */}
                {fighter?.coordinates && <div>Coordinate [ X: {matrixCoordToWorld(fighter.coordinates)?.x/*+60-.5*/} Z: {matrixCoordToWorld(fighter.coordinates)?.z/*+60-.5*/} ]</div>}
                <div>Draw Calls <span id="drawCalls"></span></div>
                <div>Draw Triangles <span id="drawTriangles"></span></div>
                <div>Draw Points <span id="drawPoints"></span></div>

            </div>
            <Leva
                // hidden={!isBackpackOpened}
                collapsed
                flat
            />
        </div>
    )
}

export default UserInterface2D