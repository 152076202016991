import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow';
import { useFighter } from 'views/Game/GamePlay/Fighter/useFighter';

import type { JsonValue } from './hooks/useWorkerWebSocket';
import type { Equipment } from "interfaces/equipment.interface";
import type { Fighter } from 'interfaces/fighter.interface';
import type { Skill } from 'interfaces/skill.interface';
import type { Direction } from 'interfaces/direction.interface';
import type { Inventory } from "interfaces/inventory.interface";
import type { Coordinate } from "interfaces/coordinate.interface";
import type { MapObject } from 'interfaces/mapObject.interface';
import type { Trade } from 'interfaces/trade.interface';


export type TargetType = { target: Fighter | null, skill: Skill | null }
export interface MarketplaceItemPrice {
    price_gold: number
    price_nefesh: number
    price_ruach: number
    price_neshamah: number
    price_haya: number
}

export interface CloudStoreInterface {
    readyState: boolean
    sendJsonMessage: (jsonMessage: JsonValue) => void | null
    init: (sendJsonMessage: (jsonMessage: JsonValue) => void) => void

    // Auth
    account: string
    setAccount: (account: string) => void
    sendAuth: (target: any) => void
    userFighters: Fighter[]
    setUserFighters: (userFighters: Fighter[]) => void
    fetchUserFighters: () => void
    createFighter: (fighterClass: string, name: string) => void

    // Players
    playerList: Fighter[]
    setPlayerList: (playerList: Fighter[]) => void

    // Inventory
    backpack: Inventory | null
    updateBackpack: (backpack: Inventory) => void
    equipment: Equipment | null
    updateEquipment: (equipment: Equipment) => void
    vault: Inventory | null
    updateVault: (vault: Inventory) => void
    requestVault: () => void
    shop: Inventory | null
    updateShop: (vault: Inventory) => void
    requestShop: () => void

    // Trade
    trade: Trade | null
    updateTrade: (trade: Trade) => void
    requestTrade: (playerID: string) => void
    approveTrade: () => void
    cancelTrade: () => void
    addTradeItem: (itemHash: string) => void
    addTradeItemToPosition: (itemHash: string, position: { x: number; z: number }) => void
    moveTradeItem: (itemHash: string, position: { x: number; z: number }) => void
    removeTradeItem: (itemHash: string) => void
    removeTradeItemToBackpack: (itemHash: string, position: { x: number; z: number }) => void
    setTradeGold: (amount: number) => void
    removeTradeItemToEquipment: (itemHash: string, slot: number) => void

    // User Events
    updateItemBackpackPosition: (itemHash: string, position: { x: number; z: number }) => void
    dropBackpackItem: (itemHash: string, coordinate: { x: number; z: number }) => void
    unequipBackpackItem: (itemHash: string, position: { x: number; z: number }) => void
    equipBackpackItem: (itemHash: string, slot: number) => void
    buyItemShop: (itemHash: string) => void
    dropVaultItem: (itemHash: string, coordinate: { x: number; z: number }) => void
    moveItemFromBackpackToVault: (itemHash: string, position: { x: number; z: number }) => void
    moveItemFromVaultToBackpack: (itemHash: string, position: { x: number; z: number }) => void
    updateItemVaultPosition: (itemHash: string, position: { x: number; z: number }) => void
    unequipVaultItem: (itemHash: string, position: { x: number; z: number }) => void
    equipVaultItem: (itemHash: string, slot: number) => void
    dropEquippedItem: (itemHash: string, coordinate: { x: number; z: number }) => void
    moveGoldFromVaultToBackpack: (amount: number) => void
    moveGoldFromBackpackToVault: (amount: number) => void
    consumeBackpackItem: (itemHash: string) => void
    bindSkill: (skillId: number, key: number) => void
    upgradeItemOption: (itemHash: string, jewelHash: string) => void
    upgradeItemLevel: (itemHash: string, jewelHash: string) => void
    requestAllItems: () => void
    listItemToMarketplace: (itemHash: string, price: MarketplaceItemPrice) => void
    unlistItemFromMarketplace: (itemHash: string) => void
    moveItemFromVaultToWarehouse: (itemHash: string) => void
    moveItemFromWarehouseToVault: (itemHash: string) => void

    // Stats
    statsAddStrength: () => void
    statsAddAgility: () => void
    statsAddEnergy: () => void
    statsAddVitality: () => void

    // Attack
    target: TargetType
    setTarget: (target: Fighter, skill: Skill) => void
    selectedSkill: number
    setSelectedSkill: (skill: number) => void
    submitAttack: (direction: Direction, target: TargetType) => void

    // Fighter
    moveFighter: (coordinate: Coordinate) => void
    updateFighterDirection: (direction: Direction) => void

    // Server Game Events
    events: any[]
    addEvent: (event: any) => void
    removeEvent: (event: any) => void

    // Items
    pickupDroppedItem: (event) => void
    refreshFighterItems: () => void

    // Command Line
    sendCommand: (text: string) => void

    // Chat
    chatLog: any[]
    setChatLog: (chatLog: {}) => void

    // Different
    lockedCoordinates: Coordinate[]
    setLockedCoordinates: (lockedCoordinates: Coordinate[]) => void
    mapAddLockedCoord: (coordinate: Coordinate) => void
}

export const useCloud = createWithEqualityFn<CloudStoreInterface>((set, get) => {
    const sendMessage = (type: string, data: any) => {
        const { sendJsonMessage } = get();
        // console.log('sendMessage', type, data)
        sendJsonMessage({ type, data });
    };

    return {
        readyState: false,
        sendJsonMessage: null,
        init: (sendJsonMessage) => set(() => ({ sendJsonMessage, readyState: true })),

        account: '',
        setAccount: (account) => set({ account }),
        sendAuth: (target) => {
            const { account } = get();
            sendMessage("auth", { playerID: target, userAddress: account, locationHash: "lorencia_0_0" });
        },
        userFighters: [],
        setUserFighters: (userFighters) => set({ userFighters }),
        fetchUserFighters: () => sendMessage("get_user_fighters", {}),
        createFighter: (fighterClass, name) => sendMessage("create_fighter", { fighterClass, name }),

        playerList: [],
        setPlayerList: (playerList) => set({ playerList }),

        backpack: null,
        equipment: null,
        vault: null,
        updateVault: (vault) => set({ vault }),
        requestVault: () => sendMessage("get_vault", {}),

        shop: null,
        updateShop: (shop) => set({ shop }),
        requestShop: () => sendMessage("get_shop", { shopName: 'potion_girl' }),
        buyItemShop: (itemHash) => sendMessage("buy_item", { shopName: 'potion_girl', itemHash }),

        updateEquipment: (equipment) => set({ equipment }),
        updateBackpack: (backpack) => set({ backpack }),

        moveItemFromBackpackToVault: (itemHash, position) => sendMessage("move_item_from_backpack_to_vault", { itemHash, position }),
        moveItemFromVaultToBackpack: (itemHash, position) => sendMessage("move_item_from_vault_to_backpack", { itemHash, position }),
        updateItemVaultPosition: (itemHash, position) => sendMessage("update_vault_item_position", { itemHash, position }),
        dropVaultItem: (itemHash, coordinates) => sendMessage("drop_vault_item", { itemHash, coordinates }),
        dropEquippedItem: (itemHash, coordinates) => sendMessage("drop_equipped_item", { itemHash, coordinates }),

        updateItemBackpackPosition: (itemHash, position) => sendMessage("update_backpack_item_position", { itemHash, position }),
        dropBackpackItem: (itemHash, coordinates) => sendMessage("drop_backpack_item", { itemHash, coordinates }),
        equipBackpackItem: (itemHash, slot) => sendMessage("equip_backpack_item", { itemHash, slot }),
        unequipBackpackItem: (itemHash, position) => sendMessage("unequip_backpack_item", { itemHash, position }),
        equipVaultItem: (itemHash, slot) => sendMessage("equip_vault_item", { itemHash, slot }),
        unequipVaultItem: (itemHash, position) => sendMessage("unequip_vault_item", { itemHash, position }),

        upgradeItemOption: (itemHash, jewelHash) => sendMessage("upgrade_item_option", { item_hash: itemHash, jewel_hash: jewelHash }),
        upgradeItemLevel: (itemHash, jewelHash) => sendMessage("upgrade_item_level", { item_hash: itemHash, jewel_hash: jewelHash }),

        moveGoldFromVaultToBackpack: (amount) => sendMessage("move_gold_from_vault_to_backpack", { amount }),
        moveGoldFromBackpackToVault: (amount) => sendMessage("move_gold_from_backpack_to_vault", { amount }),
        consumeBackpackItem: (itemHash) => sendMessage("consume_backpack_item", { itemHash }),
        bindSkill: (skillId, key) => sendMessage("skill_bind", { skill: skillId, key }),

        requestAllItems: () => sendMessage("list_all_game_items", {}),

        statsAddStrength: () => sendMessage("stats_add_strength", { amount: 1 }),
        statsAddAgility: () => sendMessage("stats_add_agility", { amount: 1 }),
        statsAddEnergy: () => sendMessage("stats_add_energy", { amount: 1 }),
        statsAddVitality: () => sendMessage("stats_add_vitality", { amount: 1 }),

        trade: null,
        updateTrade: (trade) => set({ trade }),
        requestTrade: (playerID) => sendMessage("trade_initiate", { player_id: playerID }),
        approveTrade: () => sendMessage("trade_approve", {}),
        cancelTrade: () => sendMessage("trade_cancel", {}),
        moveTradeItem: (itemHash, position) => sendMessage("trade_move_item", { item_hash: itemHash, position }),
        addTradeItem: (itemHash) => sendMessage("trade_add_item", { item_hash: itemHash }),
        addTradeItemToPosition: (itemHash, position) => sendMessage("trade_add_item_to_position", { item_hash: itemHash, position }),
        removeTradeItem: (itemHash) => sendMessage("trade_remove_item", { item_hash: itemHash }),
        removeTradeItemToBackpack: (itemHash, position) => sendMessage("trade_remove_item_to_backpack", { item_hash: itemHash, position }),
        setTradeGold: (amount) => sendMessage("trade_set_gold", { amount }),
        removeTradeItemToEquipment: (itemHash, slot) => sendMessage("trade_remove_item_to_equipment", { itemHash, slot }),
        listItemToMarketplace: (itemHash, price) => sendMessage("list_item_to_marketplace", { item_hash: itemHash, ...price }),
        unlistItemFromMarketplace: (itemHash) => sendMessage("unlist_item_from_marketplace", { item_hash: itemHash }),
        moveItemFromVaultToWarehouse: (itemHash) => sendMessage("move_item_from_vault_to_warehouse", { item_hash: itemHash }),
        moveItemFromWarehouseToVault: (itemHash) => sendMessage("move_item_from_warehouse_to_vault", { item_hash: itemHash }),

        target: { target: null, skill: null },
        setTarget: (target, skill) => set({ target: { target, skill } }),
        selectedSkill: 4,
        setSelectedSkill: (skill) => set({ selectedSkill: skill }),
        submitAttack: (direction, target) => {
            if (!target?.target?.id) return;
            sendMessage("submit_attack", {
                opponentID: target.target.id.toString(),
                playerID: useFighter.getState().fighter.tokenId.toString(),
                skill: target.skill.skillId,
                direction
            });
        },

        moveFighter: ({ x, z }) => sendMessage("move_fighter", { x, z }),
        updateFighterDirection: (direction) => sendMessage("update_fighter_direction", { direction }),

        events: [],
        addEvent: (event) => set(state => ({ events: [...state.events, event] })),
        removeEvent: (event) => set(state => ({ events: state.events.filter((e) => e !== event) })),

        pickupDroppedItem: (event) => sendMessage("pickup_dropped_item", { itemHash: event.itemHash }),
        refreshFighterItems: () => sendMessage("get_fighter_items", { fighterId: parseInt(useFighter.getState().fighter.tokenId as any) }),

        sendCommand: (text) => sendMessage("message", { text }),

        chatLog: [],
        setChatLog: (chatLog) => set(state => ({ chatLog: [...state.chatLog, chatLog] })),

        lockedCoordinates: [],
        setLockedCoordinates: (lockedCoordinates) => set({ lockedCoordinates }),
        mapAddLockedCoord: (coordinate) => sendMessage("map_add_locked_coord", { coord: coordinate })
    };
}, shallow)