import 'rc-slider/assets/index.css'
import { createRoot } from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"

import { Game } from 'views/Game'
import { Auth } from 'views/Auth'
import { Layout } from 'Layout'
import { Wiki } from 'views/Wiki'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Game />,
      },
      {
        path: 'auth',
        element: <Auth />
      },
      {
        path: 'wiki',
        element: <Wiki/>
      }
    ]
  },
]);

const root = createRoot(document.getElementById('root'))
root.render(<RouterProvider router={router} />)