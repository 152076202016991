import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { shallow } from "zustand/shallow"
import { createWithEqualityFn } from "zustand/traditional"

interface UseLoader {
    showen: boolean
    show: (showen: boolean) => void
}
export const useLoader = createWithEqualityFn<UseLoader>((set, get) => ({
    showen: false,
    show: showen => set({ showen })
}), shallow)

export const Loader = () => {
    const showen = useLoader(state => state.showen)
    const [delayedShowen, setDelayedShowen] = useState(showen)
    const [styles, setStyles] = useState({})
    const timeout = useRef<any>()
    useEffect(() => {
        clearTimeout(timeout.current)
        if (showen) {
            setDelayedShowen(true)
            timeout.current = setTimeout(() => {
                setStyles({
                    opacity: 1,
                    visibility: 'visible',
                    pointerEvents: 'all'
                })
            }, 50)
            return
        }
        setStyles({})
        timeout.current = setTimeout(() => {
            setDelayedShowen(false)
        }, 350)
    }, [showen])

    if (!delayedShowen) return null

    return (
        <StyledLoader style={styles}>
            <span className="loader"></span>
        </StyledLoader>
    )
}

const StyledLoader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, .8);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity .3s ease, visibility .3s ease;

    .loader {
        width: 48px;
        height: 48px;
        display: inline-block;
        position: relative;
    }
    .loader::after,
    .loader::before {
        content: '';  
        box-sizing: border-box;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: #ffd0b5;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        animation: animloader 2s linear infinite;
    }
    .loader::after {
        animation-delay: 1s;
    }

    @keyframes animloader {
        0% {
            transform: scale(0);
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity: 0;
        }
    }
`