import * as THREE from 'three'
import { RefObject, createRef } from "react";
import { create } from "zustand";
import { useCloud } from 'EventCloud/useCloud';
import { Fighter } from 'interfaces/fighter.interface';

export interface UiStoreInterface {
    // HTML element which used for additional event styling, scene parent
    eventsNode: RefObject<HTMLDivElement | null>

    // Set cursor state
    setCursor: (property: 'pointer' | 'grabbing' | 'default') => void

    // User Interface 3d Object
    userInterface: RefObject<THREE.Group | null>
    // Plane which we raycasts, based on which all mouse events in ui
    intersectionPlane: RefObject<THREE.Mesh | null>

    // Events
    pressedKeys: Set<string>
    _keyDownHandler: (event: KeyboardEvent) => void
    _keyUpHandler: (event: KeyboardEvent) => void
    _updatePressedKeys: (key: string, action: 'add' | 'remove') => void
    subscribePressedKeys: () => void
    unsubscribePressedKeys: () => void
    _blurHandler: () => void
    _visibilityChangeHandler: () => void

    // Backpack slots
    cellSize: number

    // Open/close functionality
    isOpened: boolean
    open: () => void
    close: () => void
    toggle: () => void

    // Vault
    isOpenedVault: boolean
    openVault: () => void
    closeVault: () => void
    toggleVault: () => void

    // Shop
    isOpenedShop: boolean
    openShop: () => void
    closeShop: () => void
    toggleShop: () => void

    // Trade
    isOpenedTrade: boolean
    openTrade: () => void
    closeTrade: () => void
    toggleTrade: () => void

    // Marketplace
    isOpenedMarketplace: boolean
    openMarketplace: () => void
    closeMarketplace: () => void
    toggleMarketplace: () => void


    // ContextMenu
    isOpenedContextMenu: boolean
    openContextMenu: () => void
    closeContextMenu: () => void
    toggleContextMenu: () => void
    contextMenuFighter: Fighter | null
    setContextMenuFighter: (contextMenuFighter: Fighter | null) => void
    contextMenuCoords: { x: number, y: number } | null
    setContextMenuCoords: (contextMenuCoords: { x: number, y: number } | null) => void 



    // Slots plane, stores Backpack data in <userData>, used by backpack drag system
    slots: RefObject<{[key: number]: THREE.Mesh}>
    equipmentSlots: RefObject<{[key: number]: THREE.Mesh}>

    // Buttons
    subscribeBackpack: () => void
    unsubscribeBackpack: () => void
    _handleKeypress: (e: KeyboardEvent) => void
}

export const useUi = create<UiStoreInterface>((set, get) => ({
    eventsNode: createRef(),
    setCursor: (property) => {
        const eventsNode = useUi.getState().eventsNode.current
        if (!eventsNode) { return }
        if (property === 'default') {
            eventsNode.style.removeProperty('cursor')
            return
        }
        eventsNode.style.cursor = property
    },
    userInterface: createRef(),
    intersectionPlane: createRef(),

    pressedKeys: new Set<string>(),
    _keyDownHandler: event => {
      const key = event.key.toLowerCase();
      set(state => ({ pressedKeys: new Set(state.pressedKeys).add(key) }));
    },
    _keyUpHandler: event => {
      const key = event.key.toLowerCase();
      set(state => {
        const newPressedKeys = new Set(state.pressedKeys);
        newPressedKeys.delete(key);
        return { pressedKeys: newPressedKeys };
      });
    },
    _blurHandler: () => {
      set({ pressedKeys: new Set() });
    },
    _visibilityChangeHandler: () => {
      if (document.hidden) {
        set({ pressedKeys: new Set() });
      }
    },
    _updatePressedKeys: (key: string, action: 'add' | 'remove') => {
      set(state => {
        const newPressedKeys = new Set(state.pressedKeys);
        action === 'add' ? newPressedKeys.add(key) : newPressedKeys.delete(key);
        return { pressedKeys: newPressedKeys };
      });
    },
    subscribePressedKeys: () => {
      const $this = get();
      if (!$this.eventsNode.current) { return; }
      $this.eventsNode.current.addEventListener('keydown', $this._keyDownHandler);
      $this.eventsNode.current.addEventListener('keyup', $this._keyUpHandler);
      window.addEventListener('blur', $this._blurHandler);
      document.addEventListener('visibilitychange', $this._visibilityChangeHandler);
    },
    unsubscribePressedKeys: () => {
      const $this = get();
      if (!$this.eventsNode.current) { return; }
      $this.eventsNode.current.removeEventListener('keydown', $this._keyDownHandler);
      $this.eventsNode.current.removeEventListener('keyup', $this._keyUpHandler);
      window.removeEventListener('blur', $this._blurHandler);
      document.removeEventListener('visibilitychange', $this._visibilityChangeHandler);
    },


    cellSize: 48,

    isOpened: false,
    open: () => set({ isOpened: true }),
    close: () => set({ isOpened: false, isOpenedVault: false, isOpenedShop: false }),
    toggle: () => set(state => ({ 
        isOpened: !state.isOpened, 
        isOpenedVault: state.isOpened ? false : state.isOpenedVault,
        isOpenedShop: state.isOpened ? false : state.isOpenedShop 
    })),

    isOpenedVault: false,
    openVault: () => { 
        set({ isOpenedVault: true })
        if (get().isOpenedTrade) {
            useCloud.getState().cancelTrade()
        } 
    },
    closeVault: () => set({ isOpenedVault: false, isOpened: false }),
    toggleVault: () => set(state => ({ isOpenedVault: !state.isOpenedVault })),

    isOpenedShop: false,
    openShop: () => { 
        set({ isOpenedShop: true })
        if (get().isOpenedTrade) {
            useCloud.getState().cancelTrade()
        } 
    },
    closeShop: () => set({ isOpenedShop: false, isOpened: false }),
    toggleShop: () => set(state => ({ isOpenedShop: !state.isOpenedShop })),

    isOpenedTrade: false,
    openTrade: () => set({ isOpenedTrade: true, isOpenedVault: false, isOpenedShop: false }),
    closeTrade: () => set({ isOpenedTrade: false }),
    toggleTrade: () => set(state => ({ isOpenedTrade: !state.isOpenedTrade })),

    isOpenedMarketplace: false,
    openMarketplace: () => set({ isOpenedMarketplace: true }),
    closeMarketplace: () => set({ isOpenedMarketplace: false }),
    toggleMarketplace: () => set(state => ({ isOpenedMarketplace: !state.isOpenedMarketplace })),


    isOpenedContextMenu: false,
    openContextMenu: () => set({ isOpenedContextMenu: true }),
    closeContextMenu: () => set({ isOpenedContextMenu: false }),
    toggleContextMenu: () => set(state => ({ isOpenedContextMenu: !state.isOpenedContextMenu })),
    contextMenuFighter: null,
    setContextMenuFighter: (contextMenuFighter) => set({ contextMenuFighter }),
    contextMenuCoords: null,
    setContextMenuCoords: (contextMenuCoords) => set({ contextMenuCoords }),


    slots: createRef(),
    equipmentSlots: createRef(),
    subscribeBackpack: () => {
        const target = useUi.getState().eventsNode.current
        if (!target) { console.error('[useCommandLine]: target event node not found') }
        target.addEventListener('keypress', get()._handleKeypress)
    },
    unsubscribeBackpack() {
        const target = useUi.getState().eventsNode.current
        if (!target) return;
        target.removeEventListener('keypress', get()._handleKeypress)
    },
    _handleKeypress: (e) => {
        if (e.code.toLowerCase() === 'keyv' ||e.code.toLowerCase() === 'keyi' ) {
            e.preventDefault()
            get().toggle()
        }
    }
}))