import React from 'react';

import type { Fighter } from 'interfaces/fighter.interface';

import { useCloud } from 'EventCloud/useCloud';
import { useFighter } from 'views/Game/GamePlay/Fighter/useFighter';
import { useNpc } from 'views/Game/GamePlay/Npc/useNpc';
import { useDroppedItem } from 'views/Game/GamePlay/DroppedItem/useDroppedItem';
import { useOtherFighter } from 'views/Game/GamePlay/Fighter/Players/useOtherFighter';
import { useMarketplace } from 'views/Game/GamePlay/UserInterface2D/Marketplace/useMarketplace';

import { useWorkerWebSocket } from './hooks/useWorkerWebSocket';
import { Inventory } from 'interfaces/inventory.interface';
import { useUi } from 'views/Game/GamePlay/UserInterface3D/useUi';

import { useLayout } from 'Layout';
import { useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { useWiki } from 'views/Wiki';

const EventCloudContext = React.createContext({});
const socketUrl = process.env.REACT_APP_WS_URL; // ws://149.100.159.50:8080/ws
export const EventCloudProvider = React.memo(function EventCloudProvider({ children }: any) {	
	const [setNpcList] = useNpc(state => [state.setNpcList])
	const [setFighter] = useFighter(state => [state.setFighter])
	const [addEvent, setUserFighters] = useCloud(state => [state.addEvent, state.setUserFighters])
	const [setDroppedItems] = useDroppedItem(state => [state.setDroppedItems]);
	const [updateBackpack, updateEquipment] = useCloud(state => [state.updateBackpack, state.updateEquipment]);
	const [updateVault] = useCloud(state => [state.updateVault])
	const [updateShop] = useCloud(state => [state.updateShop])
	const [refreshFighterItems] = useCloud(state => [state.refreshFighterItems])
	const [setChatLog] = useCloud(state => [state.setChatLog])
	const [setPlayerList] = useCloud(state => [state.setPlayerList])
	const [setLockedCoordinates] = useCloud(state => [state.setLockedCoordinates])
	const [updateOtherFighterList] = useOtherFighter(state => [state.updateOtherFighterList])
	const [updateTrade] = useCloud(state => [state.updateTrade])
    const [fetchUserFighters] = useCloud(state => [state.fetchUserFighters])
	const [setWarehouseItems] = useMarketplace(state => [state.setWarehouseItems])
	
	const [openTrade, closeTrade] = useUi(state => [state.openTrade, state.closeTrade])
	const [openBackpack, closeBackpack] = useUi(state => [state.open, state.close])

	const navigate = useNavigate()
	const [,setCookie] = useCookies(['sessionId'])
	const {sessionId, setSessionId} = useLayout()

	const setWikiItems = useWiki(state => state.setItems)


	const processIncomingMessage = React.useCallback((event: any) => {
		const msg = JSON.parse(event.data)

		switch (msg.action) {
			case "item_picked":
				handleItemPickedEvent(msg.item, msg.fighter, msg.qty);
				break;

			case "dropped_items":
				handleDroppedItems(msg.droppedItems);
				break;

			case "fighter_items":
				handleFighterItems(msg.items, msg.attributes, msg.equipment, msg.stats, msg.npcs, msg.fighter, msg.money, msg.droppedItems, msg.backpack);
				break;

			case "damage_dealt":
				handleDamage(msg.damage, msg.opponent, msg.player, msg.opponentHealth, msg.lastDmgTimestamp, msg.playerFighter, msg.opponentFighter, msg.type, msg.skill)
				break;

			case "ping":
				handlePing(msg.fighter, msg.locked_coords, msg.npcs, msg.players);
				break;

			case "backpack_update":
				handleUpdateBackpack(msg.backpack, msg.equipment);
				break;

			case "vault_update":
				handleUpdateVault(msg.vault);
				break;

			case "shop":
				handleUpdateShop(msg.shop);
				break;

			case "user_fighters":
				handleUserFighters(msg.fighters);
				break;

			case "fire_skill":
				handleFireSkill(msg.fighter, msg.skill);
				break;

			case "chat_message":
				handleChatMessage(msg.author, msg.msg, msg.msgType);
				break;

			case "trade":
				updateTrade(msg.trade)
				msg.trade ? openTrade() : closeTrade()
				msg.trade ? openBackpack() : closeBackpack()
				break;

			case "items_list":
				setWikiItems(msg.items)
				break;
			case "warehouse_update":
				// console.log('warehouse_update', msg.warehouse)
				handleWarehouseUpdate(msg.warehouse)
				break;
		}

		// Event Processing Logic  
		function handleItemPickedEvent(item, fighter, qty) {
			refreshFighterItems();
		}

		function handleDroppedItems(droppedItems) {
			setDroppedItems(droppedItems);
		}

		function handleFighterItems(items, attributes, equipment, stats, npcs, fighter, money, droppedItems, backpack) {
			setDroppedItems(droppedItems)
			updateBackpack(backpack)
			updateEquipment(equipment)
			setFighter(fighter)
		}

		function handleDamage(damage, opponent, player, opponentHealth, lastDmgTimestamp, playerFighter, opponentFighter, dmgType, skill) {
			addEvent({ type: 'damage', npcId: opponent, damage, dmgType, skill, playerFighter, opponentFighter });
		}

		function handlePing(fighter, lockedCoordinates, npcs, players: Fighter[]) {
			setFighter(fighter);
			setLockedCoordinates(lockedCoordinates);
			setNpcList(npcs);
			setPlayerList(players);
			updateOtherFighterList(players.filter(_ => _.id !== useFighter.getState().fighter.id))
		}

		function handleUpdateBackpack(newBackpack, newEquipment) {
			updateBackpack(newBackpack);
			updateEquipment(newEquipment)
		}

		function handleUserFighters(userFighters) { 
			setUserFighters(userFighters) 
		}

		function handleFireSkill(fighter, skill) {
			addEvent({ type: 'skill', fighter, skill });
		}

		function handleChatMessage(author, msg, msgType) {
			setChatLog({ author, msg, msgType })
		}

		function handleUpdateVault(vault: Inventory) {
			updateVault(vault)
		}

		function handleWarehouseUpdate(warehouse: Inventory) {
			setWarehouseItems(Object.values(warehouse.items))
		}

		function handleUpdateShop(shop: Inventory) {
			updateShop(shop)
		}
	}, [])

	const socketOptions = React.useMemo(() => ({
		onMessage: (event) => processIncomingMessage(event),
		onError: () => { 
			navigate('/auth') 
			setCookie('sessionId', '', { path: '/' })
			setSessionId('')
			toast('Something went wrong. You have been logged out.', { type: 'error' })
		}
	}), [processIncomingMessage])
	const { sendJsonMessage, readyState } = useWorkerWebSocket(`${socketUrl}?sessionId=${sessionId}`, socketOptions)
	React.useLayoutEffect(() => {
		if (readyState) {
			useCloud.getState().init(sendJsonMessage)
			fetchUserFighters()
		}
	}, [readyState])

	return <EventCloudContext.Provider value={{}}>{children}</EventCloudContext.Provider>
});

