import { WarehouseItem, MarketplaceItem } from "interfaces/marketplace.interface"
import { shallow } from "zustand/shallow"
import { createWithEqualityFn } from "zustand/traditional"

export type TabType = 'marketplace' | 'warehouse' | 'history'
interface UseMarketplace {
    tab: TabType
    setTab: (tab: TabType) => void
    items: any[]
    page: number
    totalPages: number
    setItems: (items: any[]) => void
    warehouseItems: any[]
    setWarehouseItems: (items: any[]) => void
    requestMarket: (page: number) => Promise<any>
    historyItems: any[]
    setHistoryItems: (items: any[]) => void
    itemToSell: WarehouseItem | null
    setItemToSell: (item: WarehouseItem | null) => void
    itemToBuy: MarketplaceItem | null
    setItemToBuy: (item: MarketplaceItem | null) => void
}
export const useMarketplace = createWithEqualityFn<UseMarketplace>((set, get) => ({
    tab: 'marketplace',
    setTab: tab => set({ tab }),
    items: [],
    setItems: items => set({ items }),
    page: 1,
    totalPages: 1,
    requestMarket: async (page: number) => {
        return fetch(`${process.env.REACT_APP_HTTP_URL}/market?page=${page}`, { method: 'GET' })
            .then(response => {
                if (!response.ok) {
                    throw new Error('[Market]: Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                set({ items: data.items, page, totalPages: data.total_pages });
                console.log('data', data, page)
                return data;
            })
            .catch(error => {
                console.error('[Market]: There was a problem with the fetch operation:', error);
            });
    },
    warehouseItems: [],
    setWarehouseItems: items => set({ warehouseItems: items }),
    historyItems: [],
    setHistoryItems: items => set({ historyItems: items }),
    itemToSell: null,
    setItemToSell: item => set({ itemToSell: item }),
    itemToBuy: null,
    setItemToBuy: item => set({ itemToBuy: item }),
}), shallow)