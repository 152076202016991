import { useNavigate } from "react-router"
import { Button } from "./Button"
import { Input } from "./Input"
import { StyledFooter, StyledForm } from "./Modal"
import { requestLogin } from "./api"
import { useState } from "react"
import { useLayout } from "Layout"
import { useCookies } from "react-cookie"
import { toast } from "react-toastify"
import { useLoader } from "core/Loader"

export const Login = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [validated, validate] = useState(false)
    const { setSessionId } = useLayout()
    const [, setCookie] = useCookies(['sessionId'])
    const [show] = useLoader(state => [state.show])

    const isValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

    const handleSubmit = (e: any) => {
        e.preventDefault()
        validate(true)
        if (!isValidEmail(email)) { return }
        if (password.length < 8) { return }
        show(true)
        requestLogin(email, password).then(data => {
            toast(data?.message || 'Welcome, we missed you:)', { type: 'success' })
            if (data?.sessionId && data?.accountId) {
                const sevenDaysFromNow = new Date();
                sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);
                setCookie('sessionId', data.sessionId, { expires: sevenDaysFromNow, path: '/' })
                setSessionId(data.sessionId)
                navigate('/')
                return
            }

        })
        .catch((message) => {
            if (message) {
                toast(String(message), { type: 'error' })
                return
            }
            toast('Something wen\'t wrong, try again later.', { type: 'error' })
        })
        .finally(() => {
            show(false)
        })
    }

    return (
        <>
            <StyledForm onSubmit={handleSubmit}>
                <p>Log in:</p>
                <Input 
                    type="text" 
                    icon="email"
                    placeholder="Email*"
                    onChange={(value: string) => setEmail(value)}
                    invalid={validated && !isValidEmail(email)}
                    required
                />
                <Input 
                    type="password" 
                    icon="password"
                    placeholder="Password*"
                    onChange={(value: string) => setPassword(value)}
                    invalid={validated && password.length < 8}
                    required
                />
                <Button className="button">Log In</Button>
            </StyledForm>
            <StyledFooter>
                <p>Don't have an account, yet?</p>
                <Button 
                    className="button"
                    onClick={() => {
                        navigate({
                            search: `?type=register`
                        })
                    }}
                >Register a new account</Button>
            </StyledFooter>
        </>
    )
}