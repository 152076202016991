import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

export type GameStateType = 'menu' | 'world'
interface UseGame {
    location: GameStateType
    setLocation: (gameState: GameStateType) => void
}
export const useGame = createWithEqualityFn<UseGame>((set, get) => ({
    location: 'menu',
    setLocation: location => set({ location })
}), shallow)