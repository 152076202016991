import React, { useCallback, useRef } from "react"
import { useUi } from "views/Game/GamePlay/UserInterface3D/useUi"
import { useCloud } from 'EventCloud/useCloud'
import { useFighter } from 'views/Game/GamePlay/Fighter/useFighter'
import { useCore } from "../../useCore"

import type { Fighter } from "interfaces/fighter.interface"
import { ThreeEvent } from "@react-three/fiber"


export const usePointerEvents = (npc: Fighter, model?: any) => {
    const nameColor = React.useRef<0xFFFFFF | 0xFF3300>(0xFFFFFF)
    const fighter = useFighter(state => state.fighter)
    const hovered = useRef(false)
    const pressedKeys = useUi(state => state.pressedKeys)
    // console.log('PRESSED KEYS', pressedKeys)
    // Set target & hover
    const handlePointerEnter = useCallback((e) => {
        const $ui = useUi.getState()
        // console.log('ENTER', npc, $ui.pressedKeys.has('metaleft'))
        if ($ui.pressedKeys.has('metaleft') || $ui.pressedKeys.has('altleft')) { 
            return 
        }
        if (npc.isDead) { return }
        e.stopPropagation()
        nameColor.current = 0xFF3300
        $ui.setCursor('pointer')
        useCore.getState().setHoveredItems(npc, 'add')
        hovered.current = true
    }, [npc])

    const handlePointerLeave = useCallback(() => {
        // console.log('LEAVE', npc)
        const $ui = useUi.getState()
        const $core = useCore.getState()
        if (!$core.isItemHovered(npc)) { return } // To prevent extra leave events, what leads to mouse flickering
        nameColor.current = 0xFFFFFF
        $ui.setCursor('default')
        $core.setHoveredItems(npc, 'remove')
        hovered.current = false
    }, [npc])

    const handleLeftClick = useCallback((e) => {
        console.error('LEFT CLICK', npc)
        const $ui = useUi.getState()
        if ($ui.pressedKeys.has('metaleft') || $ui.pressedKeys.has('altleft')) { 
            handlePointerLeave()
            return 
        }
        if (npc.isDead) { return }
        e.stopPropagation()
        useCloud.getState().setTarget(npc, fighter.skills[0])
    }, [npc])
    const handleRightClick = useCallback((event: ThreeEvent<PointerEvent>) => {
        if (npc.isDead) { return }
        event.stopPropagation()
        useCloud.getState().setTarget(npc, fighter.skills[useCloud.getState().selectedSkill])
    }, [npc])


    return {
        nameColor,
        handlePointerEnter,
        handlePointerLeave,
        handleRightClick,
        handleLeftClick,
        hovered
    }
}