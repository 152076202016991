// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserInterface2D_UserInterface2D__-HGQ6 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  z-index: 10002;
}

.UserInterface2D_coordinates__QFgTu {
  position: absolute;
  top: 32px;
  left: 32px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.UserInterface2D_players__3vwU- {
  position: absolute;
  top: 140px;
  left: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}
.UserInterface2D_players__3vwU- p {
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/views/Game/GamePlay/UserInterface2D/UserInterface2D.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,MAAA;EAAQ,OAAA;EACR,WAAA;EACA,YAAA;EACA,oBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,cAAA;AAEJ;;AACA;EACI,kBAAA;EACA,SAAA;EACA,UAAA;EAEA,eAAA;EACA,+BAAA;EACA,yBAAA;UAAA,iBAAA;EACA,oBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,UAAA;EAAY,UAAA;EACZ,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,eAAA;EACA,+BAAA;AAEJ;AAAI;EACI,SAAA;EACA,UAAA;AAER","sourcesContent":[".UserInterface2D {\n    position: absolute;\n    top: 0; left: 0;\n    width: 100%;\n    height: 100%;\n    pointer-events: none;\n    user-select: none;\n    z-index: 10002;\n}\n\n.coordinates {\n    position: absolute;\n    top: 32px;\n    left: 32px;\n    // transform: translateX(-50%);\n    font-size: 14px;\n    color: rgba(255, 255, 255, .5);\n    user-select: none;\n    pointer-events: none;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.players {\n    position: absolute;\n    top: 140px; left: 32px;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    font-size: 14px;\n    color: rgba(255, 255, 255, .5);\n\n    p {\n        margin: 0;\n        padding: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserInterface2D": `UserInterface2D_UserInterface2D__-HGQ6`,
	"coordinates": `UserInterface2D_coordinates__QFgTu`,
	"players": `UserInterface2D_players__3vwU-`
};
export default ___CSS_LOADER_EXPORT___;
