import React from "react"
import { useOtherFighter } from "./useOtherFighter"

import Player from "./Player"
import { useTestFighters } from "views/Game/GamePlay/hooks/useTestFighters"

const OtherFighterList = React.memo(function OtherFighterList() {
    const f = useTestFighters(50, false)
    const otherFighterList = useOtherFighter(state => state.otherFighterList)
    return <>{ otherFighterList.map(fighter => <Player key={fighter?.id} fighter={fighter} />) }</>
})

export default OtherFighterList