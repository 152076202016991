import { Plane, useTexture } from "@react-three/drei"
import Button from "views/Game/GamePlay/UserInterface3D/components/Button"
import { UiText } from "views/Game/GamePlay/UserInterface3D/components/UiText"
import { Skill } from "interfaces/skill.interface"
import { Texture } from "three"
import { useSkillsSlotsTextures } from "./useSkillsTextures"

interface Props {
    id: string
    position?: [number, number, number]
    onClick?: () => void
    width?: number
    height?: number
    active?: boolean
    selectedSkill?: { skill: Skill, map: Texture | null } | null
    circleWidth?: number
    circleHeight?: number
    showId?: boolean
}
export const SkillSlot = ({
    width = 34,
    height = 48,
    circleWidth = 28,
    circleHeight = 28,
    showId = true,
    ...props
}: Props) => {
    const textures = useSkillsSlotsTextures()
    const idBgMap = useTexture('/assets/bottom-menu/id-bg.png')
    return (
        <Button
            name={"skill_slot_"+props.id}
            onClick={props.onClick}
            position={props.position}
        > 
            <Plane 
                args={[width, height]} 
                userData={{ map: textures.skillSlot, mapHover: textures.skillSlotHover }}
                // @ts-expect-error
                onPointerEnter={(e) => (e.object as THREE.Mesh).material.map = e.object.userData.mapHover}
                // @ts-expect-error
                onPointerLeave={(e) => (e.object as THREE.Mesh).material.map = e.object.userData.map}
            >
                <meshBasicMaterial map={textures.skillSlot} transparent={true} />
            </Plane>
            {
                props.selectedSkill?.map ? (
                    <Plane args={[width*0.9, height*0.9]}>
                        <meshBasicMaterial map={props.selectedSkill.map} />
                    </Plane>
                ) : null
            }
            <Plane args={[width, height]} visible={props.active}>
                <meshBasicMaterial color='#ffffff' transparent={true} opacity={0.3} />
            </Plane>
            {
                showId ? (
                    <Plane position={[ /*-width/2+circleWidth/8*/0, height/2-circleHeight/8, 0 ]} args={[circleWidth, circleHeight]}>
                        <meshBasicMaterial map={idBgMap} transparent={true} />
                        <UiText position={[0, 0, 10]} fontSize={12} color={'#adadad'}>{ props.id }</UiText>
                    </Plane>
                ) : null
            }
            <>
                { props.selectedSkill?.skill ? <UiText position={[0, -height/2+7, 0]} fontSize={5} color="#000000">{ props.selectedSkill.skill.name }</UiText> : null }
            </>
        </Button>
    )
}