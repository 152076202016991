import styled from "styled-components"
import { useMarketplace } from "./useMarketplace"

const StyledTabs = styled.div`
    position: absolute;
    top: 140px;
    left: 43px;
    display: flex;
`

const StyledTabButton = styled.div`
    color: #D4D4D4;
    text-align: center;
    text-shadow: 0px 0px 20px #949494;
    font-family: "Acari Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    filter: blur(0.15000000596046448px);
    padding: 11px 34px;
    cursor: pointer;
    z-index: 1;

    &:hover:not(.-active) {
        opacity: .8;
    }

    &.-active::before {
        visibility: visible !important;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        background-color: #43413C;
        opacity: .5;
        box-shadow: 0px 0px 19.39px 0px rgba(0, 0, 0, 0.75) inset, 0px 0px 19.39px 11.634px rgba(0, 0, 0, 0.50) inset;
        filter: drop-shadow(0px 3.878px 3.878px rgba(0, 0, 0, 0.25));
        z-index: -1;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        visibility: hidden;
    }
`

export const Tabs = () => {
    const [tab, setTab] = useMarketplace(state => [state.tab, state.setTab])
    return (
        <StyledTabs>
            {['Marketplace', 'Warehouse', 'History'].map((tabName, idx) => (
                <StyledTabButton 
                    key={idx}
                    // @ts-expect-error
                    onClick={() => setTab(tabName.toLowerCase())}
                    className={tabName.toLowerCase() === tab ? '-active' : ''}
                >{ tabName }</StyledTabButton>
            ))}
        </StyledTabs>
    )
}