import { useCloud } from "EventCloud/useCloud"
import { Slots } from "./Slots/Slots"
import { useUi } from "./useUi"
import { useRef } from "react"
import { Aling } from "./components/Align"
import { Plane, useTexture } from "@react-three/drei"
import { CloseButton } from "./components/CloseButton"
import { UiText } from "./components/UiText"
import { useBackpackItemMaps, useBackpackSlotsMaps } from "./Backpack/useBackpackSlotsMaps"
import * as THREE from 'three'

export const Shop = () => {
    const [isOpenedShop, closeShop] = useUi(state => [state.isOpenedShop, state.closeShop])
    const [shop, buyItemShop] = useCloud(state => [state.shop, state.buyItemShop])
    const relatedNode = useRef<THREE.Group>()
    const relatedNode2 = useRef<THREE.Group>()
    const bgMap = useTexture('/assets/backpack-menu/shop-bg-color.png')
    const slotsMaps = useBackpackSlotsMaps()
    const bpItemMaps = useBackpackItemMaps()

    if (!shop) return null

    return (
        <Aling visible={isOpenedShop} ref={relatedNode} alignX="right" hoverId="shopAlign">
            <Plane position={[-660, 40, 0]} args={[420, 826, 1]} ref={relatedNode2 as any}>
                <meshBasicMaterial map={bgMap} transparent={true} />
                <CloseButton 
                    name="vault"
                    position={[140, 345, 10]}
                    onClick={closeShop}
                    visible={isOpenedShop}
                />
                <UiText position={[0, 345, 10]} color={'#d48932'}>Shop</UiText>
                    <group position={[0, 0, 1]}>
                        <Slots
                            id='ID_SHOP'
                            type='backpack'
                            enableRender={isOpenedShop}
                            cellWidth={35}
                            cellHeight={35}
                            grid={shop.grid}
                            items={shop.items}
                            width={shop.grid.length}
                            height={shop.grid[0].length}
                            position={[-120, 230, 0]}
                            maps={slotsMaps}
                            itemMaps={bpItemMaps}
                            events={[
                                { id: '', type: 'doubleClick', handler: (props) => buyItemShop(props.item.itemHash) },
                            ]}
                            relatedNodes={[relatedNode, relatedNode2]}
                        />
                    </group>
                </Plane>
        </Aling>
    )
}