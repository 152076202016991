import * as THREE from 'three'
import React, { useMemo } from "react"
import { Sprite } from "three"

import { Fighter } from "interfaces/fighter.interface"
import { createBillboardMaterial } from "../materials/createBillboardMaterial"
import { Plane } from '@react-three/drei'


interface Props {
    fighter: Fighter
    offsetY: number
}
const HealthBar = React.memo(function HealthBar({ fighter, offsetY }: Props) {
    const shader = React.useRef<any | null>(null)
    const material = useMemo(() => {
        const material = createBillboardMaterial(new THREE.MeshBasicMaterial({ transparent: false }))
        material.onBeforeCompile = (_shader) => {
            _shader.uniforms.maxHealth = { value: fighter.maxHealth }
            _shader.uniforms.currentHealth = { value: fighter.currentHealth }

            _shader.vertexShader = _shader.vertexShader.replace(`#include <common>`, `
                #include <common>
                varying vec2 vUv;
            `)
    
            _shader.vertexShader = _shader.vertexShader.replace(`#include <uv_vertex>`, `
                #include <uv_vertex>
                vUv = uv;
            `)
    
            _shader.fragmentShader = _shader.fragmentShader.replace(`#include <common>`, `
                #include <common>
                uniform float maxHealth;
                uniform float currentHealth;
                varying vec2 vUv;
            `)
    
            _shader.fragmentShader = _shader.fragmentShader.replace(`#include <dithering_fragment>`, `
                #include <dithering_fragment>
                float health = currentHealth / maxHealth;
                float height = .98;
    
                // Height & position
                // float alpha = step(height, 1. - vUv.y);
                float alpha = 1.;
    
                // Green -> Yellow -> Red
                vec3 healthColor = vec3(0., 1., 0.);
                if (health > .3 && health < .65) {
                     healthColor = vec3(1., 1., 0.);
                }
                if (health <= .3) {
                    healthColor = vec3(1., 0., 0.);
                }
    
                // Width
                float alphaWidth = step(vUv.x, health);
    
                gl_FragColor = vec4(healthColor, alpha * alphaWidth * .7);
            `)
    
            shader.current = _shader 
        }
        return material
    }, [])

    React.useEffect(() => {
        if (!shader.current) { return }
        shader.current.uniforms.currentHealth.value = fighter.currentHealth || 0
        shader.current.uniforms.maxHealth.value = fighter.maxHealth
    }, [ fighter ])

    return <Plane
        args={[1, 0.03, 1]}
        material={material}
        position={[0, offsetY, 0]}
    />
})

export default HealthBar