import { useTexture } from "@react-three/drei"
import { ButtonPlane } from "./ButtonPlane"

interface Props {
    name: string
    args?: [number, number, number]
    position?: [number, number, number]
    onClick?: () => void
    visible?: boolean
}
export const CloseButton = ({
    name,
    args = [18, 18, 1],
    position,
    onClick,
    visible = true
}: Props) => {
    const [closeMap, closeHoverMap] = useTexture(['/assets/shared/close-button-color.png', '/assets/shared/close-button-hover-color.png'])
    return (
        <ButtonPlane 
            name={"close-"+name}
            colorMap={closeMap}
            hoverColorMap={closeHoverMap}
            args={args} 
            position={position}
            onClick={onClick}
            visible={visible}
        />
    )
}