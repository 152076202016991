import { useEffect, useMemo, useRef } from "react"
import { Aling } from "../components/Align"
import { Consumables } from "./Consumables"
import { Skills } from "./Skills/Skills"
import { Hexagon } from "./Hexagon"
import { useFighter } from "views/Game/GamePlay/Fighter/useFighter"
import { Plane, useTexture } from "@react-three/drei"
import { ExperienceBar } from "./ExperienceBar"
import { StaminaBar } from "./StaminaBar"
import { WingRight } from "./WingRight"
import { useCloud } from "EventCloud/useCloud"
import { WingLeft } from "./WingLeft"
import { SkillSlot } from "./Skills/SkillSlot"
import { useSkillsTextures } from "./Skills/useSkillsTextures"
import * as THREE from 'three'

export const Quickmenu = () => {
    const relatedNode = useRef<THREE.Group>()
    const fighter = useFighter(state => state.fighter)
    const [bgMap] = useTexture(['assets/bottom-menu/background-color.png'])
    const [qSkillMap] = useTexture(['assets/bottom-menu/question-skill.png'])

    const requestVault = useCloud(state => state.requestVault)
    const requestShop = useCloud(state => state.requestShop)

    const binding = useMemo(() => fighter.skill_bindings, [fighter])
    const selectedSkill = useCloud(state => state.selectedSkill)
    const skillsTextures = useSkillsTextures()

    // Request Vault on btn Mount to already have data
    useEffect(() => {requestVault(); requestShop()}, [])

    return (
        <Aling ref={relatedNode} alignY="bottom" hoverId="quickmenuAlign">
            <Consumables relatedNodes={[relatedNode]} position={[-230, 75, 1]} />
            <Plane position={[-20, 75, 1]} args={[45, 45]}>
                <meshBasicMaterial map={qSkillMap} transparent={true} />
            </Plane>
            <SkillSlot
                id={'selected'}
                showId={false}
                width={45}
                height={45}
                position={[-20, 75, 1]}
                selectedSkill={{ skill: binding[selectedSkill], map: binding[selectedSkill]?.skillId ?  (skillsTextures?.[binding[selectedSkill].skillId] || skillsTextures.unknown) : null }}
            />
            <Skills position={[51, 75, 1]}/>
            <group position={[0, 60, 0]}>
                <Hexagon name="health" position={[-390+65, 8, 1]} args={[120, 120]} max={+fighter.maxHealth} current={+fighter.currentHealth}  color={{ r: 250, g: 0, b: 0 }} />
                <Hexagon name="mana" position={[390-65, 8, 1]} args={[120, 120]} max={+fighter.maxMana} current={+fighter.currentMana} timeOffset={1000} color={{ r: 0, g: 150, b: 255 }} />
                <Plane position={[0, 0, 0]} args={[663, 100, 1]}>
                    <meshBasicMaterial map={bgMap} transparent={true} />
                </Plane>
                <ExperienceBar max={100} current={+fighter.level_progress} position={[0, -22, 10]}/>
                <StaminaBar max={100} current={70} position={[0, 57, 0]} />
                <WingRight position={[446, 10, 0]} />
                <WingLeft position={[-395, 10, 0]} />
            </group>

        </Aling>
    )
}