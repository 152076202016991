import styled from 'styled-components'
import { StyledText } from '../styles'
import { TextureBtn } from '../components/TextureBtn'

interface Props {
    className?: string
    name: string
    amount: string
    onSubmit: () => void
    disabled?: boolean
    info: { name: string, value: string }[]
}
export const StatsField = ({ 
    className, 
    name, 
    amount,
    onSubmit,
    disabled,
    info,
    ...props 
}: Props) => {
    return (
        <StyledStatsField className={`${disabled ? 'disabled' : ''}`} { ...props }>
            <div className='field'>
                <StyledText className='name'>{ name }</StyledText>
                <div className='amount'>{ amount }</div>
                <TextureBtn texture='/assets/stats/add-color-map.png' className='btn' onClick={onSubmit}>+</TextureBtn>
            </div>
            <div className='info'>
                {info.map((_,i) => (
                    <p key={i}>
                        <span>{ _.name }</span>:
                        <span> { _.value }</span>
                    </p>
                ))}
            </div>
        </StyledStatsField>
    )
}

const StyledStatsField = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, rgba(28, 28, 28, 0.2), rgba(28, 28, 28, 1), rgba(28, 28, 28, 0.2));
    }

    &.disabled {
        .btn {
            pointer-events: none;
            opacity: .5;
        }
    }

    .field {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name {
            font-size: 16px;
            color: #D4D4D4;
            font-weight: 400;
        }

        .amount {
            display: flex;
            padding: 4px 20px 4px 20px;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            border-radius: 15px;
            border: 0.5px solid #565656;
            background: #252525;
            box-shadow: 0px -4px 4px 0px rgba(79, 79, 79, 0.45) inset;
            text-align: center;
            text-shadow: 0px 0px 20px #986F40;
            font-family: "Acari Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.7px;
            background: linear-gradient(252deg, #C2AD62 40.02%, #C8AA87 82.23%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            filter: blur(0.15000000596046448px);
            margin-left: auto;
            margin-right: 5px;
        }

        p {
            font-size: 14px;
            color: rgb(255, 140, 0);
            background-color: rgb(27, 27, 27);
            width: 80px;
            border-radius: 4px;
            text-align: left;
        }

        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            width: 35px;
            height: 35px;
            cursor: pointer;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        color: white;
        width: 100%;
        gap: 4px;
        opacity: .7;


        p {
            text-shadow: 0px 0px 20px #578CF4;
            font-family: "Acari Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.7px;
            background: linear-gradient(252deg, #4574CF 40.02%, #7096F9 82.23%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            filter: blur(0.15000000596046448px);
        }
    }
`