import styles from './Modal.module.scss'

import { useSpring, animated } from "@react-spring/web"
import { useEffect, useRef, useState } from "react"

const fadeDur = 300
const mountDelay = 30

interface Props {
    showen: boolean,
    onClose?: () => void,
    children?: any
    type?: 'fadeIn' | 'moveFromLeft'
}

export const Modal = ({ showen: _showen, onClose, children, type, ...props }: Props) => {
    const [showen, show] = useState(false)

    const [mounted, mount] = useState(false)
    const timeout = useRef<any>()

    useEffect(() => {
        clearTimeout(timeout.current)

        if (_showen) {
            mount(true)
            setTimeout(() => {
                show(true)
            }, mountDelay)
            return
        }

        show(false)
        timeout.current = setTimeout(() => {
            mount(false)
        }, fadeDur)
    }, [_showen])

    const bgValues = useSpring({ opacity: showen ? 1 : 0 })
    const modalValues = useSpring({ opacity: showen ? 1 : 0, blur: showen ? 0 : 10/*, config: { duration: fadeDur }*/ })

    if (!mounted) { return }

    return (
        <div className={styles.Modal} {...props}>
            <animated.div className={styles.container} style={modalValues}>
                { children }
            </animated.div>
            <animated.div style={bgValues} className={styles.bg} onClick={onClose}/>
        </div>
    )
}