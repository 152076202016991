import { RefObject, createRef } from "react";
import { create } from "zustand";

import type { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { ObjectMap } from "@react-three/fiber";


export interface GltfLoaderStoreInterface {
    models: RefObject<{ [key: string]: GLTF & ObjectMap | undefined }>
}
export const useGLTFLoaderStore = create<GltfLoaderStoreInterface>((set, get) => ({
    models: createRef(),
}))