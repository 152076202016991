import React, { forwardRef, MutableRefObject } from "react"
import * as THREE from 'three'

import { Plane } from "@react-three/drei"
import { createBillboardMaterial } from "../materials/createBillboardMaterial"
import { useFrame } from "@react-three/fiber"
import { getMeshDimensions } from "../utils/getMeshDimensions"

interface Props {
    opacity?: number
    color?: number | string
    target: MutableRefObject<THREE.Mesh>
    offsetX?: number
    offsetY?: number
    anchorY?: 'bottom' | 'middle' | 'top' 
}
export const BackgroundPlane = React.forwardRef(({ opacity = 0.8, color = 0xFFFFFF, offsetX = 0, offsetY = 0, target, anchorY = 'middle' }: Props, outerRef: React.MutableRefObject<THREE.Mesh>) => {
    const ref = React.useRef<THREE.Mesh | null>(null)
    React.useImperativeHandle(outerRef, () => ref.current)
    const backgroundMaterial = React.useMemo(
        () =>
            createBillboardMaterial(
                new THREE.MeshBasicMaterial({
                    transparent: true,
                    color,
                    opacity,
                    depthWrite: false
                })
            ),
        [color, opacity]
    )

    const updatePlaneSize = React.useCallback(() => {
        if (target.current && ref.current) {
            const { width, height } = getMeshDimensions(target.current)
            ref.current.scale.set(width + offsetX, height + offsetY, 1)

            if (anchorY === 'bottom') {
                ref.current.position.y = height / 2
            }
            if (anchorY === 'top') {
                ref.current.position.y = -height / 2
            }
        }
    }, [offsetX, offsetY, anchorY])

    useFrame(() => updatePlaneSize())

    return (
        <Plane
            ref={ref}
            material={backgroundMaterial}
            position={[0, 0, 0]}
            args={[1, 1, 1]}
        />
    )
})

BackgroundPlane.displayName = 'BackgroundPlane'
