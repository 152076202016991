export const fragments = [
    // Dragon Trousers Skirt
    {
        name: 'fragments_legendary_pants_skirt_front',
        url: 'models/fragments/legendary_pants_skirt/legendary_pants_skirt_front.gltf'
    },
    {
        name: 'fragments_legendary_pants_skirt_back',
        url: 'models/fragments/legendary_pants_skirt/legendary_pants_skirt_back.gltf'
    },
]