export const requestLogin = (email: string, password: string) => {
    return requestAuth(email, password, 'login')
}

export const requestRegister = (email: string, password: string) => {
    return requestAuth(email, password, 'register')
}

function requestAuth(email: string, password: string, endpoint: string) {
    const body = JSON.stringify({
        email,
        password
    })
    return fetch(`${process.env.REACT_APP_HTTP_URL}/${endpoint}`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body
    })
    .then((response) => {
        if (!response.ok) {
            return response.text().then(errorMessage => {
                throw new Error(errorMessage)
            })
        }
        return response.json()
    })   
    .catch((error) => {
        console.error('Request failed:', error)
        throw error
    })
}