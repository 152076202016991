import { useGLTFLoaderStore } from "views/Game/GamePlay/Models/GLTFLoaderStore"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"
import { ObjectMap } from "@react-three/fiber"

export const armourRequirements = ['boots', 'armour', 'gloves', 'helm', 'pants']

export const getModelMesh = (name: string) => getMesh(getModel(name), name)

function getModel(name: string): GLTF & ObjectMap {
    const models = useGLTFLoaderStore.getState().models.current
    /**** INVENTORY SETS */
    /*warrior*/
    if (is('inventory', 'leather')) return models['inventory_warrior_leather_set']
    if (is('inventory', 'bronze')) return models['inventory_warrior_bronze_set']

    /**** SETS */
    /*warrior*/
    if (is('standard')) return models['warrior_standard_set']
    if (is('leather')) return models['warrior_leather_set']
    if (is('bronze')) return models['warrior_bronze_set']

    /**** NPC */
    if (is('npc', 'elite', 'bull', 'fighter')) return models['npc_elite_bull_fighter']
    if (is('npc', 'bull', 'fighter')) return models['npc_bull_fighter']
    if (is('npc', 'spider')) return models['npc_spider']
    if (is('npc', 'sproat', 'dragon')) return models['npc_sproat_dragon']
    if (is('npc', 'vault')) return models['npc_vault']
    if (is('npc', 'potion', 'girl')) return models['npc_potion_girl']


    if (is('npc')) return models['npc_spider']

    /**** Shared */
    if (is('gold')) return models['shared_gold']
    if (is('magic', 'box')) return models['shared_magicBox']
    if (is('small', 'axe')) return models['shared_small_axe']
    if (is('apple')) return models['shared_apple']
    if (is('small', 'hammer')) return models['shared_small_hammer']
    if (is('knife')) return models['shared_knife']
    if (is('large', 'healing', 'potion')) return models['shared_large_healing_potion']
    if (is('medium', 'mana', 'potion')) return models['shared_medium_mana_potion']
    if (is('small', 'healing', 'potion')) return models['shared_small_healing_potion']
    if (is('small', 'mana', 'potion')) return models['shared_small_mana_potion']
    if (is('large', 'mana', 'potion')) return models['shared_large_mana_potion']
    if (is('medium', 'healing', 'potion')) return models['shared_medium_healing_potion']
    if (is('sefirah', 'haya')) return models['shared_sefirah_of_haya']
    if (is('sefirah', 'ruach')) return models['shared_sefirah_of_ruach']
    if (is('sefirah', 'nefesh')) return models['shared_sefirah_of_nefesh']
    if (is('sefirah', 'neshamah')) return models['shared_sefirah_of_neshamah']
    if (is('simple', 'wings')) return models['shared_simple_wings']
    if (is('crystal', 'sword')) return models['shared_crystal_sword']
    if (is('axe')) return models['shared_axe']


    return null
    function is(...value: string[]) {
        return value.every(value => name.toLowerCase().includes(value.toLowerCase()))
    }
}

const getMesh = (model: GLTF & ObjectMap, name: string) => {
    if (!model) {
        console.warn('[getModelMesh]: Requested mesh not found')
        return null
    }

    const gltf = {
        animations: model.animations,
        asset: model.asset,
        cameras: model.cameras,
        materials: model.materials,
        nodes: model.nodes,
        parser: model.parser,
        scene: model.scene,
        scenes: model.scenes,
        userData: model.userData
    }

    // For sets we need to get needed part
    const bodyPartName = armourRequirements.find(bodyPartName => name.toLowerCase().includes(bodyPartName))
    if (bodyPartName) {
        // @ts-expect-error
        gltf.scene = model.scene.getObjectByName(bodyPartName)
        return gltf
    }

    // For injection for Fighter
    // TODO: in blender change name of objects to "injectNode"
    const animatedMesh = model.scene.getObjectByName("animatedArmature")
    if (animatedMesh) {
        // @ts-expect-error
        gltf.scene = animatedMesh
        return gltf
    }

    // TODO: change in editor
    if (!name.includes('inventory')) {
        if (name.toLowerCase().includes('sword')) {
            // @ts-expect-error
            gltf.scene = model.scene.getObjectByProperty('isMesh', true)
        }
    }

    return gltf
}