import { Plane, useTexture } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import { useEffect, useRef } from "react"

interface Props {
    min?: number
    max: number
    color?: { r: number, g: number, b: number }
    args?: any,
    position?: [number, number, number],
    name?: string
    current: number
    timeOffset?: number
}
export const Hexagon = ({
    min = 0,
    max,
    color = { r: 230, g: 5, b: 3 },
    current,
    position = [0, 0, 0],
    name = 'hexagon',
    args = [80, 80, 1],
    timeOffset = 0,
    ...props
}: Props) => {
    const [map, alphaMap, ao] = useTexture(['assets/bottom-menu/hexagon-color.png', 'assets/bottom-menu/hexagon-alphamap.png'/*, 'assets/bottom-menu/hexagon-ao.png'*/])
    const [glare, overlay] = useTexture(['assets/bottom-menu/hexagon-glare.png', 'assets/bottom-menu/hexagon-overlay.png'])
    const uniforms = useRef({
        uMin: { value: min },
        uMax: { value: max },
        uCurrent: { value: current },
        uColor: { value: color },
        uGlareMap: { value: glare },
        uOverlayMap: { value: overlay },
        uTime: { value: 0 }
    })
    useEffect(() => {
        uniforms.current.uMin.value = min
        uniforms.current.uMax.value = max
        uniforms.current.uCurrent.value = current
        uniforms.current.uColor.value = color
    }, [min, max, current, color])
    useFrame(({ clock }) => {
        uniforms.current.uTime.value = clock.getElapsedTime() + timeOffset
    })

    return (
        <Plane
            name={name}
            position={position}
            args={args}
            {...props}
        >
            <meshBasicMaterial
                transparent={true}
                map={map}
                alphaMap={alphaMap}
                onBeforeCompile={(shader) => {
                    shader.uniforms = {
                        ...shader.uniforms,
                        ...uniforms.current,
                    }
                    // Add Uniforms
                    shader.fragmentShader = `
                        uniform float uMin;
                        uniform float uMax;
                        uniform float uCurrent;
                        uniform vec3 uColor;
                        uniform sampler2D uGlareMap; 
                        uniform sampler2D uOverlayMap; 
                        uniform float uTime;
                    `+shader.fragmentShader
                    // Remove alphamap
                    shader.fragmentShader = shader.fragmentShader.replace(`#include <alphamap_fragment>`, ``)
                    // Add Our Color & reuse alpha map
                    shader.fragmentShader = shader.fragmentShader.replace(`#include <dithering_fragment>`, `
                        // Progress 
                        float alphaMask = texture2D( alphaMap, vAlphaMapUv ).g;
                        float delta = uCurrent / (uMax - uMin);
                        float edge = 0.05;
                        float clampHeight = 1. - smoothstep(delta - edge, delta + edge, vAlphaMapUv.y);
                        vec3 color = vec3(uColor.r / 255., uColor.g / 255., uColor.b / 255.) * alphaMask * clampHeight;
                        gl_FragColor.rgb *= color;

                        // Glare Anim
                        // vec2 glareUV = mod(vAlphaMapUv, 0.4) * 2.5;
                        vec2 glareUV = vAlphaMapUv;
                        glareUV.x += sin(glareUV.y * 10.0 + uTime * 0.75) * 0.08;
                        glareUV.y += sin(glareUV.x * 10.0 + uTime * 0.75) * 0.08;
                        vec4 glare = texture2D( uGlareMap, glareUV );
                        gl_FragColor.rgb += glare.rgb * alphaMask * clampHeight * 1.;

                        // Glare
                        vec4 overlayColor = texture2D( uOverlayMap, vAlphaMapUv );
                        gl_FragColor.rgb += overlayColor.rgb * overlayColor.a;

                        #include <dithering_fragment>
                    `)
                }}
            />
        </Plane>
    )
}