import { Three } from './Three'

const View = ({ children }: any) => {
  return (
      <Three>
        {children}
      </Three>
  )
}

export { View }
