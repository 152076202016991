// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Chat_chat__Yneh5 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 400px;
  height: 23.6%;
  overflow-y: auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 2px;
  z-index: 10001;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.Chat_message__yZTBu {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/views/Game/GamePlay/UserInterface2D/Chat/Chat.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,OAAA;EAAS,SAAA;EACT,YAAA;EACA,aAAA;EACA,gBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,yBAAA;EACA,QAAA;EACA,cAAA;EACA,oBAAA;EACA,yBAAA;UAAA,iBAAA;AAEJ;;AACA;EACI,eAAA;AAEJ","sourcesContent":[".chat {\n    position: absolute;\n    left: 0; bottom: 0;\n    width: 400px;\n    height: 23.6%;\n    overflow-y: auto;\n    padding: 32px;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: flex-end;\n    gap: 2px;\n    z-index: 10001;\n    pointer-events: none;\n    user-select: none;\n}\n\n.message {\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chat": `Chat_chat__Yneh5`,
	"message": `Chat_message__yZTBu`
};
export default ___CSS_LOADER_EXPORT___;
