import { shallow } from "zustand/shallow"
import { Modal } from "../components/Modal/Modal"
import { createWithEqualityFn } from "zustand/traditional"
import styles from './GoldEnter.module.scss'
import { useCallback, useEffect, useRef } from "react"

export interface UseGoldEnter { isOpened: boolean, onSubmit: (value: number) => void | null, open: (onSubmit: (value: number) => void) => void, close: () => void }
export const useGoldEnter = createWithEqualityFn<UseGoldEnter>((set, get) => ({
    isOpened: false,
    onSubmit: null,
    open: (onSubmit) => set({ isOpened: true, onSubmit }),
    close: () => set({ isOpened: false, onSubmit: null }),
}), shallow)

export const GoldEnter = () => {
    const ref = useRef<HTMLInputElement>()
    const [isOpened, onSubmit, close] = useGoldEnter(state => [state.isOpened, state.onSubmit, state.close])
    const handleSubmit = useCallback((e) => {
        e.preventDefault()
        onSubmit && onSubmit(+ref.current.value)
        close()
    }, [close, onSubmit])
    useEffect(() => {
        if (isOpened) {
            setTimeout(() => {ref.current.focus()}, 100)
        }
    }, [isOpened])
    return (
        <Modal showen={isOpened} onClose={() => close()}>
            <form className={styles.GoldEnter} onSubmit={handleSubmit}>
                <p>Enter Amount</p>
                <input ref={ref} type="number" placeholder="0" />
            </form>
        </Modal>
    )
}