import styled from "styled-components"
import { TabType, useMarketplace } from "../useMarketplace"
import { TableItem } from "./TableItem"
import { useMemo } from "react"

const StyledTable = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0;
    top: 278px;
    width: 100%;
    padding: 0 70px;

    .header {
        display: grid;
        grid-template-columns: 2.51fr 1.6fr 2.19fr 2.27fr;
        width: 100%;
        color: #D4D4D4;
        text-shadow: 0px 0px 20px #949494;
        font-family: "Acari Sans";
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        font-size: 12px;
        filter: blur(0.15000000596046448px);


        > :nth-child(1), :nth-child(3) {
            margin-left: 25px;
        } 
        > :nth-child(2) {
            margin-left: auto;
            margin-right: auto;
        } 
        > :nth-child(4) {
            margin-left: auto;
            margin-right: 25px;
        } 
    }

    .items {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: hidden auto;
        height: 410px;
        margin-top: 20px;
    }
`

const headerData: Record<TabType, string[]> = {
    'marketplace': ['Name', 'Added', 'Merchant', 'Price'],
    'warehouse': ['Name', 'Added', 'Status', 'Price'],
    'history': ['Name', 'Added', 'Merchant', 'Price'],
}

export const Table = () => {
    const [tab, marketplaceItems] = useMarketplace(state => [state.tab, state.items])
    const [warehouseItems] = useMarketplace(state => [state.warehouseItems])
    const [historyItems] = useMarketplace(state => [state.historyItems])
    const items = useMemo(() => {
        if (tab === 'warehouse') {
            return warehouseItems
        } else if (tab === 'history') {
            return historyItems
        }
        return marketplaceItems
    }, [tab, warehouseItems, historyItems, marketplaceItems])
    return (
        <StyledTable>
            <div className="header">
                { headerData[tab].map((name, idx) => (<div key={idx}>{ name }</div>)) }
            </div>
            <div className="items">
                { items.map((item, idx) => (<TableItem key={idx} item={item} />)) }
            </div>
        </StyledTable>
    )
}