import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { EventCloudProvider } from 'EventCloud/EventCloud';

import Chat from 'views/Game/GamePlay/UserInterface2D/Chat/Chat';

import { useUi } from './GamePlay/UserInterface3D/useUi';
import { Loader } from "@react-three/drei"

import { Experience } from './Experience';
import { GamePlay } from './GamePlay'

import UserInterface2D from './GamePlay/UserInterface2D/UserInterface2D';
import { Menu } from './Menu';
import { GameStateType, useGame } from '../../core/useGame'
import styled from 'styled-components'
import React from 'react';
import { useLayout } from 'Layout';
import { useCloud } from 'EventCloud/useCloud';

// Placeholder with commands for game is located:
// Scene/UserInterface2D/CommandLine/CommandsPlaceholder.tsx

// Commandments
//
// FOR PLAYERS
// /move <map name> - teleport map 
// /trade - initiate trade (point at other player)
// /party - join/initiate party (point at other player)
// <text> - local chat
// ~<text> - party chat
// @<text> - guild chat
//
// FOR DEVS
// /slide <x: int> <z: int> - move char to coords
// /spawn <npcName: string> - spawn an NPC (eg: spider, bull)
// /make <itemName: string> [+<lvl>] [+<add points>] [l] [exc]  - make item (eg: /make brass armour +9 +8 l exc  | /make nodachi)
// /makeset <setName: string> [+<lvl>] - make item (eg: /makeset legendary +15)
//

export const Game = () => {
	const eventsNode = useUi(state => state.eventsNode)
    const location = useGame(state => state.location)
    const view = React.useMemo(() => GameLocation(location), [location])
    const readyState = useCloud(state => state.readyState)
    const { sessionId } = useLayout()


    if (!sessionId) return null

    return (
		<EventCloudProvider>
			<DndProvider backend={HTML5Backend}>
                {
                    readyState ? (<>
				        <Chat />
                        <StyledScene id="scene" tabIndex={0} ref={eventsNode}>
                            <Experience/>
                            { view }
                            <Loader />
                        </StyledScene>
                        <UserInterface2D />
                    </>) : null
                }
			</DndProvider>
		</EventCloudProvider>
	);
}

const StyledScene = styled.div`
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border: none !important;
    outline: none !important;

    > div {
        width: 100%;
        height: 100%;
    }
`

function GameLocation(location: GameStateType) {
    if (location === 'menu') {
        return <Menu/>
    }
    if (location === 'world') {
        return <GamePlay/>
    }
    return <Menu/>
}

