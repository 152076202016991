import { Plane, useTexture } from "@react-three/drei"
import { ButtonPlane } from "./components/ButtonPlane"
import { UiText } from "./components/UiText"
import { useUi } from "./useUi"
import { useCloud } from "EventCloud/useCloud"
import { useEffect, useRef } from "react"
import * as THREE from 'three'
import { Aling } from "./components/Align"

export const ContextMenu = () => {
    const [contextMenuFighter, contextMenuCoords] = useUi(state => [state.contextMenuFighter, state.contextMenuCoords])
    const [isOpenedContextMenu, closeContextMenu] = useUi(state => [state.isOpenedContextMenu, state.closeContextMenu])
    
    const bgMap = useTexture('/assets/context-menu/context-menu-background-color.png')
    const [party, partyHover] = useTexture(['/assets/context-menu/btn-add-party-color.png', '/assets/context-menu/btn-add-party-hover-color.png'])
    const [whisper, whisperHover] = useTexture(['/assets/context-menu/btn-whisper-color.png', '/assets/context-menu/btn-whisper-hover-color.png'])
    const [trade, tradeHover] = useTexture(['/assets/context-menu/btn-trade-color.png', '/assets/context-menu/btn-trade-hover-color.png'])

    useEffect(() => {
        if (!isOpenedContextMenu) {
            useUi.getState().setContextMenuFighter(null)
        }
    }, [isOpenedContextMenu])

    const ref = useRef<THREE.Mesh | null>(null)
    useEffect(() => {
        if (isOpenedContextMenu && contextMenuCoords) {
            ref.current.position.set(contextMenuCoords.x + 125, contextMenuCoords.y + 150, 0)
        }
    }, [contextMenuCoords])

    return (
        <Aling hoverId="contentMenuAlign" visible={isOpenedContextMenu && !!contextMenuFighter && !!contextMenuCoords}>
            <Plane ref={ref} position={[-120, 100, 100]} args={[176, 186, 1]} onPointerMissed={closeContextMenu}>
                <meshBasicMaterial map={bgMap} transparent={true} />
                <UiText position={[0, 65, 1]} color="#4574CF" fontSize={18}>{ contextMenuFighter?.name }</UiText>
                <ButtonPlane
                    visible={isOpenedContextMenu && !!contextMenuFighter && !!contextMenuCoords}
                    name="add-to-party"
                    colorMap={party}
                    hoverColorMap={partyHover}
                    position={[0, 20, 1]}
                    args={[146, 24, 1]}
                    onClick={() => {closeContextMenu()}}
                ><UiText position={[-30, -1, 1]} color="#cbcbcb" anchorX="left" fontSize={14}>Add to Party</UiText></ButtonPlane>
                <ButtonPlane
                    visible={isOpenedContextMenu && !!contextMenuFighter && !!contextMenuCoords}
                    name="whisper"
                    colorMap={whisper}
                    hoverColorMap={whisperHover}
                    position={[0, -20, 1]}
                    args={[146, 24, 1]}
                    onClick={() => {closeContextMenu()}}
                ><UiText position={[-30, -1, 1]} color="#cbcbcb" anchorX="left" fontSize={14}>Whisper</UiText></ButtonPlane>
                <ButtonPlane
                    visible={isOpenedContextMenu && !!contextMenuFighter && !!contextMenuCoords}
                    name="trade"
                    colorMap={trade}
                    hoverColorMap={tradeHover}
                    position={[0, -60, 1]}
                    args={[146, 24, 1]}
                    onClick={() => {
                        if (!useUi.getState().isOpenedTrade && contextMenuFighter) 
                            useCloud.getState().requestTrade(contextMenuFighter.id)
                        closeContextMenu()
                    }}
                ><UiText position={[-30, -1, 1]} color="#cbcbcb" anchorX="left" fontSize={14}>Trade</UiText></ButtonPlane>
            </Plane>
        </Aling>
    )
}