import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { TextureBtn } from '../components/TextureBtn';
import { useMarketplace } from './useMarketplace';

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  p {
    text-shadow: 0px 0px 20px #7A7A7A;
    font-family: "Acari Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.75;
    background: linear-gradient(252deg, #D4D4D4 40.02%, #9A9A9A 82.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: blur(0.15000000596046448px);
  }

  .btn {
    width: 28px;
    height: 28px;

    img {
        position: absolute;
        transform: translate(-50%, -50%) scale(1.4);
        top: 50%;
        left: 50%;
    }
  }
`;

interface PaginationProps extends HTMLAttributes<HTMLDivElement> {
}
const Pagination = ({ ...props }: PaginationProps) => {
    const [totalPages, page, requestMarket] = useMarketplace((state) => [state.totalPages, state.page, state.requestMarket])

    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            requestMarket(newPage)
        }
    }

    return (
        <StyledPagination {...props}>
            <TextureBtn
                className='btn'
                texture="/assets/marketplace/pagination-quad-back-btn-color.png"
                width={32}
                height={32}
                onClick={() => handlePageChange(Math.max(page - Math.floor(totalPages / 3), 1))}
            />
            <TextureBtn
                className='btn'
                texture="/assets/marketplace/pagination-back-btn-color.png"
                width={32}
                height={32}
                onClick={() => handlePageChange(Math.max(page - 1, 1))}
            />

            <p>{page} / {totalPages}</p>

            <TextureBtn
                className='btn'
                texture="/assets/marketplace/pagination-next-btn-color.png"
                width={32}
                height={32}
                onClick={() => handlePageChange(Math.min(page + 1, totalPages))}
            />
            <TextureBtn
                className='btn'
                texture="/assets/marketplace/pagination-quad-next-btn-color.png"
                width={32}
                height={32}
                onClick={() => handlePageChange(Math.min(page + Math.floor(totalPages / 3), totalPages))}
            />
        </StyledPagination>
    );
};

export default Pagination;
