import styled from "styled-components"
import { Indicator } from "./Indicator"

export const PartyIndicators = () => {
    return (
        <StyledPartyIndicators>
            <Indicator />
            <Indicator />
            <Indicator />
        </StyledPartyIndicators>
    )
}

const StyledPartyIndicators = styled.div`
    position: absolute;
    top: 300px; left: 30px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`