import { useFrame } from '@react-three/fiber'
import { MutableRefObject } from 'react'
import { useSpring } from 'react-spring'
import { Uniforms } from 'three-stdlib'

export const useSpawn = (uniforms: MutableRefObject<Uniforms>) => {
    const [values, api] = useSpring(() => ({
        uSpawnHiddenAlpha: 1,
        config: { duration: 2000 }
    }))

    useFrame(() => {
        if (uniforms.current?.uSpawnHiddenAlpha) {
            uniforms.current.uSpawnHiddenAlpha.value = values.uSpawnHiddenAlpha.get()
        }
    })

    const play = () => {
        if (uniforms.current?.uSpawnHiddenAlpha) {
            uniforms.current.uSpawnHiddenAlpha.value = 0
            api.start({ from: { uSpawnHiddenAlpha: 0 }, to: { uSpawnHiddenAlpha: 1 } })
        }
    }

    return [play]
}