import styled from "styled-components"
import { useUi } from "../../UserInterface3D/useUi"
import { Tabs } from "./Tabs"
import { Table } from "./Table/Table"
import { useEffect } from "react"
import { useMarketplace } from "./useMarketplace"
import { ItemModal } from "./ItemModal"
import Pagination from "./Pagination"
import { Filter } from "./Filter/Filter"

const StyledMarketplace = styled.div`
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    color: white;

    font-family: "Acari Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.7px;

    .background {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        z-index: -1;
    }
`

const StyledContent = styled.div`
    width: 987px;
    height: 842px;
    position: relative;
    z-index: 1;

    > img {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: -1;
    }

    h2 {
        position: absolute;
        left: 50%;
        top: 73px;
        transform: translateX(-50%);
        text-align: center;
        text-shadow: 0px 0px 20px #986F40;
        font-family: "Acari Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.7px;
        background: linear-gradient(252deg, #926837 40.02%, #C8AA87 82.23%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .pagination {
        position: absolute;
        bottom: 60px;
        right: 70px;
    }
`

export const Marketplace = () => {
    const [isOpenedMarketplace, closeMarketplace] = useUi(state => [state.isOpenedMarketplace, state.closeMarketplace])

    const [requestMarket, page, tab] = useMarketplace(state => [state.requestMarket, state.page, state.tab])

    useEffect(() => {
        if (isOpenedMarketplace && tab === 'marketplace') {
            requestMarket(page)
        }
    }, [isOpenedMarketplace, requestMarket, tab])

    if (!isOpenedMarketplace) return null

    return (
        <>
            <StyledMarketplace>
                <StyledContent>
                    <img src="/assets/marketplace/background.png" alt="" width={987} height={842} />
                    <h2>Marketplace</h2>
                    <Filter />
                    <Tabs />
                    <Table />
                    { tab === 'marketplace' ? <Pagination className="pagination" /> : null }
                </StyledContent>
                <div onClick={closeMarketplace} className="background"></div>
            </StyledMarketplace>
            <ItemModal />
        </>

    )
}

