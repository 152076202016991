export const shader_golden = ({ uIsGolden }: { uIsGolden: boolean }) => {
    const fragmentShaderHeader = `
        uniform bool uIsGolden;

        // Wave Base
        uniform float uGoldenWaveBaseFrequency;
        uniform float uGoldenWaveBaseStrength;
        uniform float uGoldenWaveBaseSpeed;
        uniform float uGoldenWaveBaseAlpha;

        // Excellent
        uniform float uGoldenColorSpeed;
        uniform vec3 uGoldenColorStop1;
        uniform vec3 uGoldenColorStop2;
        uniform vec3 uGoldenColorStop3;

        float getGoldenFlashAlpha(float perlingStrength) {
            float filledFlashAlpha = mix(0.3, .5, perlingStrength);
            float strokeFlashAlpha = 1. - smoothstep(0., 1., abs(perlingStrength));
            strokeFlashAlpha = mix(0., .5, strokeFlashAlpha);
            float combinedFlashFilledAlpha = max(filledFlashAlpha, strokeFlashAlpha);
            float solidCombined = max(0.1, combinedFlashFilledAlpha);
            return solidCombined;
        }
        float getGoldenSolidFlashAlpha(float perlingStrength) {
            return mix(0., .2, smoothstep(0., 1., perlingStrength+.5));
        }
        vec3 getGoldenColor(vec2 position) {
            float t = (sin(position.x + uTime * uGoldenColorSpeed) + 1.)/2.;
            float t2 = (sin(position.y + uTime * uGoldenColorSpeed) + 1.)/2.;
            vec3 color = mix(normalize(uGoldenColorStop1), mix(normalize(uGoldenColorStop2), normalize(uGoldenColorStop3), t), t2);
            return color;
        }
    `
    const fragmentShaderFooter = `
        if (uIsGolden) {
            // Choose Color
            vec3 colorGoldenAccent = getGoldenColor(vPosition.xy);

            // Base Wave lvl 7+
            float t_waveBase = uTime * uGoldenWaveBaseSpeed;
            float waveBaseAccentPerling = perlin4d(vec4(vec3(vPosition.x - t_waveBase, vPosition.y - t_waveBase, 1.) * uGoldenWaveBaseFrequency, 1.)) * uGoldenWaveBaseStrength;
            float waveBaseAccentAlpha = getGoldenFlashAlpha(waveBaseAccentPerling);
            vec3 waveBaseAccentColor = normalize(colorGoldenAccent) * waveBaseAccentAlpha * uGoldenWaveBaseAlpha;

            baseColor += waveBaseAccentColor;
        }
    `

    const uniforms = {
        // Global
        uTime: { value: 0 },

        uIsGolden: { value: uIsGolden },

        // Excellent
        uGoldenColorSpeed: { value: 5.0 },
        uGoldenColorStop1: { value: { r: 255, g: 168, b: 0 } },
        uGoldenColorStop2: { value: { r: 255, g: 122, b: 0 } },
        uGoldenColorStop3: { value: { r: 255, g: 185, b: 0 } },

        // Wave Base
        uGoldenWaveBaseFrequency: { value: 3.0 },
        uGoldenWaveBaseStrength: { value: 0.75 },
        uGoldenWaveBaseSpeed: { value: 0.7 },
        uGoldenWaveBaseAlpha: { value: 1. },
    }

    return {
        injectFragmentShader: {
            header: fragmentShaderHeader,
            footer: fragmentShaderFooter
        },
        uniforms: uniforms,
    }
}