// export const CAMERA_POSITION = [ -8.84, 8.84, 3.66 ] // used for camera, to save distance to object
// export const CAMERA_POSITION = [ -8.84, 10, 3.66 ] // used for camera, to save distance to object
export const CAMERA_POSITION = [ -11.05, 30, 4.575 ] // used for camera, to save distance to object
// export const CAMERA_POSITION = [ -8, 8, 8 ] // used for camera, to save distance to object
// export const CAMERA_POSITION = [ 0, 15, 0] // for dev
export const CAMERA_ZOOMIN = .3 // 30%

export const DAMAGE_COLORS = {
    ignoreDefence: 0xFFE600,
    excellent: 0x00FF19,
    critical: 0x00F0FF,
    other: 0xFFC940,
    miss: 0xD9D9D9
}