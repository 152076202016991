import { PerspectiveCamera } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import { useEffect, useRef } from "react"
import { Vector3 } from "three"
import { lerp } from "three/src/math/MathUtils"

export const Camera = () => {
    const ref = useRef<any>()
    useFrame(({ pointer, camera }) => {
        camera.position.x = lerp(camera.position.x, pointer.x, 0.075)
    })

    useEffect(() => {
        ref.current.lookAt(0, 0, 0)
    }, [])

    return <PerspectiveCamera makeDefault position={[0, 2, 7]} ref={ref} />
}