import React from "react"


import { useUi } from "views/Game/GamePlay/UserInterface3D/useUi"
import { useCloud } from 'EventCloud/useCloud'
import { useFighter } from 'views/Game/GamePlay/Fighter/useFighter'
import { useCore } from "views/Game/GamePlay/useCore"

import type { Fighter } from "interfaces/fighter.interface"
import { ThreeEvent } from "@react-three/fiber"

export const usePointerEvents = (npc: Fighter) => {
    const nameColor = React.useRef<0xFFFFFF | 0xFF3300>(0xFFFFFF)
    const setTarget = useCloud(state => state.setTarget)
    const fighter = useFighter(state => state.fighter)
    const setCursor = useUi(state => state.setCursor)
    const setHoveredItems = useCore(state => state.setHoveredItems)


    // Set target & hover
    const handlePointerEnter = React.useCallback((e) => {
        if (npc.isDead) { return }
        e.stopPropagation()
        nameColor.current = 0xFF3300
        setCursor('pointer')
        setHoveredItems(npc, 'add')
    }, [npc])

    const handlePointerLeave = React.useCallback(() => {
        nameColor.current = 0xFFFFFF
        setCursor('default')
        setHoveredItems(npc, 'remove')
    }, [npc])

    const handleLeftClick = React.useCallback((e) => {
        if (npc.isDead) { return }
        e.stopPropagation()
        setTarget(npc, fighter.skills[0])
    }, [npc])


    return {
        nameColor,
        handlePointerEnter,
        handlePointerLeave,
        handleLeftClick,
    }
}