export const lorencia = generateWorldArray('lorencia', 2) // 5x5

type WorldObject = {
    name: string
    url: string
};

function generateWorldArray(name: string, size: number): WorldObject[] {
    const result: WorldObject[] = []

    for (let x = -size; x <= size; x++) {
        for (let y = -size; y <= size; y++) {
            const obj = {
                name: `${name}_${x}_${y}`,
                url: `models/worlds/${name}/${name}_${x}_${y}.glb`
            };
            result.push(obj)
        }
    }

    return result;
}