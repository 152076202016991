import { Plane } from "@react-three/drei"
import Button from "./Button"
import { Texture } from "three"

interface Props {
    name: string
    args?: [number, number, number]
    position?: [number, number, number]
    colorMap: Texture
    hoverColorMap: Texture
    onPointerEnter?: () => void
    onPointerLeave?: () => void
    onClick?: () => void
    visible?: boolean
    children?: any
}
export const ButtonPlane = ({
    name,
    args = [45, 45, 1],
    position,
    colorMap,
    hoverColorMap,
    onPointerEnter,
    onPointerLeave,
    onClick,
    visible = true,
    children
}: Props) => {
    return (
        <Button 
            name={name}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
            onClick={onClick}
            position={position}
            visible={visible}
        >
            <Plane
                args={args} 
                userData={{ colorMap, hoverColorMap }}
                // @ts-expect-error
                onPointerEnter={(e) => visible && ((e.object as THREE.Mesh).material.map = e.object.userData.hoverColorMap)}
                // @ts-expect-error
                onPointerLeave={(e) => visible && ((e.object as THREE.Mesh).material.map = e.object.userData.colorMap)}
            >
                <meshBasicMaterial map={colorMap} transparent={true} />
                { children }
            </Plane>
        </Button>
    )
}