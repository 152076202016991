import { useCloud } from "EventCloud/useCloud"
import { Slots } from "./Slots/Slots"
import { useUi } from "./useUi"
import { Aling } from "./components/Align"
import { useRef } from "react"
import { PlaneButton } from "./components/PlaneButton"
import { useGoldEnter } from "views/Game/GamePlay/UserInterface2D/GoldEnter/GoldEnter"
import { Plane, useTexture } from "@react-three/drei"
import { CloseButton } from "./components/CloseButton"
import { UiText } from "./components/UiText"
import { useBackpackItemMaps, useBackpackSlotsMaps, useToWarehouseSlotsMaps } from "./Backpack/useBackpackSlotsMaps"
import { ButtonPlane } from "./components/ButtonPlane"
import * as THREE from 'three'
import { InventorySlot } from "interfaces/inventory.interface"
import BigNumber from "bignumber.js"

const warehouseSlot: InventorySlot = {
    inTrade: false,
    itemAttributes: {
        tokenId: BigNumber(0),
        // @ts-expect-error
        itemParameters: {
            itemWidth: 0,
            itemHeight: 0,
        },
    },
    qty: 0,
    itemHash: '',
}

export const Vault = () => {
    const [vault] = useCloud(state => [state.vault])
    const [isOpenedBackpack, isOpenedVault, cellSize, closeVault] = useUi(state => [state.isOpened, state.isOpenedVault, state.cellSize, state.closeVault])
    const [updateItemVaultPosition, moveItemFromVaultToBackpack, dropVaultItem] = useCloud(state => [state.updateItemVaultPosition, state.moveItemFromVaultToBackpack, state.dropVaultItem])
    const [equipVaultItem] = useCloud(state => [state.equipVaultItem])
    const [moveItemFromVaultToWarehouse] = useCloud(state => [state.moveItemFromVaultToWarehouse])

    const [openGoldEnter] = useGoldEnter(state => [state.open])

    const relatedNode = useRef<THREE.Group>()
    const relatedNode2 = useRef<THREE.Group>()

    const slotsMaps = useBackpackSlotsMaps()
    const bpItemMaps = useBackpackItemMaps()
    const bgMap = useTexture('/assets/backpack-menu/vault4-bg-color.png')
    const [topupColor, topupHoverColor] = useTexture(['/assets/backpack-menu/top-up-gold-color.png','/assets/backpack-menu/top-up-gold-hover-color.png'])
    const [withdrawColor, withdrawHoverColor] = useTexture(['/assets/backpack-menu/withdraw-gold-color.png','/assets/backpack-menu/withdraw-gold-hover-color.png'])
    const warehouseColor = useToWarehouseSlotsMaps()


    if (!vault) return null

    return (
        <Aling visible={isOpenedVault} ref={relatedNode} alignX="right" hoverId="vaultAlign">
            <Plane position={[-660, 40, 0]} args={[420, 826, 1]} ref={relatedNode2 as any}>
                <meshBasicMaterial map={bgMap} transparent={true} />
                <CloseButton 
                    name="vault"
                    position={[140, 345, 10]}
                    onClick={closeVault}
                    visible={isOpenedVault}
                />
                <UiText position={[0, 345, 10]} color={'#d48932'}>Vault</UiText>
                <UiText anchorX="right" position={[120, -282, 10]} color={'#dc801f'}>{ vault.gold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</UiText>
                <group position-z={1}>
                    <Slots
                        id='ID_VAULT'
                        type='backpack'
                        enableRender={isOpenedVault}
                        cellWidth={35}
                        cellHeight={35}
                        grid={vault.grid}
                        items={vault.items}
                        width={vault.grid.length}
                        height={vault.grid[0].length}
                        position={[-121, 285, 0]}
                        maps={slotsMaps}
                        itemMaps={bpItemMaps}
                        events={[
                            { id: 'ID_VAULT', type: 'update', handler: (props) => updateItemVaultPosition(props.item.itemHash, props.position) },
                            { id: 'ID_BACKPACK', type: 'transferTo', handler: (props) => moveItemFromVaultToBackpack(props.item.itemHash, props.position) },
                            { id: 'ID_EQUIPMENT', type: 'transferTo', handler: (props) => equipVaultItem(props.item.itemHash, props.slot) },
                            { id: 'ID_TO_WAREHOUSE', type: 'transferToWithNotAllowed', handler: (props) => moveItemFromVaultToWarehouse(props.item.itemHash) },
                            { id: '', type: 'drop', handler: (props) => dropVaultItem(props.item.itemHash, props.coordinate) },
                        ]}
                        relatedNodes={[relatedNode, relatedNode2]}
                    />
                    <ButtonPlane
                        visible={isOpenedVault}
                        name="topup-gold"
                        colorMap={topupColor}
                        hoverColorMap={topupHoverColor}
                        position={[118, -328, 0]}
                        args={[45, 45, 1]}
                        onClick={() => openGoldEnter(useCloud.getState().moveGoldFromBackpackToVault)}
                    />
                    <Slots
                        id='ID_TO_WAREHOUSE'
                        type='backpack'
                        enableRender={isOpenedVault}
                        cellWidth={160}
                        cellHeight={62}
                        grid={[[false]]}
                        items={{}}
                        width={1}
                        height={1}
                        position={[-66, -328, 0]}
                        maps={warehouseColor}
                        itemMaps={bpItemMaps}
                        events={[]}
                        relatedNodes={[relatedNode, relatedNode2]}
                    />
                    <ButtonPlane
                        visible={isOpenedVault}
                        name="widraw-gold"
                        colorMap={withdrawColor}
                        hoverColorMap={withdrawHoverColor}
                        position={[60, -328, 0]}
                        args={[45, 45, 1]}
                        onClick={() => openGoldEnter(useCloud.getState().moveGoldFromVaultToBackpack)}
                    />
                </group>
            </Plane>
        </Aling>
    )
}