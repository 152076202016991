import React, { useRef } from "react"
import { useFrame } from "@react-three/fiber"
import { useAnimations } from "@react-three/drei"
import { euclideanDistance } from "../utils/euclideanDistance"
import { calcDirection } from "../utils/calcDirection"
import { useCore } from "../useCore"
import { useCloud } from "EventCloud/useCloud"
import { useFighter } from "./useFighter"
import { useControls } from "views/Game/GamePlay/Controls/useControls"
import { useEquipmentChange } from "./hooks/useEquipmentChange"

import { useEvent } from "views/Game/GamePlay/hooks/useEvent"
import { useSkillEffects } from "./hooks/useSkillEffects/useSkillEffects"

import * as THREE from 'three'
import { usePlayerSkin } from "./hooks/usePlayerSkin/usePlayerSkin"
import { ObjectElements } from "../components/ObjectElements"
import { useActionSound, useDeathEventSound } from "core/SoundManager/SoundEvents"
import { useSpawn } from "./hooks/useSpawn"

const Fighter = React.memo(function Fighter() {
    const spawned = React.useRef(false)
    const dead = React.useRef(false)
    const submitAttack = useCloud(state => state.submitAttack)
    const [target, setTarget] = useCloud(state => [state.target, state.setTarget])
    const [fighter, fighterNode, move] = useFighter(state => [state.fighter, state.fighterNode, state.move])
    const [matrixCoordToWorld, worldCoordToMatrix] = useCore(state => [state.matrixCoordToWorld, state.worldCoordToMatrix])
    const setPosition = useFighter(state => state.setPosition)
    const setDirection = useControls(state => state.setDirection)
    const elementsRef = React.useRef<THREE.Group | null>(null)

    // console.log("figher", fighter)
    // TODO: Fix this
    // This state used just for Sword equipment on move
    const isMoving = useFighter(state => state.isMoving)
    const setAllActions = useFighter(state => state.setAllActions)

    const { model, animations, updatePoses, uniforms } = usePlayerSkin(fighter)
    const { actions } = useAnimations(animations, fighterNode)

    React.useLayoutEffect(() => setAllActions(actions), [actions])
    const [ setAction ] = useFighter(state => [state.setAction])

    const { effects, play: playSkillEffect } = useSkillEffects()

    const [playSpawn] = useSpawn(uniforms)
    const playDeathSound = useDeathEventSound(fighter)


    // Manage fighter changes from server
    React.useEffect(() => {
        // console.log('[Fighter]: updated', fighter)
        if (!fighter || !fighterNode.current) { return }

        if (fighter?.isDead) {
            spawned.current = false
            dead.current = true
            playDeathSound()
            setAction('die')
            return 
        }

        dead.current = false

        if (fighter?.coordinates) {
            if (!spawned.current) {
                playSpawn()
                spawned.current = true
                setPosition(matrixCoordToWorld(fighter?.coordinates))
                setAction('stand') 
            }

            // Check if the position differ by more than 2 cells
            if (euclideanDistance(fighter.coordinates, worldCoordToMatrix(fighterNode.current.position)) > 2) {
                setPosition(matrixCoordToWorld(fighter?.coordinates))
            }
        }
    }, [fighter, fighterNode.current, playDeathSound])

    useFrame(() => {
        if (!fighterNode.current) { return }
        if (dead.current) { return }
        fighterNode.current.rotation.y = useControls.getState().direction
        elementsRef.current.rotation.y = -fighterNode.current.rotation.y
    })

    const devClickedCoordinate = useRef<THREE.Mesh>()
    // React on Target and Do Action
    React.useEffect(() => {
        if (!target?.target) { return }
        if (dead.current) { return }
        const objectCoordinate = target.target.coordinates

        if (target?.skill) {
            // Check the distance, if too long - move fighter
            // target.skill.activeDistance + 0.5 for diagonal
            if (euclideanDistance(objectCoordinate, fighter.coordinates) <= target.skill.activeDistance + 0.5) {
                const direction = calcDirection(worldCoordToMatrix(fighterNode.current.position), objectCoordinate)
                setDirection(Math.atan2(direction.dx, direction.dz)) // Additionally set direction on attack, to be sure that fighter look at opponent
                setAction('attack')
                submitAttack(direction, target)
                return
            }

            // FOR TEST
            const objectWorldCoordinate = matrixCoordToWorld(objectCoordinate)
            if (devClickedCoordinate.current) {
                devClickedCoordinate.current.position.x = objectWorldCoordinate.x
                devClickedCoordinate.current.position.z = objectWorldCoordinate.z
            }
            // 
            const to = matrixCoordToWorld(useCore.getState().getTargetSquareWithAttackDistance(fighter.coordinates, objectCoordinate, target.skill.activeDistance))
            move(
                to,
                target
            )
            setTarget(null, null)
        }

    }, [target])


    // Change Animations On Equipment Change
    useEquipmentChange(fighter, (changes) => {
        if (dead.current) { return }
        if (changes.changedSlots.includes(6) || changes.changedSlots.includes(7)) {
            const action = useFighter.getState().action
            if (action.includes('stand')) { setAction('stand') }
        }
    })

    // Animate Skills on Server Event
    useEvent(fighter, 'skill', (event, removeEvent) => {
        if (dead.current) { return }
        playSkillEffect(event)
        removeEvent(event)
    })

    const action = useFighter(state => state.action)
    useActionSound(fighter, action)
    
    // TODO: Think about this
    // If we have so strong connection between equipment change & actions
    // Should we refactor this in some more correct logic?
    const timeout = React.useRef<any>()
    React.useEffect(() => {
        clearTimeout(timeout.current)
        if (!isMoving) {
            timeout.current = setTimeout(() => updatePoses(isMoving), 50)
            return
        }
        updatePoses(isMoving)
    }, [isMoving])

    return (
        <group ref={fighterNode as any} scale={1}>
            <primitive object={model} />
            <ObjectElements 
                ref={elementsRef}
                fighter={fighter} 
                heatBox
                nameWithMessage
            />
            {effects.map((_, i) => <primitive object={_} key={i} />)}
        </group>
    )
})

export default Fighter