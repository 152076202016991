import styled from "styled-components";
import { CustomSelect } from "./Select";
import { StyledText } from "../../styles";

const StyledFilter = styled.div`
    position: absolute;
    top: 197px;
    left: 0;
    width: 100%;
    padding: 0 76px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
        margin-right: 5px;
        font-size: 14px;
    }

    .filters {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
    }

    .search {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        .search-input {
            position: relative;
            overflow: hidden;
            width: 350px;
            height: 28px;
            display: flex;
            padding: 0px 20px 0px 30px;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            border-radius: 15px;
            border: 0.5px solid #56565687;
            background: #25252561;
            box-shadow: 0px -4px 4px 0px rgba(79, 79, 79, 0.45) inset;

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: none;
                background: transparent;
                padding-left: 16px;
                font-size: 14px;
                color: #D4D4D4;
                font-family: "Acari Sans";
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
`;

const classOptions = [
    { value: "Warrior", label: "Warrior" },
    { value: "Mage", label: "Mage" },
];

const typeOptions = [
    { value: "Accessory", label: "Accessory" },
    { value: "Armor", label: "Armor" },
    { value: "Weapon", label: "Weapon" },
];

const costOptions = [
    { value: "0-100", label: "0-100" },
    { value: "100-200", label: "100-200" },
    { value: "200-300", label: "200-300" },
];

export const Filter = () => {
    return (
        <StyledFilter>
            <div className="filters">
                <StyledText>Filters</StyledText>
                <CustomSelect
                    className="filter-select"


                    placeholder="Class"
                    options={classOptions}
                />
                <CustomSelect
                    className="filter-select"
                    placeholder="Type"
                    options={typeOptions}
                />
                <CustomSelect
                    className="filter-select"
                    placeholder="Cost"
                    options={costOptions}
                />
            </div>
            <div className="search">
                <StyledText>Search</StyledText>
                <div className="search-input">
                    <input type="text" />
                </div>
            </div>
        </StyledFilter>
    )
}
