import { useGame } from 'core/useGame'
import styled from 'styled-components'

const OpenButton = () => {
    const [location, setLocation] = useGame(state => [state.location, state.setLocation])
    if (location === 'menu') { return null }
    return (
        <StyledButton onClick={() => {
            if (location === 'world') {
                setLocation('menu')
                return
            }
        }}>Change Character</StyledButton>
    )
}

const StyledButton = styled.div`
    position: absolute;
    top: 32px;
    right: 100px;
    font-size: 12px;
    color: rgba(255, 255, 255, .5);
    cursor: pointer;
    pointer-events: all;
    text-decoration: underline;
    user-select: none;
`

export default OpenButton