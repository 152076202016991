import { perlin4d } from "./presets/perlin4d"
import { normalizeColor } from "./presets/normalizeColor"

export const shader_common = () => {
    const vertexShaderHeader = `
        varying vec2 vUv;
        varying vec3 vPosition;
        varying vec4 vWorldPosition;
    `
    const vertexShaderFooter = `
        vUv = uv;
        vWorldPosition = modelMatrix * vec4(position, 1.0);
        vPosition = position;
    `
    const fragmentShaderHeader = `
        varying vec2 vUv;
        varying vec3 vPosition;
        varying vec4 vWorldPosition;

        // Time, Speed
        uniform float uTime;

        ${perlin4d}
        ${normalizeColor}
    `
    const fragmentShaderFooter = `
        // Model Color
        vec3 baseColor = gl_FragColor.rgb * gl_FragColor.a;
        float baseAlpha = gl_FragColor.a;
    `
    const fragmentShaderGlFragColor = `
        // Model Color
        gl_FragColor = vec4(baseColor, baseAlpha);
    `

    const uniforms = {
        uTime: { value: 0 },
    }

    return {
        injectVertexShader: {
            header: vertexShaderHeader,
            footer: vertexShaderFooter
        },
        injectFragmentShader: {
            header: fragmentShaderHeader,
            footer: fragmentShaderFooter,
            glFragColor: fragmentShaderGlFragColor
        },
        uniforms,
    }
}