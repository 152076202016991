import { useControls } from "leva"
import React from "react"
import { useUi } from "./UserInterface3D/useUi"
import { useCommandLine } from "./UserInterface2D/CommandLine/useCommandLine"
import { subscribeDisableContextMenu, unsubscribeDisableContextMenu } from "./utils/contextMenu"
import { useCore } from "./useCore"
import { useFighter } from "./Fighter/useFighter"
import { OrbitControls, Stats } from "@react-three/drei"
import * as THREE from 'three'
import Camera from "./Camera"
import GLTFLoader from "./Models/GLTFLoader"
import { DPRLimiter } from "./UserInterface2D/Settings/DPRLimiter"
import NpcList from "./Npc/NpcList"
import DroppedItemList from "./DroppedItem/DroppedItemList"
import OtherFighterList from "./Fighter/Players/Players"
import Fighter from "./Fighter/Fighter"
import { GameMap } from "./GameMap/GameMap"
import Controls from "./Controls/Controls"
import FloatingDamage from "./FloatingDamage/FloatingDamage"
import UserInterface3D from "./UserInterface3D/UserInterface3D"
import Light from "./Light"
import { DevHelpers } from "./devHelpers/DevHelpers"
import { DrawCalls } from "./components/DrawCalls"
import { View } from "core/Tunnel/View"

export const GamePlay = () => {
    const eventsNode = useUi(state => state.eventsNode)
    const fighterNode = useFighter(state => state.fighterNode)
    const fighter = useFighter(state => state.fighter)

    React.useEffect(() => {
        if (eventsNode.current) {
            // TODO: use global node instead providing one
            useCommandLine.getState().subscribeCommandLine(eventsNode.current)
            // 
            useUi.getState().subscribeBackpack()
            useUi.getState().subscribePressedKeys()
            subscribeDisableContextMenu()
        }
        return () => {
            useCommandLine.getState().unsubscribeCommandLine()
            useUi.getState().unsubscribeBackpack()
            useUi.getState().unsubscribePressedKeys()
            unsubscribeDisableContextMenu()
        }
    }, [eventsNode.current])

    // For Dev
    const [devMode, setDevMode] = useCore(state => [state.devMode, state.setDevMode])
    const dev = useControls('Camera', { freeCamera: false })
    React.useEffect(() => void setDevMode(dev.freeCamera), [dev])
    // 

    if (!fighter) { return null }

    return (
        <>
            <View>
                {/* {!devMode ? <fog attach="fog" args={['#010021', 10, 22]}></fog> : null} */}
                {/* <fog attach="fog" args={!devMode ? ['#1c1b39', 20, 1000] : ['#1c1b39', 10000, 10001]}></fog> */}
                {devMode ? <OrbitControls target={fighterNode.current?.position || new THREE.Vector3(0, 0, 0)} /> : <></>}

                {/* Game Stats (dev) */}
                <Stats className='stats' />
                <DrawCalls />
                <DevHelpers />

                {/* Game Settings */}
                <DPRLimiter />

                <React.Suspense fallback={null}>
                    <GLTFLoader>

                        {/* Instances */}
                        <GameMap />
                        <Fighter />
                        <NpcList />
                        <DroppedItemList />
                        <OtherFighterList />
                        <FloatingDamage />

                        {/* Game Interface */}
                        <UserInterface3D />

                        {/* Game Camera & Lights */}
                        <Camera />
                        <Light />
                        
                    </GLTFLoader>
                </React.Suspense>

                {/* Game Controls */}
                <Controls />

                {/* Game render */}
                {/* <Postprocessing /> */}
            </View>
        </>
    )
}