import React from "react"
import * as THREE from 'three'
import { useFrame } from "@react-three/fiber"

import { optionals, preset, requirements } from "./presets"
import { useEquimentPoses } from "./useEquipmentPoses"
import { getShaderedModel } from "views/Game/GamePlay/Models/utils/getShaderedModel"

import { Fighter } from "interfaces/fighter.interface"
import { InventorySlot } from "interfaces/inventory.interface"


export const useFighterEquipment = (fighter: Fighter, fighterModel: THREE.Group | THREE.Mesh) => {
    const _equipment = React.useMemo(() => fighter.equipment?.items || [], [fighter])
    const equipment = React.useMemo(() => Object.values(_equipment), [_equipment])

    const _uniforms = React.useRef({ uTime: { value: 0 }, uSpawnHiddenAlpha: { value: 1 } })

    // Forward ref
    const ready = React.useRef(false)
    const standard = React.useRef<{
        'armature': THREE.SkinnedMesh | null
        'standard_armour': THREE.SkinnedMesh | null,
        'standard_helmet': THREE.SkinnedMesh | null,
        'standard_pants': THREE.SkinnedMesh | null,
        'standard_gloves': THREE.SkinnedMesh | null,
        'standard_boots': THREE.SkinnedMesh | null,
    }>({
        'armature': null,
        'standard_armour': null,
        'standard_helmet': null,
        'standard_pants': null,
        'standard_gloves': null,
        'standard_boots': null,
    })

    function getStandard(bodyPartName: string) {
        const keys = Object.keys(standard.current)
        const key = keys.find(_ => _.includes(bodyPartName))
        if (!key) return null
        return standard.current[key]
    }

    // Find Armature & Skeletons
    React.useEffect(() => {
        const standardArmature = (fighterModel.getObjectByName('Armature') as THREE.SkinnedMesh);
        const standardArmour = (fighterModel.getObjectByName('armour') as THREE.SkinnedMesh);
        const standardHelmet = (fighterModel.getObjectByName('helm') as THREE.SkinnedMesh);
        const standardPants = (fighterModel.getObjectByName('pants') as THREE.SkinnedMesh);
        const standardGloves = (fighterModel.getObjectByName('gloves') as THREE.SkinnedMesh);
        const standardBoots = (fighterModel.getObjectByName('boots') as THREE.SkinnedMesh);
        if (!standardArmature) { console.error('[FighterModel]: "Armature" not found') }
        if (!standardArmour) { console.error('[FighterModel]: "armour" not found') }
        if (!standardHelmet) { console.error('[FighterModel]: "helmet" not found') }
        if (!standardPants) { console.error('[FighterModel]: "pants" not found') }
        if (!standardGloves) { console.error('[FighterModel]: "gloves" not found') }
        if (!standardBoots) { console.error('[FighterModel]: "boots" not found') }

        standard.current = {
            'armature': standardArmature,
            'standard_armour': standardArmour,
            'standard_helmet': standardHelmet,
            'standard_pants': standardPants,
            'standard_gloves': standardGloves,
            'standard_boots': standardBoots,
        }

        ready.current = true
    }, [fighterModel])

    const { addPose, updatePose, removePose, updatePoses } = useEquimentPoses(fighterModel)

    const lastEquipment = React.useRef<InventorySlot[]>([preset['boots'] as any])
    // Take on Requirements
    React.useEffect(() => {
        if (!ready.current) { return }
        if (arraysAreEqual(lastEquipment.current, equipment)) { return }

        // Take on/off Requirements (toggle material)
        requirements.forEach((bodyPartName) => {
            const requiredEquipment = equipment.find(item => item.itemAttributes.name.toLowerCase().includes(bodyPartName))
            const alreadyInArmatureObject = standard.current['armature'].getObjectByName(bodyPartName) as THREE.SkinnedMesh
            if (requiredEquipment && alreadyInArmatureObject && requiredEquipment?.itemHash === alreadyInArmatureObject?.userData?.itemHash) { return }
            if (requiredEquipment) {
                const { uniforms, scene: model } = getShaderedModel(requiredEquipment.itemAttributes.name, requiredEquipment, _uniforms)
                model.userData.itemHash = requiredEquipment.itemHash
                // @ts-expect-error
                applyGeometries(model, bodyPartName)
                return
            }

            // Add standard object if no equipment
            const { uniforms, scene: model } = getShaderedModel(preset[bodyPartName].itemAttributes.name, preset[bodyPartName], _uniforms)
            model.userData.itemHash = preset[bodyPartName].itemHash
            // @ts-expect-error
            applyGeometries(model, bodyPartName)
        })

        // Take on/off Optionals (use Binders)
        optionals.forEach((partName) => {
            const requiredEquipment = equipment.find(item => item.itemAttributes.name.toLowerCase().includes(partName))
            const alreadyInArmatureObject = standard.current['armature'].getObjectByName(partName) as THREE.SkinnedMesh
            if (requiredEquipment && alreadyInArmatureObject && requiredEquipment?.itemHash === alreadyInArmatureObject?.userData?.itemHash) { return }
            if (alreadyInArmatureObject) {
                removePose(alreadyInArmatureObject.userData.itemHash)
            }
            if (requiredEquipment) {
                // console.log('model', requiredEquipment.itemAttributes.name, requiredEquipment)
                const { scene: model, animations } = getShaderedModel(requiredEquipment.itemAttributes.name, requiredEquipment)
                const bindersData = JSON.parse(model.userData.binders)
                model.userData.itemHash = requiredEquipment.itemHash
                model.name = partName
                addPose(requiredEquipment.itemHash, bindersData, model as THREE.Mesh, animations)
                updatePose(requiredEquipment.itemHash)
            }
        })

        function applyGeometries(model: THREE.SkinnedMesh, bodyPartName: string) {
            const standardSkinned = getStandard(bodyPartName) as THREE.SkinnedMesh;
            if (!standardSkinned) { return console.error('[FighterModel]: Something wrong with skinned mesh name') } 

            // console.log('geometries', bodyPartName, standardSkinned.geometry?.attributes, model.geometry?.attributes)
            // console.log(standardSkinned.skeleton, model.skeleton)
            // console.log('|-------|')
            // console.log('model', bodyPartName, model)

            standardSkinned.geometry = model.geometry
            standardSkinned.material = model.material
        }
        lastEquipment.current = equipment
    }, [equipment])

    useFrame(({ clock }) => {
        // console.log(_uniforms.current.uTime)
        if (_uniforms.current.uTime) {
            _uniforms.current.uTime.value = clock.getElapsedTime()
        }
    })

    return {
        updatePoses,
        uniforms: _uniforms
    }
}

function arraysAreEqual(arr1: InventorySlot[], arr2: InventorySlot[]) {
    if (arr1.length !== arr2.length) {
        return false
    }

    // Compare each element in both arrays
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i].itemHash !== arr2[i].itemHash) {
            return false
        }
    }

    return true
}