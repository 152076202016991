export interface SoundConfig {
    id: string;
    src: string;
    volume: number;
    loop?: boolean;
    load: 'preload' | 'loadQueued' | 'auto';
    rate: number;
}

// preload: load the sound immediately
// loadQueued: load the sound when needed
// auto: loads up when the sound is played

export const sounds = {
    BACKGROUND_MENU: 'backgroundMenu',
    BACKGROUND_WORLD: 'backgroundWorld',
    GRASS_WALK: 'grassWalk',
    MALEE: 'malee',
    SWORD: 'sword',
    SPIDER_WALK: 'spiderWalk',
    SPIDER_ATTACK: 'spiderAttack',
    FIGHTER_DEATH: 'fighterDeath',

    BULL_ATTACK: 'bullAttack',
    DRAGON_ATTACK: 'dragonAttack',
    HAMMER_ATTACK: 'hammerAttack',
    SKILL_SLASH_ATTACK: 'skillSlashAttack',
    SKILL_TRIPPLE_SHOT_ATTACK: 'skillTrippleShotAttack',
    SKILL_DARK_SPIRITS_ATTACK: 'skillDarkSpiritsAttack',
    SKILL_ARROW_ATTACK: 'skillArrowAttack',

    NPC_DEATH: 'npcDeath',
    NPC_SMALL_WALK: 'npcSmallWalk',
    NPC_BIG_WALK: 'npcBigWalk',

    ITEM_DROP: 'itemDrop',
    ITEM_PICKUP: 'itemPickup',
    ITEM_PICKUP_JEWEL: 'itemPickupJewel',
    ITEM_EQUIP: 'itemEquip',
    ITEM_UPGRADE: 'itemUpgrade',
} as const;

export type SoundId = keyof typeof sounds;
export const maxSoundDistance = 10
export const soundsConfig: SoundConfig[] = [
    { id: sounds.BACKGROUND_MENU, src: '/sounds/background-menu.wav', volume: 0.15, loop: true, load: 'preload', rate: 1.0 },
    { id: sounds.BACKGROUND_WORLD, src: '/sounds/background-world.mp3', volume: 0.15, loop: true, load: 'preload', rate: 1.0 },
    { id: sounds.GRASS_WALK, src: '/sounds/grass-walk.mp3', volume: .3, loop: true, load: 'preload', rate: 0.9 },
    { id: sounds.NPC_DEATH, src: '/sounds/npc-death.mp3', volume: .5, load: 'loadQueued', rate: 1.0 },
    { id: sounds.MALEE, src: '/sounds/malee.mp3', volume: 1.0, load: 'auto', rate: 1.0 },
    { id: sounds.SWORD, src: '/sounds/sword.mp3', volume: 1.0, load: 'auto', rate: 1.0 },
    { id: sounds.SPIDER_WALK, src: '/sounds/spider-walk.mp3', volume: .5, loop: true, load: 'preload', rate: 2 },
    { id: sounds.SPIDER_ATTACK, src: '/sounds/spider-attack.mp3', volume: .2, load: 'auto', rate: 1.0 },
    { id: sounds.FIGHTER_DEATH, src: '/sounds/fighter-death.mp3', volume: 1.0, load: 'auto', rate: 1.0 },

    { id: sounds.BULL_ATTACK, src: '/sounds/bull-attack.mp3', volume: .2, load: 'auto', rate: 1.0 },
    { id: sounds.DRAGON_ATTACK, src: '/sounds/dragon-attack.mp3', volume: .2, load: 'auto', rate: 1.0 },
    { id: sounds.HAMMER_ATTACK, src: '/sounds/hammer-attack.mp3', volume: .2, load: 'auto', rate: 1.0 },
    { id: sounds.SKILL_SLASH_ATTACK, src: '/sounds/skill-slash-attack.mp3', volume: .2, load: 'auto', rate: 1.0 },
    { id: sounds.SKILL_TRIPPLE_SHOT_ATTACK, src: '/sounds/skill-tripple-shot-attack.mp3', volume: .2, load: 'auto', rate: 1.0 },
    { id: sounds.SKILL_DARK_SPIRITS_ATTACK, src: '/sounds/skill-dark-spirits-attack.mp3', volume: .2, load: 'auto', rate: 1.0 },
    { id: sounds.SKILL_ARROW_ATTACK, src: '/sounds/skill-arrow-attack.mp3', volume: .2, load: 'auto', rate: 1.0 },

    { id: sounds.NPC_SMALL_WALK, src: '/sounds/npc-small-walk.mp3', volume: .5, loop: true, load: 'auto', rate: 1 },
    { id: sounds.NPC_BIG_WALK, src: '/sounds/npc-big-walk.mp3', volume: .5, loop: true, load: 'auto', rate: 0.8 },


    { id: sounds.ITEM_DROP, src: '/sounds/item-drop.mp3', volume: .6, load: 'auto', rate: 1 },
    { id: sounds.ITEM_PICKUP, src: '/sounds/item-pickup.mp3', volume: .3, load: 'auto', rate: 1 },
    { id: sounds.ITEM_PICKUP_JEWEL, src: '/sounds/item-pickup-jewel.mp3', volume: .4, load: 'auto', rate: 1 },
    { id: sounds.ITEM_EQUIP, src: '/sounds/item-equip.mp3', volume: .5, load: 'auto', rate: 1 },
    { id: sounds.ITEM_UPGRADE, src: '/sounds/item-upgrade.mp3', volume: .5, load: 'auto', rate: 1 },
];