import { Preload } from "@react-three/drei"
import { Canvas } from "@react-three/fiber"
import { useControls } from "leva"
import React from "react"
import * as THREE from 'three'
import { useSettings } from "./GamePlay/UserInterface2D/Settings/useSettings"
import { r3f } from "../../core/Tunnel/Three"
import { useCloud } from "EventCloud/useCloud"

export const Experience = () => {
    const data = useControls('GL', {
        exposure: { value: 1, min: -5, max: 5 },
        encoding: {
            options: {
                'rgb': THREE.SRGBColorSpace,
                'linear': THREE.LinearSRGBColorSpace,
            }
        },
    })

    // Tone Mapping
    React.useLayoutEffect(() => {
        THREE.ShaderChunk.tonemapping_pars_fragment = THREE.ShaderChunk.tonemapping_pars_fragment.replace(

            'vec3 CustomToneMapping( vec3 color ) { return color; }',

            `#define Uncharted2Helper( x ) max( ( ( x * ( 0.15 * x + 0.10 * 0.50 ) + 0.20 * 0.02 ) / ( x * ( 0.15 * x + 0.50 ) + 0.20 * 0.30 ) ) - 0.02 / 0.30, vec3( 0.0 ) )

            float toneMappingWhitePoint = 1.0;

            vec3 contrast( vec3 color ) {
                float c = 0.23;
                // return (color - 0.5) / (1.0 - c) + 0.5;
                return color;
            }

            vec3 CustomToneMapping( vec3 color ) {
                

                const float StartCompression = 0.8 - 0.04;
                const float Desaturation = 0.15;
            
                color *= toneMappingExposure;
            
                float x = min( color.r, min( color.g, color.b ) );
            
                float offset = x < 0.08 ? x - 6.25 * x * x : 0.04;
            
                color -= offset;
            
                float peak = max( color.r, max( color.g, color.b ) );
            
                if ( peak < StartCompression ) return contrast( color );
            
                float d = 1. - StartCompression;
            
                float newPeak = 1. - d * d / ( peak + d - StartCompression );
            
                color *= newPeak / peak;
            
                float g = 1. - 1. / ( Desaturation * ( peak - newPeak ) + 1. );
            
                return contrast( mix( color, vec3( newPeak ), g ) );
            }`

            // vec3 CustomToneMapping( vec3 color ) {
            //     color *= toneMappingExposure;
            //     gl_FragColor.rgb += brightness;
            //     return saturate( Uncharted2Helper( color ) / Uncharted2Helper( vec3( toneMappingWhitePoint ) ) );
            // }
        );
    }, [])
    const enableShadows = useSettings(state => state.enableShadows)

    return (
        <Canvas
            performance={{ min: 0.9 }}
            shadows={{
                // Always Enabled, but in Lights.tsx control render mode
                enabled: enableShadows, 
                // type: THREE.PCFSoftShadowMap
            }}
            gl={{
                powerPreference: "high-performance",
                alpha: false,
                antialias: true,
                toneMappingExposure: data.exposure,
                toneMapping: THREE.CustomToneMapping,
                outputColorSpace: data.encoding,
            }}
        >
            <color attach="background" args={[0x000000]} />
            <r3f.Out/>
            <Preload all />
        </Canvas>
    )
}