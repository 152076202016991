import { Plane, useTexture } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import { useEffect, useRef } from "react"

interface Props {
    min?: number
    max: number
    color?: { r: number, g: number, b: number }
    args?: any,
    position?: [number, number, number],
    name?: string
    current: number
}
export const ExperienceBar = ({
    min = 0,
    max,
    color = { r: 39, g: 214, b: 36 },
    current,
    position = [0, 0, 0],
    name = 'experience-bar',
    args = [505, 8, 1],
    ...props
}: Props) => {
    const [colorMap, alphaMap, overlayMap] = useTexture(['assets/bottom-menu/experience-bar.png', 'assets/bottom-menu/experience-bar-alpha.png', 'assets/bottom-menu/experience-bar-overlay.png'])
    const uniforms = useRef({
        uMin: { value: min },
        uMax: { value: max },
        uCurrent: { value: current },
        uColor: { value: color },
        uOverlayMap: { value: overlayMap }
    })
    useEffect(() => {
        uniforms.current.uMin.value = min
        uniforms.current.uMax.value = max
        uniforms.current.uCurrent.value = current
        uniforms.current.uColor.value = color
    }, [min, max, current, color])

    return (
        <Plane
            name={name}
            position={position}
            args={args}
            {...props}
        >
            <meshBasicMaterial
                transparent={true}
                map={colorMap}
                alphaMap={alphaMap}
                onBeforeCompile={(shader) => {
                    shader.uniforms = {
                        ...shader.uniforms,
                        ...uniforms.current,
                    }
                    // Add Uniforms
                    shader.fragmentShader = `
                        uniform float uMin;
                        uniform float uMax;
                        uniform float uCurrent;
                        uniform vec3 uColor;
                        uniform sampler2D uOverlayMap; 
                    `+shader.fragmentShader
                    // Remove alphamap
                    shader.fragmentShader = shader.fragmentShader.replace(`#include <alphamap_fragment>`, ``)
                    // Add Our Color & reuse alpha map
                    shader.fragmentShader = shader.fragmentShader.replace(`#include <dithering_fragment>`, `
                        float alphaMask = texture2D( alphaMap, vAlphaMapUv ).g;
                        vec3 overlayColor = texture2D( uOverlayMap, vAlphaMapUv ).rgb;
                        float delta = uCurrent / (uMax - uMin);
                        float edge = 0.01;
                        float clampHeight = 1. - smoothstep(delta - edge, delta + edge, vAlphaMapUv.x);
                        vec3 color = vec3(uColor.r / 255., uColor.g / 255., uColor.b / 255.) * alphaMask * clampHeight;
                        gl_FragColor.rgb += color;
                        gl_FragColor.rgb *= overlayColor;
                        #include <dithering_fragment>
                    `)
                }}
            />
        </Plane>
    )
}