import { useMemo } from "react"
import { Fighter } from "interfaces/fighter.interface"
import { useFighterEquipment } from "./useFighterEquipment"
import { getShaderedModel } from "views/Game/GamePlay/Models/utils/getShaderedModel"
import { useFrame } from "@react-three/fiber"

export const usePlayerSkin = (fighter: Fighter) => {
    const { scene, animations, uniforms } = useMemo(() => getShaderedModel('standard_warrior', fighter), ['standard_warrior'])
    // @ts-expect-error
    const { updatePoses, uniforms: equipmentUniforms } = useFighterEquipment(fighter, scene)
    useFrame(({ clock }) => {
        if (uniforms.current?.uTime) {
            uniforms.current.uTime.value = clock.getElapsedTime()
        }
        if (equipmentUniforms.current?.uSpawnHiddenAlpha && uniforms.current?.uSpawnHiddenAlpha) {
            equipmentUniforms.current.uSpawnHiddenAlpha = uniforms.current.uSpawnHiddenAlpha
        }
    })
    return {
        model: scene,
        animations,
        updatePoses,
        uniforms
    }
}