import { Fighter } from "interfaces/fighter.interface"
import { Box, useAnimations, useGLTF } from "@react-three/drei"
import { useEffect, useMemo, useRef } from "react"
import { SkeletonUtils } from "three-stdlib"
import Name from "../GamePlay/components/Name"
import { usePlayerSkin } from "../GamePlay/Fighter/hooks/usePlayerSkin/usePlayerSkin"
import { ObjectElements } from "../GamePlay/components/ObjectElements"

interface Props {
    fighter: Fighter
    position: [number, number, number]
    onClick: () => void
    selected: boolean
}
export const Character = ({ fighter, position, onClick, selected }: Props) => {
    const { model, animations } = usePlayerSkin(fighter)
    const { actions } = useAnimations(animations, model)

    useEffect(() => {
        actions['stand']?.play()
    }, [actions])

    const onPointerEnter = () => {
        actions['stand']?.stop()
        actions['run']?.play()
    }
    const onPointerLeave = () => {
        if (selected) { return }
        actions['run']?.stop()
        actions['stand']?.play()
    }

    useEffect(() => {
        if (selected) {
            onPointerEnter()
            return
        }
        onPointerLeave()
    }, [selected])
    
    return (
        <group name="character" position={position}>
            <primitive
                object={model as any}
            />
            <ObjectElements
                fighter={fighter}
                heatBox
                heatBoxEvents={{
                    onPointerMove: onPointerEnter,
                    onPointerLeave: onPointerLeave,
                    onPointerDown: onClick,
                }}
                name
            />
        </group>
    )
}