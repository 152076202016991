// import { useGLTF } from "@react-three/drei";
import { useGLTF } from "./hooks/useGLTF";
import { useGLTFLoaderStore } from "views/Game/GamePlay/Models/GLTFLoaderStore";
import { models } from "./models";
import { ModelLoadItem } from "interfaces/gltfloader.interface";

interface Props {
    children: any
    loadData?: ModelLoadItem[]
}
const GLTFLoader = ({ children, loadData }: Props) => {
    // TODO: Replace <createRef> on custom one with initial value?
    const modelsRef = useGLTFLoaderStore(state => state.models)
    // @ts-expect-error
    modelsRef.current = useGLTF(loadData || models)
    return <>{ children }</>
}

export default GLTFLoader