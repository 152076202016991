import { createContext, useContext, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router"
import { GlobalStyles } from "style"
import { useCookies } from "react-cookie";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from "core/Loader";
import { useSound } from "core/SoundManager/useSound";
import { SoundBackground } from "core/SoundManager/SoundBackground";

const LayoutContext = createContext({ sessionId: '', setSessionId: undefined })
export const useLayout = () => useContext(LayoutContext)
export const Layout = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [sessionId, setSessionId] = useState()
    const [cookies] = useCookies(['sessionId']);
    useEffect(() => {
        if (!cookies?.['sessionId'] && location.pathname !== '/auth') {
            navigate('auth') 
            return
        } 
        // if (cookies?.['authToken'] && location.pathname !== '/') {
        //     navigate('/')
        // }
    }, [sessionId, location])
    useEffect(() => {
        // console.log(cookies)
        if (cookies?.['sessionId'] !== sessionId) {
            setSessionId(cookies?.['sessionId'] || '')
        }
    }, [cookies])

    const initializeSoundManager = useSound(state => state.initializeSoundManager)
    useEffect(() => {
        initializeSoundManager()
    }, [initializeSoundManager])

    return (
        <LayoutContext.Provider value={{ sessionId, setSessionId }}>
            <ToastContainer theme="dark"/>
            <Loader/>
            <GlobalStyles/>
            <Outlet/>
            <SoundBackground/>
        </LayoutContext.Provider>
    )
}