import { useThree } from "@react-three/fiber"
import React, { useEffect, useState } from "react"
import * as THREE from 'three'
import { UIHoverControl } from "./UIHoverControl"


interface Props { children: any, visible?: boolean, alignX?: 'right' | 'left' | 'center', alignY?: 'top' | 'bottom' | 'center', offsetX?: number, offsetY?: number, hoverId?: string }
export const Aling = React.forwardRef(({ children, visible = true, alignX = 'center', alignY = 'center', offsetX = 0, offsetY = 0, hoverId }: Props, outerRef) => {
    const [prerendered, prerender] = useState(false)
    useEffect(() => { setTimeout(() => prerender(true), 500) }, [0])
    const width = useThree(state => state.size.width)
    const height = useThree(state => state.size.height)
    const ref = React.useRef<THREE.Group>()
    const x = React.useMemo(() => {
        if (alignX === 'center') {
            return 0
        }
        if (alignX === 'right') {
            return width / 2 - offsetX
        }
        return -width / 2 + offsetX
    }, [width, visible, alignX, offsetX])
    const y = React.useMemo(() => {
        if (alignY === 'center') {
            return 0
        }
        if (alignY === 'top') {
            return height / 2 - offsetY
        }
        return -height / 2 + offsetY
    }, [height, visible, alignY, offsetY])
    React.useImperativeHandle(outerRef, () => ref.current)

    return (
        <group 
            visible={visible} 
            ref={ref} 
            name="aling" 
            position-x={x} 
            position-y={y}
        >
            <UIHoverControl id={hoverId} visible={visible}>
                { visible || !prerendered ? children : null }
            </UIHoverControl>
        </group>
    )
})
Aling.displayName = 'Aling'