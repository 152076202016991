import { useMemo } from "react"
import styled from "styled-components"
import { useMarketplace } from "../useMarketplace"
import { TextureBtn } from "../../components/TextureBtn"
import { useCloud } from "EventCloud/useCloud"
import { MarketplaceItem, WarehouseItem } from "interfaces/marketplace.interface"

const StyledTableItem = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    gap: 10px;
    /* background: red; */

    &.marketplace {
        .content {
            cursor: pointer;

            &:hover > img {
                filter: brightness(1.2);
            }
        }
    }

    .view {
        position: relative;
        width: 51px;
        height: 51px;

        canvas {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    .content {
        position: relative;
        flex: 1;
        width: 100%;
        height: 51px;
        display: grid;
        grid-template-columns: 170px 160px 2.17fr 210px;
        padding: 0px 15px;
        font-size: 13px;
        align-items: center;
        font-family: "Acari Sans";
        font-style: normal;
        font-weight: 300;
        line-height: 1;
        color: #D4D4D4;
        text-shadow: 0px 0px 20px #949494;

        > img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transform: scale(1.27);
            object-fit: contain;
            z-index: -1;
        }

        .name {
            display: flex;
            flex-direction: column;

            p {
                font-size: 10px;
                font-weight: 200;
                margin-bottom: 4px;
            }
            h3 {
                font-weight: 400;
            }
        }

        .date {
            text-align: center;
            opacity: .75;
            font-size: 10px;
        }

        .merchant {
            margin-left: 24px;
            display: flex;
            gap: 10px;

            p {
                align-self: center;
                width: 40px;
            }

            .btn {
                width: 24px;
                height: 24px;

                img {
                    transform: scale(1.75);
                }
            }
        }

        .price {
            margin-left: auto;
            margin-right: 12px;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow-x: auto;

            > div {
                margin-left: auto;
            }
        }
    }
`
const StyledPrice = styled.div`
    display: flex;
    gap: 8px;

    .row {
        display: flex;
        align-items: center;
        gap: 3px;
    }

    p {
        text-align: right;
        font-family: "Acari Sans";
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        filter: blur(0.12053072452545166px);

        &.price-gold {
            text-shadow: 0px 0px 16.071px rgba(255, 255, 255, 0.50);
            background: linear-gradient(252deg, #C2AD62 40.02%, #C8AA87 82.23%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &.price-haya {
            text-shadow: 0px 0px 16.071px rgba(181, 54, 201, 0.50);
            background: linear-gradient(252deg, #A762C2 40.02%, #B087C8 82.23%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &.price-nefesh {
            text-shadow: 0px 0px 16.071px rgba(255, 255, 0, 0.50);
            background: linear-gradient(252deg, #C2C200 40.02%, #C8AA87 82.23%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &.price-neshamah {
            text-shadow: 0px 0px 16.071px rgba(0, 255, 0, 0.50);
            background: linear-gradient(252deg, #00C200 40.02%, #C8AA87 82.23%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &.price-ruach {
            text-shadow: 0px 0px 16.071px rgba(0, 0, 255, 0.50);
            background: linear-gradient(252deg, #0000C2 40.02%, #C8AA87 82.23%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    img {
        width: 14px;
        height: 14px;
    }
`
const colors = {
    'rare': '#FF0000',
    'epic': '#9400D3',
    'legendary': '#FF7F00',
    'mythic': '#FFFF00',
    'unique': '#00FF00',
}
export const getRandomColor = () => {
    const colorKeys = Object.keys(colors) as (keyof typeof colors)[];
    const randomIndex = Math.floor(Math.random() * colorKeys.length);
    return colors[colorKeys[randomIndex]];
}
export const TableItem = ({ item }: { item: MarketplaceItem | WarehouseItem }) => {
    const [marketplaceItems, tab] = useMarketplace((state) => [state.items, state.tab])
    const [setItemToSell, setItemToBuy] = useMarketplace(state => [state.setItemToSell, state.setItemToBuy])
    const status = useMemo(() => {
        if (!(item as WarehouseItem).inTrade) return 'Inactive'
        return 'Active'
    }, [item])  
    const moveFromWarehouse = useCloud(state => state.moveItemFromWarehouseToVault)
    const unlistItemFromMarketplace = useCloud(state => state.unlistItemFromMarketplace)
    return (
        <StyledTableItem className={tab === 'marketplace' ? 'marketplace' : ''}>
            <div className="view">
                <img src="/assets/marketplace/market-item-color.png" alt="" width={51} height={51} />
                <img src={`/assets/shared/items/item-wings.png`} alt="" width={51} height={51} />
            </div>
            <div 
                className="content"
                onClick={() => {
                    if (tab === 'marketplace') {
                        setItemToBuy(item as MarketplaceItem)
                    }
                }}
            >
                <img src="/assets/marketplace/market-row-color.png" alt="" width={790} height={51} />
                <div className="name">
                    {
                        tab === 'marketplace' ? (
                            <>
                                <p>{(item as MarketplaceItem).item.itemParameters.type}</p>
                                <h3 style={{ color: getRandomColor() }}>{(item as MarketplaceItem).item.name} +{Number((item as MarketplaceItem).item.itemLevel)}</h3>
                            </>
                        ) : (
                            <>
                                <p>{(item as WarehouseItem).itemAttributes.itemParameters.type}</p>
                                <h3 style={{ color: getRandomColor() }}>{(item as WarehouseItem).itemAttributes.name}  {Number((item as WarehouseItem).itemAttributes.itemLevel) > 0 ? '+' + Number((item as WarehouseItem).itemAttributes.itemLevel) : ''}</h3>
                            </>
                        )
                    }
                </div>
                <div className="date">
                    {tab === 'marketplace' ? (
                        <p>{new Date((item as MarketplaceItem).created_at || 0).toLocaleDateString()}, {new Date((item as MarketplaceItem).created_at || 0).toLocaleTimeString()}</p>
                    ) : (
                        <p>-</p>
                    )}
                </div>
                <div className="merchant">
                    {
                        tab === 'marketplace' ?
                            (
                                <p>{(item as MarketplaceItem).owner_name || 'Unknown'}</p>
                            ) : (
                                <>
                                    {status === 'Inactive' ? (<>
                                        <p>{status}</p>
                                        <TextureBtn
                                            onClick={() => {
                                                setItemToSell(item as WarehouseItem)
                                            }}
                                            className="btn"
                                            texture="/assets/marketplace/sale-btn-color.png"
                                            width={24}
                                            height={24}
                                        />
                                        <TextureBtn
                                            onClick={() => moveFromWarehouse((item as WarehouseItem).itemHash)}
                                            className="btn"
                                            texture="/assets/marketplace/to-vault-btn-color.png"
                                            width={24}
                                            height={24}
                                        />
                                    </>) : (<>
                                        <p>{status}</p>
                                        <TextureBtn
                                            onClick={() => unlistItemFromMarketplace((item as WarehouseItem).itemHash)}
                                            className="btn"
                                            texture="/assets/marketplace/unlist-btn-color.png"
                                            width={24}
                                            height={24}
                                        />
                                    </>)}
                                </>

                            )
                    }
                </div>
                <div className="price">
                    <Price item={item} />
                </div>
            </div>

        </StyledTableItem>
    )
}

const Price = ({ item }: { item: MarketplaceItem | WarehouseItem }) => {
    const itemWithPrice = item
    return (
        <StyledPrice>
            {(itemWithPrice as MarketplaceItem)?.price_gold ? (
                <div className="row">
                    <p className="price-gold">{(itemWithPrice as MarketplaceItem)?.price_gold || 0}</p>
                    <img src="/assets/shared/gold.png" alt="" width={24} height={24} />
                </div>
            ) : '-'}

            {(itemWithPrice as MarketplaceItem)?.price_haya ? (
                <div className="row">
                    <p className="price-haya">{(itemWithPrice as MarketplaceItem)?.price_haya}x</p>
                    <img src="/assets/shared/sefirah_of_haya.png" alt="" width={24} height={24} />
                </div>
            ) : null}

            {(itemWithPrice as MarketplaceItem)?.price_nefesh ? (
                <div className="row">
                    <p className="price-nefesh">{(itemWithPrice as MarketplaceItem)?.price_nefesh}x</p>
                    <img src="/assets/shared/sefirah_of_nefesh.png" alt="" width={24} height={24} />
                </div>
            ) : null}

            {(itemWithPrice as MarketplaceItem)?.price_neshamah ? (
                <div className="row">
                    <p className="price-neshamah">{(itemWithPrice as MarketplaceItem)?.price_neshamah}x</p>
                    <img src="/assets/shared/sefirah_of_neshamah.png" alt="" width={24} height={24} />
                </div>
            ) : null}

            {(itemWithPrice as MarketplaceItem)?.price_ruach ? (
                <div className="row">
                    <p className="price-ruach">{(itemWithPrice as MarketplaceItem)?.price_ruach}x</p>
                    <img src="/assets/shared/sefirah_of_ruach.png" alt="" width={24} height={24} />
                </div>
            ) : null}
        </StyledPrice>
    )
}