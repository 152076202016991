import * as THREE from 'three'
import React from "react"
import { degToRad } from "three/src/math/MathUtils"
import { useFrame } from "@react-three/fiber"

export interface Binders {
    "id": string,
    "rotation": { "x": number, "y": number, "z": number },
    "position": { "x": number, "y": number, "z": number },
    "scale": { "x": number, "y": number, "z": number },
    "parentNodeName": string,
}

export const useEquimentPoses = (fighterModel: THREE.Group | THREE.Mesh) => {
    const store = React.useRef<{[key: string]: { binders: Binders[], mesh: THREE.Mesh, poseIndex: number, animations: THREE.AnimationClip[] }}>({})
    const mixer = React.useMemo(() => new THREE.AnimationMixer(fighterModel), [])

    useFrame(({}, delta) => {
        mixer.update( delta )
    })

    const addPose = React.useCallback((name: string, binders: Binders[], mesh: THREE.Mesh, animations: THREE.AnimationClip[]) => {
        store.current[name] = { binders, mesh: mesh as THREE.Mesh, animations, poseIndex: 0 }
    }, [])

    const removePose = React.useCallback((name: string) => {
        const pose = store.current[name]
        if (!pose) { return }
        // For Test
        // const binder = pose.binders[pose.poseIndex]
        // 
        removeBinder(fighterModel, pose.mesh)
        delete store.current[name]
    }, [fighterModel])

    const updatePose = (name: string) => {
        const pose = store.current[name]
        if (!pose) { return }
        // For Test
        const binder = pose.binders[pose.poseIndex]
        // 
        applyBinder(fighterModel, pose.mesh, binder)

        // TODO: think about this, now made only for wings
        // TODO: Bug when second time take on animation doesnt play
        const animation = pose.animations.find(_ => _.name === "Armature|mixamo.com|Layer0.001" || _.name === 'fly')
        // console.log(animation, mixer, 'animation')
        if (animation) { 
            // console.log(animation)
            mixer.clipAction(animation, fighterModel).setEffectiveTimeScale(1.5).play()
            // clips.current.push({ itemHash: item.itemHash, animation, object: model })
        }
        // 

    }

    // TODO: Fix this, for now made just for sword
    const updatePoses = React.useCallback((isMove: boolean) => {
        if (isMove) {
            Object.keys(store.current).forEach(key => {
                // TODO: Just for test, for walk
                if (store.current[key].binders.length > 1) {
                    store.current[key].poseIndex = 1
                    updatePose(key)
                }
            })
        } else {
            Object.keys(store.current).forEach(key => {
                store.current[key].poseIndex = 0
                updatePose(key)
            })
        }
    }, [])

    return { addPose, updatePose, updatePoses, removePose }
}

export function applyBinder(fighterNode: THREE.Group | THREE.Mesh, targetNode: THREE.Group | THREE.Mesh, binder: any) {
    const fighterModelNode = fighterNode.getObjectByName(binder.parentNodeName.replace(':', ''))
    if (!fighterModelNode) { return console.warn('[applyBinder]: fighterModelNode not found, check binders') }
    targetNode.userData.binderParsed = binder
    targetNode.position.set(binder.position.x, binder.position.y, binder.position.z)
    targetNode.rotation.set(degToRad(binder.rotation.x), degToRad(binder.rotation.y), degToRad(binder.rotation.z))
    targetNode.scale.set(binder.scale.x, binder.scale.y, binder.scale.z)
    fighterModelNode.add( targetNode )
}

export function removeBinder(fighterNode: THREE.Group | THREE.Mesh, targetNode: THREE.Group | THREE.Mesh) {
    const fighterModelNode = fighterNode.getObjectByName(targetNode.userData.binderParsed.parentNodeName.replace(':', ''))
    if (!fighterModelNode) { return console.warn('[useEquipmentPoses]: fighterModelNode not found, check binders') }
    // console.log('fighterModelNode', fighterModelNode, targetNode)
    fighterModelNode.remove( targetNode )
}