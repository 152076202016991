import { useCloud } from "EventCloud/useCloud"
import { Character } from "./Character"
import React from "react"
import Web3 from "web3"
import { View } from "core/Tunnel/View"
import { UI, UIMenu } from "./UI"

import { Fighter } from "interfaces/fighter.interface"
import { Camera } from "./Camera"
import { Environment } from "./Environment"
import { Lights } from "./Lights"
import { createWithEqualityFn } from "zustand/traditional"
import { shallow } from "zustand/shallow"
import GLTFLoader from "../GamePlay/Models/GLTFLoader"
import { fighters } from "../GamePlay/Models/models"
import { shared } from "../GamePlay/Models/models/shared"

interface UseMenu {
    selected: Fighter | null
    select: (selected: Fighter) => void
}
export const useMenu = createWithEqualityFn<UseMenu>((set, get) => ({
    selected: null,
    select: selected => set({ selected })
}), shallow)

export const Menu = () => {
    const [userFighters] = useCloud(state => [state.userFighters])
    const [selected, select] = useMenu(state => [state.selected, state.select])

    return (
        <>
            <View>
                {/* <fog attach="fog" args={['#010021', 10, 22]}></fog> */}
                <Camera/>
                <Lights/>

                <React.Suspense fallback={null}>
                    <Environment/>
                    <GLTFLoader loadData={[...fighters, ...shared]}>
                        {/* Characters */}
                        {userFighters?.map((fighter, idx) => (
                            <Character
                                key={idx}
                                fighter={fighter}
                                position={[-2.5 + idx * 1.2, 0, 2]}
                                onClick={() => select(fighter)}
                                selected={fighter.name === selected?.name}
                            />
                        ))}
                    </GLTFLoader>
                </React.Suspense>

                <UIMenu.Out/>
            </View>
            <UI/>
        </>
    )
}