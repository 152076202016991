// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommandLine_CommandLine__Wl7IY {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translate(-50%);
  width: 60%;
  min-width: 300px;
  height: 36px;
  background-color: black;
  border: 1px solid #ff7b00;
  pointer-events: all;
}
.CommandLine_CommandLine__Wl7IY input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  padding: 0 12px;
  font-size: 18px;
  margin: 0;
  color: #ff7b00;
}

.CommandLine_CommandsPlaceholder__Bdzk2 {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  bottom: calc(100% + 120px);
  color: white;
  opacity: 0.5;
  font-size: 12px;
}
.CommandLine_CommandsPlaceholder__Bdzk2 pre {
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/views/Game/GamePlay/UserInterface2D/CommandLine/CommandLine.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,SAAA;EACA,0BAAA;EACA,UAAA;EACA,gBAAA;EACA,YAAA;EACA,uBAAA;EACA,yBAAA;EACA,mBAAA;AACJ;AACI;EACI,kBAAA;EACA,MAAA;EAAQ,OAAA;EACR,YAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,SAAA;EACA,cAAA;AAER;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,OAAA;EACA,0BAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AACJ;AACI;EACI,SAAA;EACA,UAAA;AACR","sourcesContent":[".CommandLine {\n    position: absolute;\n    bottom: 32px;\n    left: 50%;\n    transform: translate(-50%);\n    width: 60%;\n    min-width: 300px;\n    height: 36px;\n    background-color: black;\n    border: 1px solid #ff7b00;\n    pointer-events: all;\n\n    input {\n        position: absolute;\n        top: 0; left: 0;\n        height: 100%;\n        background: transparent;\n        border: none;\n        outline: none;\n        width: 100%;\n        padding: 0 12px;\n        font-size: 18px;\n        margin: 0;\n        color: #ff7b00;\n    }\n}\n\n.CommandsPlaceholder {\n    position: absolute;\n    width: 100%;\n    height: auto;\n    left: 0;\n    bottom: calc(100% + 120px);\n    color: white;\n    opacity: .5;\n    font-size: 12px;\n\n    pre {\n        margin: 0;\n        padding: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommandLine": `CommandLine_CommandLine__Wl7IY`,
	"CommandsPlaceholder": `CommandLine_CommandsPlaceholder__Bdzk2`
};
export default ___CSS_LOADER_EXPORT___;
