interface Props {
    id?: 'email' | 'password' | 'none'
}
export const Svg = ({ id = 'email' }: Props) => {
    if (id === 'email') {
        return (
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.21875 8.25C5.80391 8.25 5.46875 8.58516 5.46875 9V9.93516L10.0531 13.2961C10.4492 13.5867 10.9883 13.5867 11.3844 13.2961L15.9688 9.93516V9C15.9688 8.58516 15.6336 8.25 15.2188 8.25H6.21875ZM5.46875 10.8656V15C5.46875 15.4148 5.80391 15.75 6.21875 15.75H15.2188C15.6336 15.75 15.9688 15.4148 15.9688 15V10.8656L11.8273 13.9008C11.1664 14.3836 10.2687 14.3836 9.61016 13.9008L5.46875 10.8656ZM4.71875 9C4.71875 8.17266 5.39141 7.5 6.21875 7.5H15.2188C16.0461 7.5 16.7188 8.17266 16.7188 9V15C16.7188 15.8273 16.0461 16.5 15.2188 16.5H6.21875C5.39141 16.5 4.71875 15.8273 4.71875 15V9Z" fill="white" />
            </svg>
        )
    }

    if (id === 'password') {
        return (
            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 8V9.5H12.5V8C12.5 6.75781 11.4922 5.75 10.25 5.75C9.00781 5.75 8 6.75781 8 8ZM7.25 9.5V8C7.25 6.34297 8.59297 5 10.25 5C11.907 5 13.25 6.34297 13.25 8V9.5H13.625C14.6609 9.5 15.5 10.3391 15.5 11.375V15.125C15.5 16.1609 14.6609 17 13.625 17H6.875C5.83906 17 5 16.1609 5 15.125V11.375C5 10.3391 5.83906 9.5 6.875 9.5H7.25ZM5.75 11.375V15.125C5.75 15.7461 6.25391 16.25 6.875 16.25H13.625C14.2461 16.25 14.75 15.7461 14.75 15.125V11.375C14.75 10.7539 14.2461 10.25 13.625 10.25H6.875C6.25391 10.25 5.75 10.7539 5.75 11.375Z" fill="white" />
            </svg>
        )
    }

    return null
}