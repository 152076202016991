import React, { MutableRefObject, RefObject, useEffect, useLayoutEffect, useMemo } from "react"
import type { InventorySlot } from "interfaces/inventory.interface"
import { ThreeEvent, useFrame } from "@react-three/fiber"
import { useUi } from "../useUi"
import { getCoordInUISpace } from "views/Game/GamePlay/utils/getCoordInUiSpace"
import { Flex, Box } from "@react-three/flex"
import { Plane, Text } from "@react-three/drei"
import BackpackItem, { BackpackItemMaps } from "./components/BackpackItem"
import EquipmentItem from "./components/EquipmentItem"
import { getSlotModel } from "./utils/getSlotModel"
import { useSlots } from "./useSlots"
import { useControls } from "views/Game/GamePlay/Controls/useControls"
import { useCore } from "../../useCore"
import { Coordinate, ServerCoordinate } from "interfaces/coordinate.interface"
import { Texture } from "three"
import * as THREE from 'three'

export type CellType = 'equipment' | 'backpack'
export type eventType = 'update' | 'transferTo' | 'drop' | 'doubleClick' | 'rightClick' | 'transferToWithNotAllowed' | 'dropOnOtherObject'
export interface handlerProps {
    item: InventorySlot
    coordinate?: ServerCoordinate
    position?: Coordinate
    slot?: number
    dropOnItem?: InventorySlot
}
export interface EventsType {
    id: string, type: eventType, handler: (props: handlerProps) => any
}
function init(state: MutableRefObject<any>, key?: string, value?: any) {
    if (!state.current) {
        state.current = {}
    }
    if (key) {
        state.current[key] = value
    }
}

const _colors = {
    COMMON_DARK: '#131313',
    COMMON_LIGHT: '#202020',

    INSERT_ALLOWED_DARK: '#183419',
    INSERT_ALLOWED_LIGHT: '#1F3D20',

    INSERT_DISALLOWED_DARK: '#351B1B',
    INSERT_DISALLOWED_LIGHT: '#442323',

    LAST_PLACEHOLDER_DARK: '#342F00', 
    LAST_PLACEHOLDER_LIGHT: '#393400', 
}


export interface HightlightColors {
    COMMON_DARK: string,
    COMMON_LIGHT: string,

    INSERT_ALLOWED_DARK: string,
    INSERT_ALLOWED_LIGHT: string,

    INSERT_DISALLOWED_DARK: string,
    INSERT_DISALLOWED_LIGHT: string,

    LAST_PLACEHOLDER_DARK: string, 
    LAST_PLACEHOLDER_LIGHT: string, 
}

export interface HightlightMaps {
    COMMON: Texture,
    INSERT_ALLOWED: Texture,
    INSERT_DISALLOWED: Texture,
    LAST_PLACEHOLDER: Texture, 
}

interface Props {
    id: string,
    type: CellType
    enableRender: boolean
    cellWidth: number
    cellHeight: number

    width?: number
    height?: number
    position?: number[]
    maxWidth?: number

    gold?: number
    grid?: boolean[][] | Record<number, boolean>
    items: Map<string, InventorySlot> | Record<number, InventorySlot>

    events: EventsType[]

    onPointerEnter?: (e: ThreeEvent<PointerEvent>) => void
    onPointerMove?: (e: ThreeEvent<PointerEvent>) => void
    onPointerLeave?: (e: ThreeEvent<PointerEvent>) => void

    relatedNodes?: RefObject<THREE.Group>[]

    children?: any

    // styling
    maps?: HightlightMaps
    // Disabled if slot maps provided
    colors?: HightlightColors
    itemMaps?: BackpackItemMaps
    itemBoxPlacement?: 'top' | 'bottom'
}
export const Slots = ({ 
    id,
    type,
    enableRender,
    cellWidth = 24,
    cellHeight = 24,

    gold,
    grid: _grid,
    items: _items,

    width,
    height,
    position = [0, 0, 0],
    maxWidth = 1920,

    // updateItemPosition,
    // dropItem,
    // equipItem,
    // unequipItem,
    events: _events,

    onPointerEnter,
    onPointerLeave,
    onPointerMove,

    relatedNodes,

    children,

    maps,
    colors = _colors,
    itemMaps,
    itemBoxPlacement = 'bottom',
}: Props) => {
    const pinnedItemEvent = useSlots(state => state.pinnedItemEvent)
    const isItemPinned = useSlots(state => state.isItemPinned)
    const isItemHovered = useSlots(state => state.isItemHovered)
    const hoveredItemEvent = useSlots(state => state.hoveredItemEvent)
    const hoveredItemModel = useSlots(state => state.hoveredItemModel)
    const pinnedSlotsId = useSlots(state => state.pinnedSlotsId)

    // Cell collisions
    const pointerCell = useSlots(state => state.pointerCell)
    const placeholderCells = useSlots(state => state.placeholderCells)
    const currentPointerCells = useSlots(state => state.currentPointerCells)
    const lastPointerCells = useSlots(state => state.lastPointerCells)
    const cellToInsert = useSlots(state => state.cellToInsert)
    useLayoutEffect(() => {
        init(pointerCell)
        init(placeholderCells, id, [])
        init(currentPointerCells, id, [])
        init(lastPointerCells, id, [])
        init(cellToInsert)
    }, [id])

    const events = useMemo(() => {
        const transferTo: EventsType[] = []
        const update: EventsType[] = []
        const drop: EventsType[] = []
        const doubleClick: EventsType[] = []
        const rightClick: EventsType[] = []
        const transferToWithNotAllowed: EventsType[] = []
        const dropOnOtherObject: EventsType[] = []

        _events.map(_ => {
            if (_.type === 'transferTo') {
                transferTo.push(_)
            }
            if (_.type === 'update') {
                update.push(_)
            }
            if (_.type === 'drop') {
                drop.push(_)
            }
            if (_.type === 'doubleClick') {
                doubleClick.push(_)
            }
            if (_.type === 'rightClick') {
                rightClick.push(_)
            }
            if (_.type == 'transferToWithNotAllowed') {
                transferToWithNotAllowed.push(_)
            }
            if (_.type === 'dropOnOtherObject') {
                dropOnOtherObject.push(_)
            }
        })
        return { transferTo, doubleClick, update, drop, rightClick, transferToWithNotAllowed, dropOnOtherObject }
    }, [_events])

    // Transform items to Array for rendering
    const items = React.useMemo(() => {
        if (!_items) { return }
        return Object.keys(_items).map(slot => ({ ..._items[slot], slot }))
    }, [_items, type])
    const grid = React.useMemo(() => {
        if (type === 'equipment') {
            return Object.keys(_grid)
        }
        return _grid
    }, [_grid, type])

    const slotsRef =  React.useRef<{[key: number]: THREE.Mesh}>({})
    const setRef = React.useCallback((ref: any, x: number, y?: number) => {
        if (type === 'backpack') {
            return slotsRef.current[x+','+y] = ref
        }
        return slotsRef.current[x] = ref
    }, [slotsRef])

    // Events
    const onItemPointerMove = (e: ThreeEvent<PointerEvent>) => {
        if (!enableRender) { return }
        if (!e.object) { return } // in case if removed
        if (isItemHovered.current) { return }
        if (isItemPinned.current || pinnedItemEvent.current) { return }
        isItemHovered.current = true
        hoveredItemModel.current = getSlotModel(e)
        useUi.getState().setCursor('pointer')

        hoveredItemEvent.current = e

        const effectObject = hoveredItemEvent.current.object?.parent as any
        if (effectObject && effectObject.userData.maps) {
            effectObject.material.map = effectObject.userData.maps.HOVER
        }

        hoveredItemModel.current.userData.positionZ = hoveredItemModel.current.position.z
        hoveredItemModel.current.position.z = 300
        // Toggle Item Description
        const itemDescription = hoveredItemEvent.current.object.parent.children.find(_ => _.name === 'item-description')
        itemDescription && ( itemDescription.visible = true )
    }
    const onItemPointerLeave = (e: ThreeEvent<PointerEvent>) => {
        if (!enableRender) { return }
        if (!e.object) { return } // in case if removed
        if (isItemPinned.current || pinnedItemEvent.current) { return }
        isItemHovered.current = false
        hoveredItemModel.current?.userData?.positionZ && (hoveredItemModel.current.position.z = hoveredItemModel.current.userData.positionZ)
        hoveredItemModel.current && (hoveredItemModel.current.rotation.y = 0)
        hoveredItemModel.current = null
        useUi.getState().setCursor('default')

        if (hoveredItemEvent.current) {
            // TODO: figure out why we have such situation when no parent 
            if (!hoveredItemEvent.current.object?.parent) { return }
            const effectObject = hoveredItemEvent.current.object?.parent as any
            if (effectObject && effectObject.userData.maps) {
                effectObject.material.map = effectObject.userData.maps.COMMON
            }
            // Toggle Item Description
            const itemDescription = hoveredItemEvent.current.object.parent.children.find(_ => _.name === 'item-description')
            itemDescription && ( itemDescription.visible = false )
            hoveredItemEvent.current = null
        }
    }
    const onClick = (e: ThreeEvent<PointerEvent>) => {
        // console.log('detail', e.detail)
        if (!enableRender) { return }
        if (!e.object) { return } // in case if removed
        // console.log(pinnedItemEvent.current?.object, e.object)
        // If we Pinning already, we have to prevent click on another Item, otherwise -- error
        if (isItemPinned.current && e.object !== pinnedItemEvent.current.object) { 
            events.dropOnOtherObject.forEach(event => event.handler({ 
                item: pinnedItemEvent.current.object.parent.userData.item,
                dropOnItem: e.object.parent.userData.item
            }))
            return 
        }
        // Dont let Move if no Move & TransferTo events
        if (!events.update.length && !events.transferTo.length && type !== 'equipment') { return }
        // 
        onItemPointerMove(e)
        setTimeout(() => {
            if (!hoveredItemEvent.current) { return } // Try fix issue, test required
            isItemPinned.current = !isItemPinned.current
            if (isItemPinned.current) {
                pinnedItemEvent.current = e
                // @ts-expect-error
                hoveredItemEvent.current?.object?.parent?.material?.opacity && (hoveredItemEvent.current.object.parent.material.visible = false) // TODO: sometimes get error over here
                // Display previous cell
                setPlaceholderCells(pinnedItemEvent.current, true)
                // setObjectRenderLayer(pinnedItemEvent.current, 'highest')
                pinnedSlotsId.current = id
            } else {
                // TODO: fix setPlaceholderCells
                setPlaceholderCells(pinnedItemEvent.current, false)
                // @ts-expect-error
                hoveredItemEvent.current?.object?.parent?.material?.opacity && (hoveredItemEvent.current.object.parent.material.visible = true)
                placeItemToCell(pinnedItemEvent.current)
                // setObjectRenderLayer(pinnedItemEvent.current, 'default')
                pinnedItemEvent.current = null
                // TODO: fix clearing
                clearPointerCells()
                onItemPointerLeave(e)
                if (pinnedSlotsId.current === id) {
                    pinnedSlotsId.current = ''
                }
            }
        }, 0)
    }
    const onDoubleClick = (e: ThreeEvent<PointerEvent>) => {
        onItemPointerLeave(e)
        const item = e.object.parent.userData.item
        events.doubleClick.forEach(event => event.handler({ item }))
        // if (events.doubleClick.length) {
            
        // }
    }
    const onRightClick = (e: ThreeEvent<PointerEvent>) => {
        onItemPointerLeave(e)
        const item = e.object.parent.userData.item
        events.rightClick.forEach(event => event.handler({ item }))
    }
    // 

    // Events Methods
    // TODO: Fix Validation for Equipment & Backpack. Make it better
    const highlightPointerCell = React.useCallback((projectedPointer: {x:number;y:number}) => {        
        lastPointerCells.current[id] = currentPointerCells.current[id]
        currentPointerCells.current[id] = getPointerCells(projectedPointer)
        const isHoveredEquipmentSlot = currentPointerCells.current[id].length 
            ? currentPointerCells.current[id][0].userData.type === 'equipment'
            : false

        lastPointerCells.current[id].forEach(cell => {
            // If placeholder cell we dont touch it
            if (placeholderCells.current[id].find(_ => _ === cell)) { return }
            if (cell.userData.maps) {
                // @ts-expect-error
                cell.material.map = cell.userData.maps.common
            } else {
                // @ts-expect-error
                cell.material.color.set(cell.userData.colors.common)
            }
        })

        const itemWidth = pinnedItemEvent.current.object.parent.userData.item.itemAttributes.itemParameters.itemWidth
        const itemHeight = pinnedItemEvent.current.object.parent.userData.item.itemAttributes.itemParameters.itemHeight
        // Check if we can insert
        let availableCells = 0
        currentPointerCells.current[id].forEach(cell => {
            if (currentPointerCells.current[id].length < itemWidth * itemHeight && !isHoveredEquipmentSlot) {
                return
            }
            availableCells += isOccupied(cell, isHoveredEquipmentSlot) ? 0 : 1
        })

        // Logic for Equipment Slot
        if (isHoveredEquipmentSlot) {
            // TODO: detect if slote enabled
            const pinnedObjectData = pinnedItemEvent.current.object.parent.userData
            const enabledEvents = pinnedObjectData.enabledEvents
            const isEnabledByEvent = !!enabledEvents?.transferTo.find((e: EventsType) => id.includes(e.id))
            const isAvailableCell = !items.find(_ => Number(_.slot) === Number(currentPointerCells.current[id][0].userData.slot))
            const isEnabledByType = +currentPointerCells.current[id][0].userData.slot === +pinnedItemEvent.current.object.parent.userData.item.itemAttributes.itemParameters.acceptableSlot1 || +currentPointerCells.current[id][0].userData.slot === +pinnedItemEvent.current.object.parent.userData.item.itemAttributes.itemParameters.acceptableSlot2
            if (isAvailableCell && isEnabledByType && isEnabledByEvent) {
                cellToInsert.current[id] = { ref: currentPointerCells.current[id][0], type: 'equipment' }
                // If placeholder cell we dont touch it
                if (placeholderCells.current[id].find(_ => _ === currentPointerCells.current[id][0])) { return }
                const cell = currentPointerCells.current[id][0]
                if (cell.userData.maps) {
                    // @ts-expect-error
                    cell.material.map = cell.userData.maps.insert_allowed
                } else {
                    // @ts-expect-error
                    cell.material.color.set(cell.userData.colors.insert_allowed)
                }
            } else {
                cellToInsert.current[id] = null
                // If placeholder cell we dont touch it
                if (placeholderCells.current[id].find(_ => _ === currentPointerCells.current[id][0])) { return }
                const cell = currentPointerCells.current[id][0]
                if (cell.userData.maps) {
                    // @ts-expect-error
                    cell.material.map = cell.userData.maps.insert_disallowed
                } else {
                    // @ts-expect-error
                    cell.material.color.set(cell.userData.colors.insert_disallowed)
                }
            }
            return
        }

        // Logic for Backpack Slot
        // Then set insert availability based on <availableCells>
        if (availableCells === itemWidth * itemHeight) {
            cellToInsert.current[id] = { ref: pointerCell.current[id], type: 'backpack' }
            currentPointerCells.current[id].forEach(cell => {
                // If placeholder cell we dont touch it
                if (placeholderCells.current[id].find(_ => _ === cell)) { return }
                if (cell.userData.maps) {
                    // @ts-expect-error
                    cell.material.map = cell.userData.maps.insert_allowed
                } else {
                    // @ts-expect-error
                    cell.material.color.set(cell.userData.colors.insert_allowed)
                }
            })
        } else {
            cellToInsert.current[id] = null
            currentPointerCells.current[id].forEach(cell => {
                // If placeholder cell we dont touch it
                if (placeholderCells.current[id].find(_ => _ === cell)) { return }
                if (cell.userData.maps) {
                    // @ts-expect-error
                    cell.material.map = cell.userData.maps.insert_disallowed
                } else {
                    // @ts-expect-error
                    cell.material.color.set(cell.userData.colors.insert_disallowed)
                }
            })
        }

        function getPointerCells(projectedPointer: {x:number;y:number}) {
            const itemWidth = pinnedItemEvent.current.object.parent.userData.item.itemAttributes.itemParameters.itemWidth
            const itemHeight = pinnedItemEvent.current.object.parent.userData.item.itemAttributes.itemParameters.itemHeight
    
            // Find Cell under Pointer (for Backpack Item)
            const _backpackPointerCell = Object.values(slotsRef.current).find(slotCell => {
                const slotRow = slotCell.parent
                const slotColumn = slotRow.parent
                const slotWrapper = slotColumn.parent
                const wrapper = slotWrapper.parent
    
                const x = slotRow.position.x + slotColumn.position.x + slotWrapper.position.x + wrapper.position.x
                const y = slotRow.position.y + slotColumn.position.y + slotWrapper.position.y + wrapper.position.y
    
                // Multiply by itemWidth & itemHeight to always position model in the center of highlighted square, no matter 1x1 or 2x2 or even 1x3
                if (type === 'equipment') {
                    // Multiply by itemWidth & itemHeight to always position model in the center of highlighted square, no matter 1x1 or 2x2 or even 1x3
                    // TODO: Change "100" to cell size
                    return Math.abs(x - projectedPointer.x) < .5 * slotCell.userData.itemWidth * cellWidth && Math.abs(y - projectedPointer.y) < .5 * slotCell.userData.itemHeight * cellHeight
                }
                return Math.abs(x - projectedPointer.x) < .5 * itemWidth * cellWidth && Math.abs(y - projectedPointer.y) < .5 * itemHeight * cellHeight
            })
    
            // Could be only one typ at the same time
            // console.log(isHoveredEquipmentSlot, _equipmentPointerCell, _backpackPointerCell)
            pointerCell.current[id] = _backpackPointerCell || null
            if (!pointerCell.current[id]) return []
        
    
            // Calc all cells belongs to the Pointer, depending on Item size (like 1x1, 2x2) and Hovered cell type
            const cells = []
    
            // Depending on hovered cell type
            if ( type === 'equipment') {
                cells.push(pointerCell.current[id])
            } else {
                const { x, y } = pointerCell.current[id].userData.slot
                for (let i = 0; i < itemWidth; i++) {
                    for (let j = 0; j < itemHeight; j++) {
                        const cell = slotsRef.current[`${x+i},${y+j}`]
                        if (cell) {
                            cells.push(cell)
                        }
                    }
                }
            }
    
            return cells
        }

        function isOccupied(cell: THREE.Mesh, isEquipmentSlot: boolean) {

            // Check if Equipment, then false (and there will be other validation)
            if (isEquipmentSlot) {
                // TODO: temporary
                return false
            }

            const pinnedObjectData = pinnedItemEvent.current.object.parent.userData
            const enabledEvents = pinnedObjectData.enabledEvents
            // Check If this Item could be transfered to this Slots
            if (!enabledEvents?.transferTo.find((e: EventsType) => e.id === id) && pinnedObjectData.slotsId !== id) {
                return true
            }
            // 

            // Check if this Item could be moved here
            if (!enabledEvents?.update.find((e: EventsType) => e.id === id) && pinnedObjectData.slotsId === id) {
                return true
            }
            // 

            const { x, y } = cell.userData.slot
            // Allow paste to the same cell, or a little touching the same cell 
            if (placeholderCells.current[id].find(_ => _.userData.slot.x === x && _.userData.slot.y === y)) return false

            return grid[y][x]
        }
    }, [type, id, grid])

    const placeItemToCell = React.useCallback((pinnedItemEvent: ThreeEvent<PointerEvent>) => {
        const itemObject = pinnedItemEvent.object.parent
        const item = itemObject.userData.item

        // console.log('cellToInsert.current', cellToInsert.current)
        // We look for the first appear, cuz situation with 2 together cannot be
        const backpackInsert = Object.values(cellToInsert.current).find(item => item?.type === 'backpack')
        const equipmentInsert = Object.values(cellToInsert.current).find(item => item?.type === 'equipment')

        if (!backpackInsert && !equipmentInsert) {
            // Drop if nothing hovered
            const isNotHovered = Object.values(pointerCell.current).every(_ => !_)
            if (isNotHovered && events.drop.length) {
                const coordinates = useControls.getState().pointerCoordinate
                // handlePointerLeave()
                events.drop.forEach(event => event.handler({ item, coordinate: useCore.getState().worldCoordToMatrix(coordinates) }))
                return
            }

            // Move to last position
            const lastPosition = itemObject.userData.currentPosition
            // const id = backpackInsert.ref.userData.id
            const id = Object.values(pointerCell.current).filter(_ => !!_)[0].userData.id

            events.transferToWithNotAllowed.filter(e => id.includes(e.id)).forEach(e => e.handler({ item }))
            itemObject.position.copy(lastPosition)
            return
        }
        
        // console.log(backpackInsert, equipmentInsert)

        // If click on Backpack cell
        if (backpackInsert) {
            const slot = backpackInsert.ref.userData.slot
            const id = backpackInsert.ref.userData.id

            // handlePointerLeave()
            // [EVENTS]: Update position
            events.update.filter(e => id.includes(e.id)).forEach(e => e.handler({item, position: { x: slot.x, z: slot.y }}))
            // [EVENTS]: Transfer To
            events.transferTo.filter(e => id.includes(e.id)).forEach(e => e.handler({ item, position: { x: slot.x, z: slot.y } }))
        } 
        // If click on Equipment cell
        else if (equipmentInsert) {
            // handlePointerLeave()
            const slot = equipmentInsert.ref.userData.slot
            const id = equipmentInsert.ref.userData.id
            // [EVENTS]: Transfer To (BUT FOR EQUIPMENT TYPE SLOTS/provide inner data to handler)
            events.transferTo.filter(e => id.includes(e.id)).forEach(e => e.handler({ item, slot }))
        }

    }, [events, id])

    const setPlaceholderCells = React.useCallback((pinnedItemEvent: ThreeEvent<PointerEvent>, show: boolean) => {
        const cellCoordinate = pinnedItemEvent.object.parent.userData.item.slot.split(',').map((_:string)=>Number(_))
        const itemWidth = pinnedItemEvent.object.parent.userData.item.itemAttributes.itemParameters.itemWidth
        const itemHeight = pinnedItemEvent.object.parent.userData.item.itemAttributes.itemParameters.itemHeight
        const cellType: CellType = pinnedItemEvent.object.parent.userData.type
        const cells = []

        // Placeholder for pinned Equipment Item
        if (cellType === 'equipment') {
            const xy = pinnedItemEvent.object.parent.userData.item.slot
            const cell = slotsRef.current[xy]
            if (!cell) { return console.warn('[Backpack: setPlaceholderCells]: Cell not found') }
            cells.push(cell)
        // Placeholder for pinned Backpack Item 
        } else {
            for (let i = 0; i < itemHeight; i++) {
                for (let j = 0; j < itemWidth; j++) {
                    const cell = slotsRef.current[`${cellCoordinate[0]+j},${cellCoordinate[1]+i}`]
                    if (!cell) { return console.warn('[Backpack: setPlaceholderCells]: Cell not found') }
                    cells.push(cell)
                }
            }
        }

        cells.forEach(cell => {
            if (show) {
                if (cell.userData.maps) {
                    cell.material.map = cell.userData.maps.last_placeholder
                } else {
                    cell.material.color.set(cell.userData.colors.last_placeholder)
                }
            } else {
                if (cell.userData.maps) {
                    cell.material.map = cell.userData.maps.common
                } else {
                    cell.material.color.set(cell.userData.colors.common)
                }
            }
        })

        placeholderCells.current[id] = show ? cells : []
    }, [id])

    const clearPointerCells = React.useCallback(() => {
        const cellArrays = Object.values(currentPointerCells.current)
        cellArrays.forEach(cells => {
            cells.forEach(cell => {
                if (cell.userData.maps) {
                    // @ts-expect-error
                    cell.material.map = cell.userData.maps.common
                } else {
                    // @ts-expect-error
                    cell.material.color.set(cell.userData.colors.common)
                }
            })
        })
    }, [])
    // 

    // Render All Highlights & Current Items
    useFrame(({ raycaster }) => {
        if (!enableRender) { return }

        // console.log('g', pointerCell.current)
        // Pin item
        if (pinnedItemEvent.current && isItemPinned.current) {
            const projectedPointer = getCoordInUISpace(raycaster)

            relatedNodes.forEach(node => {
                projectedPointer.sub(node.current.position)
            })

            // projectedPointer Controls which "Slots" will render highlights
            if (!projectedPointer) { return }
            // <substract Backpack position>: Take backpack Items offset into the account
            projectedPointer.z += 100 // Make it Higher than other Inventory Objects
            // Detect collisions
            highlightPointerCell(projectedPointer)

            if (pinnedSlotsId.current === id) {
                pinnedItemEvent.current.object.parent.position.copy(projectedPointer)
            }
        } else {
            cellToInsert.current && (cellToInsert.current[id] = null)
        }
        // Rotate pinned or hovered item
        if (hoveredItemModel.current && hoveredItemEvent.current?.object?.parent?.userData?.slotsId === id) {
            hoveredItemModel.current.rotation.y += 0.05
        }
    })

    return (
        <group 
            onPointerEnter={(e) => { onPointerEnter && onPointerEnter(e); }} 
            onPointerMove={(e) => { onPointerMove && onPointerMove(e); }} 
            onPointerLeave={(e) => { onPointerLeave && onPointerLeave(e); }}
        >

            <group>

                <group 
                    name='backpack-slots-container' 
                    position={position as any}
                >
                    {
                        type === 'backpack' ? 
                        (
                            <Flex name='backpack' flexDir="column">
                                { [...new Array(width)].map((_, i) => (
                                    <Box name='column' key={i} flexDir="row">
                                        { [...new Array(height)].map((_, j) => (
                                            <Box name='row' key={'_'+j}>
                                                <Plane
                                                    name='slot-cell' 
                                                    ref={(r) => setRef(r, j, i)} 
                                                    args={[cellWidth, cellHeight, 1]}
                                                    userData={{
                                                        id,
                                                        type: 'backpack',
                                                        slot: { x: j, y: i }, 
                                                        maps: maps ? {
                                                            common: maps.COMMON,
                                                            insert_allowed: maps.INSERT_ALLOWED,
                                                            insert_disallowed: maps.INSERT_DISALLOWED,
                                                            last_placeholder: maps.LAST_PLACEHOLDER,
                                                        } : null,
                                                        colors: {
                                                            common: (i + j) % 2 === 0 ? colors.COMMON_DARK : colors.COMMON_LIGHT,
                                                            insert_allowed: (i + j) % 2 === 0 ? colors.INSERT_ALLOWED_DARK : colors.INSERT_ALLOWED_LIGHT,
                                                            insert_disallowed: (i + j) % 2 === 0 ? colors.INSERT_DISALLOWED_DARK : colors.INSERT_DISALLOWED_LIGHT,
                                                            last_placeholder: (i + j) % 2 === 0 ? colors.LAST_PLACEHOLDER_DARK : colors.LAST_PLACEHOLDER_LIGHT,
                                                        },
                                                    }}
                                                >
                                                    {
                                                        maps ? (
                                                            <meshBasicMaterial 
                                                                map={maps.COMMON} 
                                                                transparent={true}
                                                            />
                                                        ) : (
                                                            <meshBasicMaterial 
                                                                color={(i + j) % 2 === 0 ? colors.COMMON_DARK : colors.COMMON_LIGHT} 
                                                            />  
                                                        )
                                                    }

                                                </Plane>
                                            </Box>
                                        ))}
                                    </Box>
                                )) }
                            </Flex>
                        ) :
                        (
                            <Flex name='equipment' maxWidth={maxWidth} flexDir="row" flexWrap="wrap">
                                { (grid as string[])/*.sort((a,b) => b.height - a.height)*/.map((key) => (
                                    <Box name='row' key={key} centerAnchor>
                                        <Plane 
                                            name='slot-equipment'
                                            ref={(r) => setRef(r, +key)}
                                            userData={{
                                                id,
                                                type: 'equipment',
                                                slot: +key,
                                                maps: maps ? {
                                                    common: maps.COMMON,
                                                    insert_allowed: maps.INSERT_ALLOWED,
                                                    insert_disallowed: maps.INSERT_DISALLOWED,
                                                    last_placeholder: maps.LAST_PLACEHOLDER,
                                                } : null,
                                                colors: {
                                                    common: +key % 2 === 0 ? colors.COMMON_DARK : colors.COMMON_LIGHT,
                                                    insert_allowed: +key % 2 === 0 ? colors.INSERT_ALLOWED_DARK : colors.INSERT_ALLOWED_LIGHT,
                                                    insert_disallowed: +key % 2 === 0 ? colors.INSERT_DISALLOWED_DARK : colors.INSERT_DISALLOWED_LIGHT,
                                                    last_placeholder: +key % 2 === 0 ? colors.LAST_PLACEHOLDER_DARK : colors.LAST_PLACEHOLDER_LIGHT,
                                                },
                                                allowedItemType: +key,
                                                itemWidth: 2,
                                                itemHeight: 2
                                            }}
                                            args={[cellWidth, cellHeight, 1]}
                                        >
                                            {
                                                maps ? (
                                                    <meshBasicMaterial 
                                                        map={maps.COMMON} 
                                                        transparent={true}
                                                    />
                                                ) : (
                                                    <meshBasicMaterial 
                                                        color={+key % 2 === 0 ? colors.COMMON_DARK : colors.COMMON_LIGHT} 
                                                    />  
                                                )
                                            }
                                        </Plane>
                                    </Box>
                                )) }
                            </Flex>
                        )
                    }
                    { gold || +gold === 0 ? (
                        <Flex name='gold' position={[0, 50, 0]} flexDir="column" >
                            <Text
                                position={[0, 0, 0]}
                                color="red" 
                                fontSize={18}
                            >
                                Gold {gold}
                            </Text>
                        </Flex>
                    ): null }

                    { children }
                </group>

                {
                    type === 'backpack' ? 
                    (
                        <group name='backpack-items'>
                            {items?.length 
                                ? items.map(item => 
                                    <BackpackItem 
                                        onClick={onClick}
                                        onPointerMove={onItemPointerMove}
                                        onPointerLeave={onItemPointerLeave}
                                        onDoubleClick={onDoubleClick}
                                        onContextMenu={onRightClick}
                                        key={item.itemHash} 
                                        item={item} 
                                        slots={slotsRef}
                                        cellWidth={cellWidth}
                                        cellHeight={cellHeight}
                                        enabledEvents={events}
                                        slotsId={id}
                                        maps={itemMaps}
                                        itemBoxPlacement={itemBoxPlacement}
                                    />) 
                                : <></>
                            }
                        </group>
                    ) :
                    (
                        <group name='equipment-items'>
                            {items?.length 
                                ? items.map(item => 
                                    <EquipmentItem
                                        onClick={onClick}
                                        onPointerMove={onItemPointerMove}
                                        onPointerLeave={onItemPointerLeave}
                                        onDoubleClick={onDoubleClick}
                                        onContextMenu={onRightClick}
                                        key={item.itemHash} 
                                        item={item} 
                                        cellWidth={cellWidth}
                                        cellHeight={cellHeight}
                                        slots={slotsRef}
                                        enabledEvents={events}
                                        slotsId={id}
                                        maps={itemMaps}
                                        itemBoxPlacement={itemBoxPlacement}
                                    />)
                                : <></>
                            }
                        </group>
                    )
                }


            </group>

        </group>
    )
}