import * as THREE from 'three'
import React from "react"
import { Fighter } from "interfaces/fighter.interface"
import { HeatBox } from "./HeatBox"
import LastMessage from "./LastMessage"
import Name from "views/Game/GamePlay/components/Name"
import HealthBar from "views/Game/GamePlay/components/HealthBar"
import { useFrame } from '@react-three/fiber'
import { getMeshDimensions } from '../utils/getMeshDimensions'
import { NameWithMessage } from './NameWithMessage'

interface Props {
    fighter: Fighter
    heatBox?: boolean
    nameWithMessage?: boolean
    name?: boolean
    nameBackgroundColor?: number | string
    makeHeatBoxVisible?: boolean
    heatBoxEvents?: {
        onPointerEnter?: (e: any) => void
        onPointerMove?: (e: any) => void
        onPointerLeave?: (e: any) => void
        onPointerDown?: (e: any) => void
        onContextMenu?: (e: any) => void
    },
    healthBar?: boolean
}

interface Dimensions {
    width: number
    height: number
    offset: [number, number, number]
}

const preset: Record<string, Dimensions> = {
    'default': { width: 0.75, height: 1.5, offset: [0, 0, 0] },
    'warrior': { width: 0.5, height: 1.5, offset: [0, 0, 0] },
    'spider': { width: 0.5, height: 0.5, offset: [0, 0.3, 0] },
    'sproat dragon': { width: 0.75, height: 0.5, offset: [0, 0.3, 0] },
    'bull fighter': { width: 0.75, height: 1.5, offset: [0, 0.75, 0] },
    'elite bull fighter': { width: 1, height: 2.3, offset: [0, 1, 0] },
    'vault': { width: 0.75, height: 1.5, offset: [0.25, 0.75, 0.25] },
    'potion girl': { width: 0.75, height: 1.5, offset: [0.25, 0.75, 0.25] },
}

export const ObjectElements = React.forwardRef(({ 
    fighter,
    heatBox = false,
    nameWithMessage = false,
    makeHeatBoxVisible = false,
    heatBoxEvents,
    name = false,
    healthBar = false,
    nameBackgroundColor,
}: Props, outerRef: React.MutableRefObject<THREE.Group>) => {
    const dimensions = React.useMemo(() => preset[fighter.class.toLowerCase()] || preset[fighter.name.toLowerCase()] || preset['default'], [fighter])


    return (
        <group ref={outerRef}>
            { heatBox ? <HeatBox visible={makeHeatBoxVisible} {...dimensions} {...heatBoxEvents} /> : null }
            { name ? <Name value={fighter.name} backgroundColor={nameBackgroundColor} offsetY={dimensions.height + 0.6}/> : null }
            { nameWithMessage ? <NameWithMessage name={fighter.name} message={fighter.lastChatMessage} backgroundColor={nameBackgroundColor} offsetY={dimensions.height + 0.6}/> : null }
            { healthBar ? <HealthBar fighter={fighter} offsetY={dimensions.height + 0.25} /> : null }
        </group>
    )
})
ObjectElements.displayName = 'ObjectElements'