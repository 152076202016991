import type { ModelLoadItem } from "interfaces/gltfloader.interface"

import { warrior } from "./warrior"
import { npc } from "./npc"
import { shared } from "./shared"
import { fragments } from "./fragments"

import { lorencia } from "./maps/lorencia"

export const fighters = [
    ...warrior,
]

export const models: ModelLoadItem[] = [
    ...fighters,
    ...npc,
    ...shared,
    ...fragments,

    ...lorencia
]