import { Fighter } from "interfaces/fighter.interface"
import { useSoundManager } from "./useSound"
import { useCallback, useEffect } from "react"
import { Skill } from "interfaces/skill.interface"
import { getIs } from "views/Game/GamePlay/utils/utils"
import { ItemDroppedEvent } from "interfaces/item.interface"

// For actions
export const useActionSound = (fighter: Fighter, action: string) => {
    const [soundManager, sounds] = useSoundManager(fighter)

    // TODO: replace with automatic sound system in future instead of writing for each instance by hand
    useEffect(() => {
        if (!fighter) return

        // Fighter
        if (!fighter.isNpc) {
            if (action.includes('stand')) {
                soundManager.stop(sounds.GRASS_WALK)
            } else if (action.includes('run')) {
                soundManager.play(sounds.GRASS_WALK)
            }
            return
        }

        // Spider
        if (fighter.name.toLowerCase().includes('spider')) {
            if (action.includes('stand')) {
                soundManager.stop(sounds.SPIDER_WALK)
            } else if (action.includes('run')) {
                soundManager.play(sounds.SPIDER_WALK)
            }
        }

        // Any bulls
        if (fighter.name.toLowerCase().includes('bull')) {
            if (action.includes('stand')) {
                soundManager.stop(sounds.NPC_BIG_WALK)
            } else if (action.includes('run')) {
                soundManager.play(sounds.NPC_BIG_WALK)
            }
        }

        // Any dragons
        if (fighter.name.toLowerCase().includes('dragon')) {
            if (action.includes('stand')) {
                soundManager.stop(sounds.NPC_SMALL_WALK)
            } else if (action.includes('run')) {
                soundManager.play(sounds.NPC_SMALL_WALK)
            }
        }

        // Any other NPC
        if (fighter.name) {
            if (action.includes('stand')) {
                soundManager.stop(sounds.NPC_SMALL_WALK)
            } else if (action.includes('run')) {
                soundManager.play(sounds.NPC_SMALL_WALK)
            }
        }

    }, [action, soundManager, sounds])
}

// For all attack events
export const useAttackEventSound = (fighter: Fighter) => {
    const [soundManager, sounds] = useSoundManager(fighter)
    const playSkillSound = useCallback((skill: Skill) => {
        const isSkill = getIs(skill.name)
        const isName = getIs(fighter.name)

        // For Fighters
        if (!fighter.isNpc) {
            if (isSkill('malee')) { chooseWeaponSound(); return }
            if (isSkill('slash')) { soundManager.play(sounds.SKILL_SLASH_ATTACK); return }
            if (isSkill('arrow')) { soundManager.play(sounds.SKILL_ARROW_ATTACK); return }
            if (isSkill('tripple', 'shot')) { soundManager.play(sounds.SKILL_TRIPPLE_SHOT_ATTACK); return }
            if (isSkill('dark', 'spirits')) { soundManager.play(sounds.SKILL_DARK_SPIRITS_ATTACK); return }
            // For the rest of skills
            soundManager.play(sounds.SKILL_DARK_SPIRITS_ATTACK) 
            return
        }

        // Spider
        if (isName('spider')) {
            if (isSkill('malee')) { soundManager.play(sounds.SPIDER_ATTACK); return }
        }

        // Bull
        if (isName('bull')) {
            if (isSkill('malee')) { soundManager.play(sounds.BULL_ATTACK); return }
        }

        // Dragon
        if (isName('dragon')) {
            if (isSkill('malee')) { soundManager.play(sounds.DRAGON_ATTACK); return }
        }

        // For the rest of the npc
        if (isSkill('malee')) { soundManager.play(sounds.DRAGON_ATTACK); return }
    }, [soundManager, sounds, fighter])
    return playSkillSound

    function chooseWeaponSound() {
        if (fighter.equipment.is_equipped[6] || fighter.equipment.is_equipped[7]) {
            soundManager.play(sounds.SWORD)
            return
        }
        soundManager.play(sounds.MALEE)
    }
}

// Death Event
export const useDeathEventSound = (fighter: Fighter) => {
    const [soundManager, sounds] = useSoundManager(fighter)

    const playDeathSound = useCallback(() => {
        const isName = getIs(fighter.name)

        if (!fighter.isNpc) {
            soundManager.play(sounds.FIGHTER_DEATH)
            return
        }

        soundManager.play(sounds.NPC_DEATH)

    }, [soundManager, sounds, fighter])

    return playDeathSound
}

// Drop Event
export const useDropEventSound = (item: ItemDroppedEvent) => {
    const [soundManager, sounds] = useSoundManager(item)
    const playDropSound = useCallback(() => {
        const isName = getIs(item.item.name)

        soundManager.play(sounds.ITEM_DROP)

    }, [soundManager, sounds, item])

    const playPickupSound = useCallback(() => {
        const isName = getIs(item.item.name)

        if (isName('jewel')) {
            soundManager.play(sounds.ITEM_PICKUP_JEWEL)
            return
        }

        soundManager.play(sounds.ITEM_PICKUP)

    }, [soundManager, sounds, item])

    return [playDropSound, playPickupSound]
}

// Backpack(Slots) Event
export const useBackpackEventSound = () => {
    const [soundManager, sounds] = useSoundManager()
    // In case we need to change sound depending on the item, provide item in callback in Slots component
    const playEquipSound = useCallback(() => {
        // const isName = getIs(item.item.name)

        soundManager.play(sounds.ITEM_EQUIP)

    }, [soundManager, sounds])

    const playUnequipSound = useCallback(() => {
        // const isName = getIs(item.item.name)

        soundManager.play(sounds.ITEM_PICKUP)

    }, [soundManager, sounds])

    const playUpgradeSound = useCallback(() => {
        // const isName = getIs(item.item.name)

        soundManager.play(sounds.ITEM_UPGRADE)

    }, [soundManager, sounds])
    return [playEquipSound, playUnequipSound, playUpgradeSound]
}