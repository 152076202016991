import { Plane, useTexture } from "@react-three/drei"
import { UiText } from "views/Game/GamePlay/UserInterface3D/components/UiText";
import { generateItemName } from "views/Game/GamePlay/utils/generateItemName";
import { TokenAttributes } from "interfaces/item.interface";
import { useMemo } from "react";

interface Props {
    visible?: boolean
    item: { qty: number; itemHash: string; itemAttributes: TokenAttributes; slot: number; inTrade: boolean }
    position?: [number, number, number]
    placement?: 'top' | 'bottom'
}
export const ItemBox = ({ 
    item: { itemAttributes: attr, qty },
    visible,
    position,
    placement = 'bottom'
}: Props) => {
    const map = useTexture('/assets/item-box/item-box-bg-color.png')
    const name = useMemo(() => generateItemName(attr, qty), [attr, qty])
    const params = useMemo(() => Object.keys(attr.itemParameters).map(key => ({
        name: key,
        value: attr.itemParameters[key]
    })).filter(item => +item.value !== 0), [attr])
    const height = useMemo(() => params.length * 20 + 50, [params])
    return (
        <group position={position} visible={false} name='item-description'>
            <Plane position={[-150, placement === 'bottom' ? -height / 2 : height / 2, 500]} visible={visible} args={[300, height, 1]} >
                <meshBasicMaterial map={map} transparent={true} />
                <group position-y={params.length * 20 / 2}>
                    <UiText color={'#7096F9'} fontSize={20} position={[0, 0, 1]}>{ name }</UiText>
                    {params.map((item, idx) => (
                        <UiText
                            color="#FFFFFF"
                            key={item.name}
                            position={[0, (idx + 1) * -20, 1]}
                        >{ item.name }: { item.value }</UiText>
                    ))}
                </group>
            </Plane>
        </group>
    )
}
