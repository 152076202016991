export const shared = [
    // gold
    {
        name: 'shared_gold',
        url: 'models/shared/gold/gold.gltf'
    },
    // magic_box
    {
        name: 'shared_magicBox',
        url: 'models/shared/magic_box/magic_box.gltf'
    },
    // small_axe
    {
        name: 'shared_small_axe',
        url: 'models/shared/small_axe.glb'
    },
    // apple
    {
        name: 'shared_apple',
        url: 'models/shared/apple.glb'
    },
    // small_hammer
    {
        name: 'shared_small_hammer',
        url: 'models/shared/small_hammer.glb'
    },
    // knife
    {
        name: 'shared_knife',
        url: 'models/shared/knife.glb'
    },
    // large_healing_potion
    {
        name: 'shared_large_healing_potion',
        url: 'models/shared/large_healing_potion.glb'
    },
    // medium_mana_potion
    {
        name: 'shared_medium_mana_potion',
        url: 'models/shared/medium_mana_potion.glb'
    },
    // small_healing_potion
    {
        name: 'shared_small_healing_potion',
        url: 'models/shared/small_healing_potion.glb'
    },
    // small_mana_potion
    {
        name: 'shared_small_mana_potion',
        url: 'models/shared/small_mana_potion.glb'
    },
    // large_mana_potion
    {
        name: 'shared_large_mana_potion',
        url: 'models/shared/large_mana_potion.glb'
    },
    // small_axe
    {
        name: 'shared_medium_healing_potion',
        url: 'models/shared/medium_healing_potion.glb'
    },
    // sefirah_of_haya
    {
        name: 'shared_sefirah_of_haya',
        url: 'models/shared/sefirah_of_haya.glb'
    },
    // sefirah_of_ruach
    {
        name: 'shared_sefirah_of_ruach',
        url: 'models/shared/sefirah_of_ruach.glb'
    },
    // sefirah_of_nefesh
    {
        name: 'shared_sefirah_of_nefesh',
        url: 'models/shared/sefirah_of_nefesh.glb'
    },
    // sefirah_of_neshamah
    {
        name: 'shared_sefirah_of_neshamah',
        url: 'models/shared/sefirah_of_neshamah.glb'
    },
    // crystal_sword
    {
        name: 'shared_crystal_sword',
        url: 'models/equipment/crystal_sword/crystal_sword.gltf'
    },

    // wings 
    {
        name: 'shared_simple_wings',
        url: 'models/equipment/simple_wings/wings.gltf'
    },
    // axe 
    {
        name: 'shared_axe',
        url: 'models/equipment/simple_wings/wings.gltf'
    },
]