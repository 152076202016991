import { useCore } from "../../useCore"
import { useUi } from "../useUi"
import { Plane, Text, useTexture } from "@react-three/drei"
import { ThreeEvent } from "@react-three/fiber"
import { useEffect, useRef } from "react"
import * as THREE from 'three'

interface Props {
    name: string,
    fontSize?: number, 
    color?: string, 
    args?: any, 
    onClick?: () => void, 
    onPointerEnter?: () => void,
    onPointerLeave?: () => void,
    children?: string
    position?: any
    disabled?: boolean
}
export const PlaneButton = ({ 
    name, 
    fontSize = 16, 
    color = 'orange', 
    args = [80, 80], 
    onClick, 
    onPointerEnter,
    onPointerLeave,
    children,
    position,
    disabled,
    ...props 
}: Props) => {
    const [map, mapHover] = useTexture(['/assets/plane-icon.png', '/assets/plane-icon-hover.png'])
    const [setHoveredItems] = useCore(state => [state.setHoveredItems])
    const setCursor = useUi(state => state.setCursor)
    const ref = useRef<THREE.Group>()
    
    const handlePointerEnter = () => {
        if (disabled) { return }
        setCursor('pointer')
        // Just for test, cuz its wrong to hoveredItems provide this way
        // I think ill change this after ill move to another store
        // @ts-expect-error
        setHoveredItems({ id: name }, 'add')
        onPointerEnter && onPointerEnter()
    }
    const handlePointerLeave = () => {
        setCursor('default')
        // Just for test
        // @ts-expect-error
        setHoveredItems({ id: name }, 'remove')
        onPointerLeave && onPointerLeave()
    }
    const _onPointerEnter = (e: ThreeEvent<PointerEvent>) => {
        if (disabled) { return }
        // @ts-expect-error
        e.object.material.map = e.object.userData.mapHover
    }
    const _onPointerLeave = (e: ThreeEvent<PointerEvent>) => {
        // @ts-expect-error
        e.object.material.map = e.object.userData.map
    }
    
    useEffect(() => () => handlePointerLeave())

    useEffect(() => {
        if (disabled) {
            handlePointerLeave()
            if (ref.current) {
                _onPointerLeave({ object: ref.current.children[0] } as any)
                // @ts-expect-error
                ref.current.children[0].material.opacity = .7
            }
            return
        }
        // @ts-expect-error
        ref.current.children[0].material.opacity = 1
    }, [disabled])
    
    return (
        <group 
            ref={ref}
            name={"button_"+name}
            onClick={onClick}
            onPointerEnter={handlePointerEnter}
            onPointerLeave={handlePointerLeave}
            position={position}
            {...props} 
        >
            <Plane userData={{mapHover: mapHover, map: map}} onPointerEnter={_onPointerEnter} onPointerLeave={_onPointerLeave} args={args}>
                <meshBasicMaterial map={map} transparent={true} />
                <Text
                    position={[0, 0, 1]}
                    color={color}
                    fontSize={fontSize}
                >{ children }</Text>
            </Plane>
        </group>
    )
}