import { Preload } from "@react-three/drei"
import { Plane, Hud } from "@react-three/drei"
import { memo } from "react"

import { useUi } from "./useUi"
import { useSettings } from "views/Game/GamePlay/UserInterface2D/Settings/useSettings"

import { shallow } from "zustand/shallow"

import { OrthographicCamera } from "@react-three/drei"
import { Shop } from "./Shop"
import { Vault } from "./Vault"
import { Trade } from "./Trade"

import { Quickmenu } from "./Quickmenu/Quickmenu"
import { Backpack } from "./Backpack/Backpack"
import { ContextMenu } from "./ContextMenu"

const UserInterface3D = memo(function UserInterface() {
    const [userInterface, intersectionPlane] = useUi(state => [state.userInterface, state.intersectionPlane], shallow)
    const enablePost = useSettings(state => state.enablePostprocessing)
    return (
        <Hud renderPriority={enablePost ? 3 : 1}>
            <Preload all />

            {/* Environment */}
            <OrthographicCamera makeDefault position={[0, 0, 10]} />
            <ambientLight color={0xFFFFFF} intensity={.5} />
            <directionalLight color={0xFFFFFF} position={[-5, 0, 10]} intensity={.5} />
            <directionalLight color={0xFFFFFF} position={[5, 0, 10]} intensity={.5} />
            {/*  */}

            {/* Space For All Instances */}
            <group position={[0, 0, -1000]} ref={userInterface} name="user-interface">
                <Backpack/>
                <Vault/>
                <Quickmenu/>
                <Shop/>
                <Trade/>
                <ContextMenu/>

                <Plane ref={intersectionPlane} name='intersection-plane' visible={false} args={[4000, 4000, 1]}></Plane>
            </group>
            {/*  */}
        </Hud>
    )
})

export default UserInterface3D