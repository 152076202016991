import { useTexture } from "@react-three/drei"

export const useSkillsTextures = () => {
    const u = '/assets/skills-preview/'
    const textures = useTexture([
        u+'skill-unknown.png',
        u+'skill-arrow.png',
        u+'skill-darkspirits.png',
        u+'skill-slash.png',
        u+'skill-trippleshot.png',
    ])
    return {
        unknown: textures[0],
        2: textures[1],
        4: textures[2],
        1: textures[3],
        3: textures[4],
    }
}

export const useSkillsSlotsTextures = () => {
    const u = '/assets/bottom-menu/'
    const textures = useTexture([
        u+'12345-slot.png',
        u+'12345-slot-hover.png',
    ])
    return {
        skillSlot: textures[0],
        skillSlotHover: textures[1],
    }
}