import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    disabled?: boolean;
};

const StyledInput = styled.input<{ isEmpty: boolean; disabled?: boolean }>`
    padding: 8px 12px;
    border-radius: 15px;
    border: 0.5px solid rgba(86, 86, 86, 0.50);
    outline: none;
    text-align: center;
    background-color: #1c1c1c;
    transition: color 0.3s ease;
    text-align: center;
    text-shadow: 0px 0px 20px #7A7A7A;
    font-family: "Acari Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 166.9%;
    opacity: 0.5;
    color: #9A9A9A;
    filter: blur(0.15000000596046448px);
    background: #131313;
    width: 100%;
    
    ${({ isEmpty }) => !isEmpty && css`
        opacity: 0.8;
        border-color: rgba(86, 86, 86, 1);
    `}

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
        `}
`;

export const Input: React.FC<InputProps> = ({ value, onChange, placeholder = '', disabled = false, ...props }) => {
    const ref = useRef<HTMLInputElement>(null)
    console.log('input', value)
    const [inputValue, setInputValue] = useState(value);
    const isEmpty = Number(inputValue) < 1;

    useEffect(() => {
        if (value === inputValue) return
        if (ref.current) {
            setInputValue(value)
            ref.current.value = value
        }
    }, [value])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        let newValue = e.target.value.replace(/^0+(?=[1-9])|^0+(?=0)/, '');
        if (value === newValue) return
        if (/^\d*$/.test(newValue)) {
            if (isNaN(Number(newValue))) {
                newValue = '0';
            }
            setInputValue(newValue);
            onChange(e);
        }
    };

    return (
        <StyledInput
            ref={ref}
            value={inputValue}
            onChange={handleChange}
            placeholder={placeholder}
            disabled={disabled}
            isEmpty={isEmpty}
            {...props}
        />
    );
};