import { Plane, useTexture } from "@react-three/drei"
import Button from "views/Game/GamePlay/UserInterface3D/components/Button"
import { UiText } from "views/Game/GamePlay/UserInterface3D/components/UiText"
import { Skill } from "interfaces/skill.interface"
import { Texture } from "three"
import { useSkillsSlotsTextures } from "./useSkillsTextures"
import { useMemo } from "react"

interface Props {
    id: string
    position?: [number, number, number]
    onClick?: () => void
    width?: number
    height?: number
    skillMap: Texture
    name: string
}
export const SkillSelect = ({
    width = 48,
    height = 24,
    ...props
}: Props) => {
    const textures = useSkillsSlotsTextures()
    const skillMap = useMemo(() => {
        const map = props.skillMap.clone()
        // let repeatX, repeatY;
        // repeatX = width * 2448 / (height * 3264);

        // if (repeatX > 1) {
        //     //fill the width and adjust the height accordingly
        //     repeatX = 1;
        //     repeatY = height * 3264 / (width * 2448);
        //     map.repeat.set(repeatX, repeatY);
        //     map.offset.y = (repeatY - 1) / 2 * -1;
        // } else {
        //     //fill the height and adjust the width accordingly
        //     repeatX = width * 2448 / (height * 3264);
        //     repeatY = 1;
        //     map.repeat.set(repeatX, repeatY);
        //     map.offset.x = (repeatX - 1) / 2 * -1;
        // }
        return map
    }, [props.skillMap, width, height])
    return (
        <Button
            name={"skill_slot_" + props.id}
            onClick={props.onClick}
            position={props.position}
        >
            <Plane
                args={[width, height]}
                userData={{ map: textures.skillSlot, mapHover: textures.skillSlotHover }}
                // @ts-expect-error
                onPointerEnter={(e) => (e.object as THREE.Mesh).material.map = e.object.userData.mapHover}
                // @ts-expect-error
                onPointerLeave={(e) => (e.object as THREE.Mesh).material.map = e.object.userData.map}
            >
                <meshBasicMaterial map={textures.skillSlot} />
            </Plane>
            <Plane args={[width * 0.9, height * 0.9]}>
                <meshBasicMaterial map={skillMap} />
                <UiText position={[0, 0, 10]} fontSize={8} color={'#ff0000'}>{ props.name }</UiText>
            </Plane>
        </Button>
    )
}