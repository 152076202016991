import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

import type { Fighter } from "interfaces/fighter.interface";

export interface OtherFighterInterface {
    otherFighterList: Fighter[]
    updateOtherFighterList: ( otherFighterList: Fighter[] ) => void
}

export const useOtherFighter = createWithEqualityFn<OtherFighterInterface>((set, get) => ({
    otherFighterList: [],
    updateOtherFighterList: ( otherFighterList ) => {
        set({ otherFighterList })
    },
}), shallow)