// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_Modal__qIkbw {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: all;
}
.Modal_Modal__qIkbw .Modal_bg__btqZT {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
.Modal_Modal__qIkbw .Modal_container__BECnj {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/views/Game/GamePlay/UserInterface2D/components/Modal/Modal.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EAAQ,OAAA;EACR,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AAEJ;AAAI;EACI,kBAAA;EACA,MAAA;EAAQ,OAAA;EACR,WAAA;EACA,YAAA;EACA,oCAAA;EACA,WAAA;AAGR;AAAI;EACI,aAAA;EACA,2BAAA;EACA,uBAAA;EACA,sBAAA;AAER","sourcesContent":[".Modal {\n    position: fixed;\n    top: 0; left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n    pointer-events: all;\n    \n    .bg {\n        position: absolute;\n        top: 0; left: 0;\n        width: 100%;\n        height: 100%;\n        background-color: rgba(0, 0, 0, .5);\n        z-index: -1;\n    }\n\n    .container {\n        display: flex;\n        justify-content: flex-start;\n        align-items: flex-start;\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Modal": `Modal_Modal__qIkbw`,
	"bg": `Modal_bg__btqZT`,
	"container": `Modal_container__BECnj`
};
export default ___CSS_LOADER_EXPORT___;
