import { Plane, useTexture } from "@react-three/drei"
import { ButtonPlane } from "../components/ButtonPlane"
import { useCallback } from "react"
import { useUi } from "../useUi"
import { useCloud } from "EventCloud/useCloud"

interface Props {
    args?: [number, number, number]
    position?: [number, number, number]
}
export const WingRight = ({
    args = [181, 62, 1],
    position,
}: Props) => {

    const [bgMap] = useTexture(['/assets/bottom-menu/quickmenu-wing-right-bg-color.png'])
    const [backpackMap, backpackHoverMap] = useTexture(['/assets/bottom-menu/btn-inventory-color.png', '/assets/bottom-menu/btn-inventory-color-hover.png'])
    const [notificationMap, notificationHoverMap] = useTexture(['/assets/bottom-menu/btn-notification-color.png', '/assets/bottom-menu/btn-notification-color-hover.png'])

    const requestShop = useCloud(state => state.requestShop)
    const [toggleBackpack, openBackpack] = useUi(state => [state.toggle, state.open])
    const [toggleVault, openVault, closeVault] = useUi(state => [state.toggleVault, state.openVault, state.closeVault])
    const [toggleShop, openShop, closeShop] = useUi(state => [state.toggleShop, state.openShop, state.closeShop])
    const [toggleMarketplace, openMarketplace, closeMarketplace] = useUi(state => [state.toggleMarketplace, state.openMarketplace, state.closeMarketplace])


    const handleShop = useCallback(() => {
        const $state = useUi.getState()
        if (!$state.isOpenedShop) {
            requestShop()
        }
        if ($state.isOpened && !$state.isOpenedShop) {
            openShop()
            closeVault()
            return
        }
        toggleShop()
        toggleBackpack()
        closeVault()
    }, [requestShop])

    return (
        <Plane position={position} args={args}>
            <meshBasicMaterial map={bgMap} transparent={true} />
            <ButtonPlane
                name="backpack"
                args={[50, 50, 1]}
                position={[-40, 0, 1]}
                colorMap={backpackMap}
                hoverColorMap={backpackHoverMap}
                onClick={toggleBackpack}
            />
            <ButtonPlane
                name="notification"
                args={[50, 50, 1]}
                position={[5, 0, 1]}
                colorMap={notificationMap}
                hoverColorMap={notificationHoverMap}
                onClick={toggleMarketplace}
            />
        </Plane>
    )
}
