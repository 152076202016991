import { Howl } from 'howler';
import { SoundConfig, soundsConfig } from './soundsConfig';

class SoundManager {
    private static instance: SoundManager;
    private sounds: Map<string, { howl: Howl; config: SoundConfig }> = new Map();
    private loadingQueue: string[] = [];

    // Maps to keep track of sound instances using sound IDs
    private currentlyPlaying: Map<string, Set<number>> = new Map();
    private fadingIn: Map<string, Set<number>> = new Map();
    private fadingOut: Map<string, Set<number>> = new Map();
    private switchTimeouts: Map<string, NodeJS.Timeout> = new Map();

    private serviceWorker: ServiceWorker | null = null;

    private constructor(configs: SoundConfig[]) {
        this.loadEssentialSounds(configs);
    }

    // TODO: complete service worker
    // async initializeServiceWorker() {
    //     if ('serviceWorker' in navigator) {
    //         try {
    //             const registration = await navigator.serviceWorker.register('/serviceWorker.js');
    //             this.serviceWorker = registration.active;

    //             // Listen for messages from service worker
    //             navigator.serviceWorker.addEventListener('message', (event) => {
    //                 if (event.data.type === 'AUDIO_CONTROL') {
    //                     this.handleAudioControl(event.data);
    //                 }
    //             });
    //         } catch (error) {
    //             console.error('ServiceWorker registration failed:', error);
    //         }
    //     }
    // }

    // private handleAudioControl(data: any) {
    //     const { action, id, volume } = data;
    //     const sound = this.howl.get(id);

    //     if (sound) {
    //         switch (action) {
    //             case 'UPDATE_VOLUME':
    //                 sound.volume(volume);
    //                 break;
    //             case 'STOP':
    //                 sound.stop();
    //                 break;
    //             // Add other controls as needed
    //         }
    //     }
    // }

    static getInstance(): SoundManager {
        if (!SoundManager.instance) {
            SoundManager.instance = new SoundManager(soundsConfig);
        }
        return SoundManager.instance;
    }

    private loadEssentialSounds(configs: SoundConfig[]) {
        configs.forEach((config) => {
            const sound = new Howl({
                src: [config.src],
                volume: config.volume !== undefined ? config.volume : 1.0,
                loop: config.loop || false,
                preload: config.load === 'preload' || false,
                rate: config.rate || 1.0,
            });
            this.sounds.set(config.id, { howl: sound, config });
            if (config.load === 'preload') sound.load();
            else if (config.load === 'loadQueued') this.loadingQueue.push(config.id);
        });
    }

    async loadQueuedSounds() {
        for (const id of this.loadingQueue) {
            const sound = this.sounds.get(id)?.howl;
            if (sound && sound.state() === 'unloaded') await sound.load();
        }
    }

    /**
     * Plays a sound and returns the unique sound ID for this playback instance.
     * @param id The sound ID.
     * @param fadeIn Duration of fade-in effect in milliseconds.
     * @returns The unique sound ID of the playback instance.
     */
    play(id: string, fadeIn?: number): number | undefined {
        const soundData = this.sounds.get(id);
        if (soundData) {
            const { howl, config } = soundData;
            if (howl.state() === 'unloaded') howl.load();
            const targetVolume = config.volume !== undefined ? config.volume : 1.0;

            // Play the sound and get the sound ID
            const soundId = howl.play();

            // Initialize sets if they don't exist
            if (!this.currentlyPlaying.has(id)) this.currentlyPlaying.set(id, new Set());
            if (!this.fadingIn.has(id)) this.fadingIn.set(id, new Set());
            if (!this.fadingOut.has(id)) this.fadingOut.set(id, new Set());

            // Stop any ongoing fade out for this sound ID
            if (this.fadingOut.get(id)?.has(soundId)) {
                howl.stop(soundId);
                this.fadingOut.get(id)?.delete(soundId);
            }

            if (fadeIn) {
                this.fadingIn.get(id)?.add(soundId);
                howl.volume(0, soundId);
                howl.fade(0, targetVolume, fadeIn, soundId);
                howl.once(
                    'fade',
                    () => {
                        this.fadingIn.get(id)?.delete(soundId);
                    },
                    soundId
                );
            } else {
                howl.volume(targetVolume, soundId);
            }

            this.currentlyPlaying.get(id)?.add(soundId);
            return soundId;
        }
    }

    /**
     * Stops a sound playback instance.
     * @param id The sound ID.
     * @param fadeOut Duration of fade-out effect in milliseconds.
     * @param soundId The unique sound ID of the playback instance.
     */
    stop(id: string, fadeOut?: number, soundId?: number) {
        const soundData = this.sounds.get(id);
        if (soundData) {
            const { howl } = soundData;

            const soundIds = soundId !== undefined ? [soundId] : Array.from(this.currentlyPlaying.get(id) || []);

            soundIds.forEach((sid) => {
                // If the sound is fading in, stop it immediately
                if (this.fadingIn.get(id)?.has(sid)) {
                    howl.stop(sid);
                    this.fadingIn.get(id)?.delete(sid);
                    this.currentlyPlaying.get(id)?.delete(sid);
                    return;
                }

                if (fadeOut) {
                    this.fadingOut.get(id)?.add(sid);
                    const currentVolume = howl.volume(sid);
                    howl.fade(currentVolume, 0, fadeOut, sid);
                    howl.once(
                        'fade',
                        () => {
                            howl.stop(sid);
                            this.currentlyPlaying.get(id)?.delete(sid);
                            this.fadingOut.get(id)?.delete(sid);
                        },
                        sid
                    );
                } else {
                    howl.stop(sid);
                    this.currentlyPlaying.get(id)?.delete(sid);
                }
            });
        }
    }

    /**
     * Sets the volume for a specific playback instance.
     * @param id The sound ID.
     * @param volume The desired volume level (0.0 to 1.0).
     * @param soundId The unique sound ID of the playback instance.
     */
    setVolume(id: string, volume: number, soundId?: number) {
        const soundData = this.sounds.get(id);
        if (soundData) {
            if (soundId !== undefined) {
                soundData.howl.volume(volume, soundId);
            } else {
                // Set volume for all instances of this sound
                const soundIds = this.currentlyPlaying.get(id);
                if (soundIds) {
                    soundIds.forEach((sid) => {
                        soundData.howl.volume(volume, sid);
                    });
                }
            }
        }
    }

    /**
     * Adjusts the volume of a playback instance based on distance.
     * @param id The sound ID.
     * @param soundId The unique sound ID of the playback instance.
     * @param distance The distance from the listener.
     * @param maxVolumeCoeff Maximum volume coefficient.
     * @param minVolumeCoeff Minimum volume coefficient.
     * @param maxDistance The distance at which the sound is at minimum volume.
     */
    setDistanceVolume(
        id: string,
        soundId: number,
        distance: number,
        maxVolumeCoeff = 1.0,
        minVolumeCoeff = 0.01,
        maxDistance = 1
    ) {
        const soundData = this.sounds.get(id);
        // console.log(soundData)
        if (soundData) {
            const { howl, config } = soundData;
            const baseVolume = config.volume !== undefined ? config.volume : 1.0;

            // Calculate min and max volumes based on base volume and coefficients
            const minVolume = baseVolume * minVolumeCoeff;
            const maxVolume = baseVolume * maxVolumeCoeff;

            // Calculate the adjusted volume based on the distance
            const adjustedVolume = Math.max(
                minVolume,
                Math.min(maxVolume, maxVolume - (distance / maxDistance) * (maxVolume - minVolume))
            );

            // console.log('distance', distance, 'maxDistance', maxDistance, 'minVolume', minVolume, 'maxVolume', maxVolume, 'adjustedVolume', adjustedVolume, 'id', id, 'soundId', soundId)
            // console.log('config.volume', config.volume)
            howl.volume(adjustedVolume, soundId);
        }
    }

    /**
     * Switches from one set of sounds to another with optional fade effects.
     * @param oldIds The old sound IDs to stop.
     * @param newIds The new sound IDs to play.
     * @param fadeOut Duration of fade-out effect for old sounds.
     * @param fadeIn Duration of fade-in effect for new sounds.
     */
    switch(oldIds: string | string[], newIds: string | string[], fadeOut = 200, fadeIn = 200) {
        const oldIdArray = Array.isArray(oldIds) ? oldIds : [oldIds];
        const newIdArray = Array.isArray(newIds) ? newIds : [newIds];

        // Clear any existing switch timeouts
        this.switchTimeouts.forEach((timeout, id) => {
            clearTimeout(timeout);
            this.switchTimeouts.delete(id);
        });

        oldIdArray.forEach((id) => {
            if (this.sounds.has(id)) {
                this.stop(id, fadeOut);
            }
        });

        const switchTimeout = setTimeout(() => {
            newIdArray.forEach((id) => {
                if (this.sounds.has(id)) {
                    this.play(id, fadeIn);
                }
            });
            this.switchTimeouts.delete(newIdArray.join(','));
        }, fadeOut);

        this.switchTimeouts.set(newIdArray.join(','), switchTimeout);
    }
}

export default SoundManager;
