import { Hud, OrthographicCamera } from "@react-three/drei"
import tunnel from "tunnel-rat"
import { Aling } from "../GamePlay/UserInterface3D/components/Align"
import { PlaneButton } from "../GamePlay/UserInterface3D/components/PlaneButton"
import { useCloud } from "EventCloud/useCloud"
import React, { useEffect, useState } from "react"
import Web3 from "web3"
import styled from "styled-components"
import { useGame } from "core/useGame"
import { useMenu } from "."
import { useLayout } from "Layout"
import { useNavigate } from "react-router"
import { useCookies } from "react-cookie"
import { toast } from "react-toastify"

export const UIMenu = tunnel()

export const UI = () => {
    const [sendAuth, createFighter] = useCloud(state => [state.sendAuth, state.createFighter])
    const [fighterName, setFighterName] = React.useState<string>('');
    const [showModal, setShowModal] = useState(false)
    const [location, setLocation] = useGame(state => [state.location, state.setLocation])
    const [selected] = useMenu(state => [state.selected])
    const navigate = useNavigate()
    const [,setCookie] = useCookies(['sessionId'])
    const { setSessionId } = useLayout()

    const createWarrior = React.useCallback(() => {
        createFighter("Warrior", fighterName)
        setShowModal(false)
    }, [createFighter, fighterName, setShowModal])

    const createWizard = React.useCallback(() => {
        createFighter("Wizard", fighterName)
        setShowModal(false)
    }, [createFighter, fighterName, setShowModal])

    const logOut = React.useCallback(() => {
        navigate('/auth') 
        setCookie('sessionId', '', { path: '/' })
        setSessionId('')
        toast('You have been successfully logged out.', { type: 'success' })
    }, [])

    return (
        <>
            <UIMenu.In>
                <Hud renderPriority={1}>
                    {/* Environment */}
                    <OrthographicCamera makeDefault position={[0, 0, 10]} />
                    <ambientLight color={0xFFFFFF} intensity={.5} />
                    <directionalLight color={0xFFFFFF} position={[-5, 0, 10]} intensity={.5} />
                    <directionalLight color={0xFFFFFF} position={[5, 0, 10]} intensity={.5} />
                    {/*  */}
                    <Aling alignY="bottom" offsetY={100}>
                        <PlaneButton 
                            name="play" 
                            disabled={!selected}
                            args={[160, 50]} 
                            position={[90, 0, 0]}
                            onClick={() => {
                                if (location === 'menu') {
                                    if (!selected) { return }
                                    sendAuth(selected.tokenId)
                                    setLocation('world')
                                    return
                                }
                                setLocation('menu')
                            }}
                        >Play</PlaneButton>
                        <PlaneButton 
                            name="create" 
                            args={[160, 50]} 
                            position={[-90, 0, 0]} 
                            onClick={() => setShowModal(true)}
                        >Create Character</PlaneButton>

                    </Aling>
                    <Aling alignX="right" alignY="bottom" offsetY={100}>
                        <PlaneButton 
                            name="Log out" 
                            args={[90, 50]} 
                            position={[-80, 0, 0]} 
                            onClick={logOut}
                            >Log out</PlaneButton>
                    </Aling>
                </Hud>
            </UIMenu.In>
            <StyledWrapper>
                {showModal ? (
                    <div className="MetamaskDialog">
                        <div className="MetamaskDialogFade" onClick={() => setShowModal(false)}></div>
                        <div className='MetamaskDialogRow'>
                            <p>Create new Character:</p>
                            <input
                                type="text"
                                placeholder="Enter Fighter Name"
                                value={fighterName}
                                onChange={(e) => setFighterName(e.target.value)}
                            />

                            <button onClick={createWarrior}>Create Warrior</button>
                            <button onClick={createWizard}>Create Wizard</button>
                        </div>
                    </div>
                ) : null}
            </StyledWrapper>
        </>
    )
}

const StyledWrapper = styled.div`
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    pointer-events: none;

    .Message {
        font-size: 32px;
    }

    .MetamaskDialog {
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: all;
    }

    .MetamaskDialogFade {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .8);
        backdrop-filter: blur(40px);
        cursor: pointer;
    }

    .MetamaskDialogRow {
        background-color: rgba(0, 0, 0, .3);
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 32px;
        color: white;
        width: 400px;
        z-index: 1;


        button, input {
            padding: 0 12px;
            height: 24px;
        }

        button {
            cursor: pointer;
        }

        > div {
            display: flex;
            gap: 8px;
        }
    }
`