import { useCloud } from "EventCloud/useCloud"
import { Slots } from "./Slots/Slots"
import { useUi } from "./useUi"
import { Aling } from "./components/Align"
import { useMemo, useRef } from "react"
import { useFighter } from "views/Game/GamePlay/Fighter/useFighter"
import { useGoldEnter } from "views/Game/GamePlay/UserInterface2D/GoldEnter/GoldEnter"
import { Plane, useTexture } from "@react-three/drei"
import { CloseButton } from "./components/CloseButton"
import { UiText } from "./components/UiText"
import { useBackpackItemMaps, useBackpackSlotsMaps } from "./Backpack/useBackpackSlotsMaps"
import Button from "./components/Button"
import { Inventory } from "interfaces/inventory.interface"
import { Fighter } from "interfaces/fighter.interface"
import { ButtonPlane } from "./components/ButtonPlane"
import * as THREE from 'three'

interface MeOpponent {
    inventory: Inventory
    approve: boolean
    fighter: Fighter
}

export const Trade = () => {
    const trade = useCloud(state => state.trade)
    const [isOpenedTrade] = useUi(state => [state.isOpenedTrade])
    const [removeTradeItem, removeTradeItemToBackpack, moveTradeItem] = useCloud(state => [state.removeTradeItem, state.removeTradeItemToBackpack, state.moveTradeItem])
    const [openGoldEnter] = useGoldEnter(state => [state.open])
    const relatedNode = useRef<THREE.Group>()
    const relatedNode2 = useRef<THREE.Group>()
    const bgMap = useTexture('/assets/backpack-menu/trade-bg-color.png')
    const slotsMaps = useBackpackSlotsMaps()
    const bpItemMaps = useBackpackItemMaps()
    const [cancelColor, cancelHoverColor] = useTexture(['/assets/backpack-menu/cancel-trade-color.png','/assets/backpack-menu/cancel-trade-hover-color.png'])
    const [approveColor, approveHoverColor] = useTexture(['/assets/backpack-menu/approve-trade-color.png','/assets/backpack-menu/approve-trade-hover-color.png'])

    const [me, opponent] = useMemo((): [MeOpponent, MeOpponent] => {
        if (!trade) { return [{} as any, {} as any] }
        const $fighter = useFighter.getState().fighter
        if (trade.Fighter1.tokenId === $fighter.tokenId) {
            return [get(1), get(2)]
        }
        return [get(2), get(1)]

        function get(type: 1 | 2) {
            if (type === 1) {
                return {
                    inventory: trade.inventory_1,
                    approve: trade.approve_1,
                    fighter: trade.Fighter1,
                }
            }
            return {
                inventory: trade.inventory_2,
                approve: trade.approve_2,
                fighter: trade.Fighter2,
            }
        }
    }, [trade])

    return (
        <Aling ref={relatedNode} alignX="right" visible={isOpenedTrade && !!trade} hoverId="tradeAlign">
            <Plane position={[-660, 40, 0]} args={[420, 826, 1]} ref={relatedNode2 as any}>
                <meshBasicMaterial map={bgMap} transparent={true} />
                <CloseButton 
                    name="vault"
                    position={[140, 345, 10]}
                    onClick={() => useCloud.getState().cancelTrade()}
                    visible={isOpenedTrade && !!trade}
                />
                <UiText position={[0, 345, 10]} color={'#d48932'}>Trade</UiText>
                {
                    opponent?.fighter && me?.fighter ? (
                        <>
                            <UiText anchorX="left" position={[-140, 265, 10]} color={!opponent.approve ? '#cccccc' : '#139c53'}>{ opponent?.fighter.name }</UiText>
                            <UiText anchorX="right" position={[140, 265, 10]} color={!opponent.approve ? '#cccccc' : '#139c53'}>Lvl. { opponent?.fighter.level }</UiText>
                            <UiText anchorX="left" position={[-140, -45, 10]} color={'#87A1C8'}>{ me?.fighter.name } (you)</UiText>
                            <UiText anchorX="right" position={[140, -45, 10]} color={'#87A1C8'}>Lvl. { me?.fighter.level }</UiText>
                        </>
                    ) : null
                }
                <ButtonPlane
                    name="cancel-trade"
                    colorMap={cancelColor}
                    hoverColorMap={cancelHoverColor}
                    position={[-138, -345, 1]}
                    args={[45, 45, 1]}
                    onClick={() => useCloud.getState().cancelTrade()}
                    visible={isOpenedTrade && !!trade}
                />
                <UiText textAlign="center" fontSize={12} maxWidth={180} position={[0, -345, 1]} color={'#525252'}>Please check the level of a player and items before trading</UiText>
                {!me?.approve ? (
                    <ButtonPlane
                        name="approve-trade"
                        colorMap={approveColor}
                        hoverColorMap={approveHoverColor}
                        position={[140, -345, 1]}
                        args={[45, 45, 1]}
                        onClick={() => !me?.approve && useCloud.getState().approveTrade()}
                        visible={isOpenedTrade && !!trade}
                    />
                ) : (
                    <Plane position={[140, -345, 1]} args={[45, 45, 1]}>
                        <meshBasicMaterial map={approveColor} transparent={true} opacity={.5} />
                    </Plane>
                )}

                <group position={[0, 0, 1]}>
                    {
                        trade ? (
                            <>
                                <Slots
                                    position={[-122, 200, 0]}
                                    id='ID_TRADE_HIS'
                                    type='backpack'
                                    enableRender={isOpenedTrade}
                                    cellWidth={35}
                                    cellHeight={35}
                                    grid={opponent?.inventory.grid || []}
                                    items={opponent?.inventory.items || []}
                                    width={opponent?.inventory.grid.length || 8}
                                    height={opponent?.inventory.grid[0].length || 4}
                                    events={[]}
                                    maps={slotsMaps}
                                    itemMaps={bpItemMaps}
                                    relatedNodes={[relatedNode, relatedNode2]}
                                />
                                <UiText position={[110, 43, 10]} anchorX="right" color={'#dc801f'}>{ opponent?.inventory.gold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</UiText>

                                <Slots
                                    position={[-122, -110, 0]}
                                    id='ID_TRADE_MINE'
                                    type='backpack'
                                    enableRender={isOpenedTrade}
                                    cellWidth={35}
                                    cellHeight={35}
                                    grid={me?.inventory.grid || []}
                                    items={me?.inventory.items || []}
                                    width={me?.inventory.grid.length || 8}
                                    height={me?.inventory.grid[0].length || 4}
                                    maps={slotsMaps}
                                    itemMaps={bpItemMaps}
                                    events={[
                                        { id: '', type: 'rightClick', handler: (props) => removeTradeItem(props.item.itemHash) },
                                        { id: 'ID_BACKPACK', type: 'transferTo', handler: (props) => removeTradeItemToBackpack(props.item.itemHash, props.position) },
                                        { id: 'ID_EQUIPMENT', type: 'transferToWithNotAllowed', handler: (props) => removeTradeItem(props.item.itemHash) },
                                        { id: 'ID_BACKPACK', type: 'transferToWithNotAllowed', handler: (props) => removeTradeItem(props.item.itemHash) },
                                        { id: 'ID_TRADE_MINE', type: 'update', handler: (props) => moveTradeItem(props.item.itemHash, props.position) },
                                    ]}
                                    relatedNodes={[relatedNode, relatedNode2]}
                                />
                                <Button visible={isOpenedTrade && !!trade} onClick={() => openGoldEnter(useCloud.getState().setTradeGold)} position={[110, -266, 10]}>
                                    <Plane visible={false} args={[260, 25, 1]} position={[-110, 0, 0]}/>
                                    <UiText anchorX="right" color={'#dc801f'}>{ me?.inventory.gold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</UiText>
                                </Button>
                            </>
                        ) : null
                    }
                </group>
            </Plane>
        </Aling>
    )
}