export const shader_spawn = () => {
    const fragmentShaderHeader = `
        uniform vec3 uSpawnColorPrimary;
        uniform vec3 uSpawnColorAccent;

        // Wave Base
        uniform float uSpawnWaveBaseFrequency;
        uniform float uSpawnWaveBaseStrength;
        uniform float uSpawnWaveBaseSpeed;
        uniform float uSpawnWaveBaseAlpha;

        // Laminate
        uniform float uSpawnLaminateIntensity;

        uniform bool uSpawnVisible;
        uniform float uSpawnHiddenAlpha;

        float getSpawnFlashAlpha(float perlingStrength) {
            float filledFlashAlpha = mix(0.1, 0.2, perlingStrength);
            float strokeFlashAlpha = 1. - smoothstep(0., 1., abs(perlingStrength));
            strokeFlashAlpha = mix(0., .5, strokeFlashAlpha);
            float combinedFlashFilledAlpha = max(filledFlashAlpha, strokeFlashAlpha);
            float solidCombined = max(0., combinedFlashFilledAlpha);
            return solidCombined;
        }

        vec3 getSpawnGlossColor() {
            return normalize(uSpawnColorPrimary) * uSpawnLaminateIntensity;
        }

        vec4 getSpawnShader() {
            // Choose Color
            vec3 colorAccent = uSpawnColorAccent;
    
            float t_waveBase = uTime * uSpawnWaveBaseSpeed;
            float waveBaseAccentPerling = perlin4d(vec4(vec3(vUv.x - t_waveBase, vUv.y + vUv.x - t_waveBase, 1.) * uSpawnWaveBaseFrequency, 1.)) * uSpawnWaveBaseStrength;
            float waveBaseAccentAlpha = getSpawnFlashAlpha(waveBaseAccentPerling);
            vec3 waveBaseAccentColor = normalize(colorAccent) * waveBaseAccentAlpha * uSpawnWaveBaseAlpha;
            vec3 glossColor = getSpawnGlossColor();
            vec3 color = mix(waveBaseAccentColor + glossColor, vec3(0.), uSpawnHiddenAlpha);

            return vec4(color, uSpawnHiddenAlpha);
        }
    `
    const fragmentShaderFooter = `
        vec4 spawnShader = getSpawnShader();
        baseColor = baseColor + spawnShader.rgb;
        baseAlpha = baseAlpha * spawnShader.a;
    `

    const uniforms = {
        uSpawnColorPrimary: { value: { r: 255, g: 255, b: 255 } },
        uSpawnColorAccent: { value: { r: 245, g: 237, b: 201 } },

        // Global
        uTime: { value: 0 },

        // Wave Base
        uSpawnWaveBaseFrequency: { value: 2 },
        uSpawnWaveBaseStrength: { value: 10.0 },
        uSpawnWaveBaseSpeed: { value: 0.05 },
        uSpawnWaveBaseAlpha: { value: 0.3 },

        // Laminate
        uSpawnLaminateIntensity: { value: 0.3 },

        uSpawnVisible: { value: true },
        uSpawnHiddenAlpha: { value: 1.0 }
    }

    return {
        injectFragmentShader: {
            header: fragmentShaderHeader,
            footer: fragmentShaderFooter
        },
        uniforms,
    }
}