import { useUi } from "../useUi"
import { useEffect } from "react"


const Button = ({ children, name, onClick, onPointerEnter, onPointerLeave, visible = true, ...props }: any) => {
    
    const setCursor = useUi(state => state.setCursor)
    
    const handlePointerEnter = (e) => {
        if (!visible) { return }
        setCursor('pointer')
        onPointerEnter && onPointerEnter()
    }
    const handlePointerLeave = (e) => {
        if (!visible) { return }
        setCursor('default')
        onPointerLeave && onPointerLeave()
    }

    return (
        <group 
            {...props} 
            name={"button_"+name}
            onClick={onClick}
            onPointerMove={handlePointerEnter}
            onPointerLeave={handlePointerLeave}
        >
            { children }
        </group>
    )
}

export default Button