import { View } from "core/Tunnel/View"
import { useCloud } from "EventCloud/useCloud"
import { MutableRefObject, Suspense, useEffect, useMemo, useRef } from "react"
import GLTFLoader from "views/Game/GamePlay/Models/GLTFLoader"
import { useWiki } from "."
import { getShaderedModel } from "views/Game/GamePlay/Models/utils/getShaderedModel"
import { ItemAttributes } from "interfaces/item.interface"
import { useFrame } from "@react-three/fiber"
import { Box, Environment, OrbitControls, OrthographicCamera, Text, useTexture } from "@react-three/drei"
import * as THREE from 'three'
import { useControls } from "leva"

export const Scene = () => {
    const requestAllItems = useCloud(state => state.requestAllItems)
    useEffect(() => { requestAllItems() }, [requestAllItems])
    return (
        <View>
            <OrthographicCamera makeDefault position={[0, 0, 1000]} near={0.1} far={10000} />
            <OrbitControls makeDefault />
            <Suspense fallback={null}>
                <Inner />
            </Suspense>

            <ambientLight color={'#152365'} intensity={12} />
            <directionalLight
                intensity={2}
                color="#fff6d5"
                position={[0, 0, 1000]}
            />
            <pointLight
                intensity={200}
                decay={1.5}
                distance={1000}
                color="#faff99"
            />
            <Environment files='christmas_photo_studio_07_1k.hdr' environmentIntensity={.5} />
        </View>
    )
}

const rowLength = 9
function Inner() {
    const items = useWiki(state => state.items)
    const values = useMemo(() => Object.values(items), [items])
    const uniforms = useRef({
        uTime: { value: 0 },
        uLevel: { value: 0 },
        uIsExellent: { value: false }
    })
    const controls = useControls('Level Shader', {
        uLevel: { value: 0, min: 0, max: 15, step: 1 },
        uIsExellent: { value: false }
    })
    useEffect(() => {
        uniforms.current.uIsExellent.value = controls.uIsExellent
        uniforms.current.uLevel.value = controls.uLevel
    }, [controls])

    useFrame(({ clock }) => {
        uniforms.current.uTime.value = clock.getElapsedTime()
    })

    return (
        <GLTFLoader>
            <group>
                {values.map((item, idx) => {
                    const x = (idx % rowLength) - (rowLength - 1) / 2
                    const y = Math.floor(idx / rowLength) - Math.floor(values.length / rowLength) / 2

                    return (
                        <Item
                            key={idx}
                            itemAttributes={item}
                            uniforms={uniforms}
                            x={x}
                            y={y}
                        />
                    )
                })}
            </group>
        </GLTFLoader>
    )
}

interface ItemProps {
    itemAttributes: ItemAttributes,
    x: number
    y: number
    uniforms: MutableRefObject<any>
}
function Item({
    itemAttributes,
    x, y,
    uniforms
}: ItemProps) {
    const { map } = useTexture({ map: 'assets/notexture.png' })

    // @ts-expect-error
    const gltf = getShaderedModel('inventory ' + itemAttributes.name, { itemAttributes }, uniforms)
    const model = useMemo(() => {
        if (!gltf?.scene) {
            return new THREE.Mesh(
                new THREE.BoxGeometry(1, 1, 1),
                new THREE.MeshStandardMaterial({ color: 'pink', map })
            )
        }
        return gltf.scene
    }, [gltf])
    useFrame(({ clock }) => {
        model.rotation.y = clock.getElapsedTime()
    })

    return (
        <group
            scale={[10, 10, 10]}
            position={[x * 30, y * 30, 0]}
        >
            <primitive
                object={model}
            />
            <Text fontSize={.15} position={[0, -1, 1]}>{itemAttributes.name}</Text>
        </group>

    )
}