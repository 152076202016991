import FighterDash from './FighterDash'
import { useFighter } from 'views/Game/GamePlay/Fighter/useFighter'
import { useLayoutEffect, useMemo } from 'react'
import { useStats } from './useStats'
import { StatsField } from './StatsField'
import { useCloud } from 'EventCloud/useCloud'
import styled from 'styled-components'
import { StyledText, StyledTitle } from '../styles'
import { TextureBtn } from '../components/TextureBtn'

const Stats = () => {
    const fighter = useFighter(state => state.fighter)
    const isOpened = useStats(state => state.isOpened)
    useLayoutEffect(() => {
        const $stats = useStats.getState()
        $stats.subscribeStats()
        return () => $stats.unsubscribeStats()
    }, [])

    useLayoutEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            const statsElement = document.querySelector('.stats-container')
            if (statsElement && !statsElement.contains(e.target as Node)) {
                useStats.getState().close()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    const data = useMemo(() => {
        const $cloud = useCloud.getState()
        return [
            {
                name: 'Strength',
                amount: fighter?.strength,
                onSubmit: $cloud.statsAddStrength,
                info: [ 
                    { name: 'Dmg(rate)', value: fighter?.damage },
                    { name: 'Attack rate', value: fighter?.damageRate },
                ]
            },
            {
                name: 'Agility',
                amount: fighter?.agility,
                onSubmit: $cloud.statsAddAgility,
                info: [ 
                    { name: 'Attack speed', value: fighter?.attackSpeed },
                    { name: 'Defence', value: fighter?.defence },
                    { name: 'Defence(rate)', value: fighter?.defenceRate },
                ]
            },
            {
                name: 'Energy',
                amount: fighter?.energy,
                onSubmit: $cloud.statsAddEnergy,
                info: [ 
                    { name: 'Mana', value: fighter?.maxMana },
                    { name: 'Wizardry dmg', value: fighter?.wizardryDamage },
                    { name: 'Skill damage', value: fighter?.skillDamage },
                ]
            },
            {
                name: 'Vitality',
                amount: fighter?.vitality,
                onSubmit: $cloud.statsAddVitality,
                info: [ 
                    { name: 'HP', value: fighter?.maxHealth },
                ]
            },
        ]
    }, [fighter])

    if (!isOpened) return null

    return (
        <StyledStats className='stats-container'>
            <TextureBtn 
                className='close' 
                texture='/assets/shared/close-button-color.png' 
                width={16} 
                height={16} 
                onClick={() => useStats.getState().close()}
            />
            <img src='/assets/stats/stats-color-map.png' width={408} height={799} alt="" />
            <div className='header'>
                <StyledTitle>{fighter?.name} [{fighter?.level}] </StyledTitle>
                {/* <span>Av. pts: <b>{ fighter?.available_stats }</b></span> */}
                {/* <FighterDash/> */}
            </div>
            <StyledContent>
                <StyledStatsRow>
                    <div className='stats-item'>
                        <StyledText>Exp:</StyledText>
                        <div className='exp'>{fighter?.experience}</div>
                    </div>
                    <div className='stats-item'>
                        <StyledText>Available points:</StyledText>
                        <div className={`points ${fighter?.available_stats ? 'active' : ''}`}>{fighter?.available_stats}</div>
                    </div>
                </StyledStatsRow>
                {data.map((_, i) => (
                    <StatsField 
                        disabled={+fighter?.available_stats < 1}
                        key={i} 
                        name={_.name} 
                        amount={_.amount+''} 
                        info={_.info as any} 
                        onSubmit={_.onSubmit} 
                    />
                ))}
            </StyledContent>

        </StyledStats>
    )
}

export default Stats

const StyledStats = styled.div`
    position: absolute;
    left: 64px;
    bottom: 50%;
    transform: translateY(50%);
    color: rgba(255, 255, 255, .5);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10002;
    gap: 16px;
    pointer-events: all;
    border-radius: 8px;
    width: 408px;
    height: 800px;
    padding: 75px 65px 0 70px;

    img {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: -1;
    }

    .close {
        position: absolute;
        top: 78px; right: 60px;
        width: 16px;
        height: 16px;
        z-index: 1;
        cursor: pointer;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .stats-item {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .exp {
        display: flex;
        padding: 4px 10px 4px 10px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        border-radius: 15px;
        border: 0.5px solid #565656;
        background: #252525;
        box-shadow: 0px -4px 4px 0px rgba(79, 79, 79, 0.45) inset;
    }

    .points {
        display: flex;
        padding: 11px 10px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        border-radius: 15px;
        border: 0.5px solid #35311f;
        background: #2a2115;
        box-shadow: 0px -4px 4px 0px rgba(79, 79, 79, 0.45) inset;
        min-width: 43px;
        color: #444444;

        &.active {
            background: #58411F;
            border: 0.5px solid #AB9E55;
            color: #d3d3d3;
        }
    }
`

const StyledStatsRow = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    margin: 12px 0 24px 0;
    justify-content: space-between;

    p {
        word-wrap: break-word;
        max-width: 100px;
    }
`

const StyledContent = styled.div`
    width: 100%;
    margin-top: 60px;
`

