import styled from "styled-components"

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    children: any
}
export const Button = ({ children, ...props }: Props) => {
    return (
        <StyledButton {...props}>
            { children }
        </StyledButton>
    )
}

const StyledButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: transparent;
    box-shadow: inset 0 -3px 3px 0 rgba(255, 255, 255, 0.1), 0 0 8px 0 rgba(0, 0, 0, 0.1), inset 0 0 11px 0 rgba(255, 255, 255, 0.1);
    padding: 12px 16px;
    border: none;
    outline: none;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 167%;
    text-align: center;
    background: radial-gradient(26.67% 41.67% at 70.03%  50.00%, rgb(255, 234, 47) 0%, rgb(236, 163, 78) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;

    &:hover {
        box-shadow: inset 0 -3px 3px 0 rgba(255, 255, 255, 0.3), 0 0 8px 0 rgba(0, 0, 0, 0.3), inset 0 0 11px 0 rgba(255, 255, 255, 0.3);
    }
`