import * as THREE from 'three'
import { useCore } from '../useCore'
import { useEffect, useMemo, useRef, useState } from "react"
import { useGLTFLoaderStore } from "views/Game/GamePlay/Models/GLTFLoaderStore"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"
import { useFighter } from "views/Game/GamePlay/Fighter/useFighter"
import { useThree } from '@react-three/fiber'
import { useCloud } from 'EventCloud/useCloud'

export const GameMap = () => {
    const [location] = useCore(state => [state.location.toLowerCase()])
    const [worldSize, chunkSize] = useCore(state => [state.worldSize, state.chunkSize])
    const chunksCount = useMemo(() => Math.pow(worldSize / chunkSize, 2), [worldSize, chunkSize])

    const groupRef = useRef<THREE.Group | null>(null);

    return (
        <group ref={groupRef} name="map" visible={true}>
            {Array.from({ length: chunksCount }).map((_, idx) => {
                const y = Math.floor(idx / (worldSize / chunkSize) - Math.floor(worldSize / chunkSize / 2))
                const x = idx % (worldSize / chunkSize) - Math.floor(worldSize / chunkSize / 2)
                return <Chunk key={idx} id={`${location}_${x}_${y}`} />
            })}
        </group>
    )
}

interface ChunkProps { id: string }
// !IMPORTANT: depends om Camera position, should be changed in case camera position has been changed
const maxDistanceTop = 30 // [TOP]
const maxDistanceBottom = 12 // [BOTTOM] 
const maxDistanceLeft = 25 // [LEFT] 
const maxDistanceRight = 20 // [RIGHT]

const closestPoint = new THREE.Vector3();
const closestGroundPoint = new THREE.Vector3();
const Chunk = ({
    id,
}: ChunkProps) => {
    const renderer = useThree(state => state.gl)
    const camera = useThree(state => state.camera)
    const scene = useThree(state => state.scene)

    const { scene: chunkModel } = useModel(id)
    const fighterNode = useFighter(state => state.fighterNode)

    const [chunkIsReady, ready] = useState(false)
    // Disable Far Away Objects
    const chunkScene = useRef<THREE.Group | null>(null)
    const chunkObjects = useRef<Map<number, THREE.Object3D>>(new Map())
    const chunkObjectsBoundingBox = useRef<Map<number, THREE.Box3>>(new Map())
    const groundBoundingBox = useRef<THREE.Box3 | null>(null)
    // Render Manager
    const renderChunkCurrent = useRef<boolean>(true)
    const renderChunkNew = useRef<boolean>(true)

    // useOccupiedCoordinates(objectsData)
    

    // Compile Chunk
    useEffect(() => {
        // Enable Shadows
        chunkModel.traverse(object => {
            object.receiveShadow = true
            object.castShadow = true
        })

        // Generate
        chunkModel.children.forEach((object, idx) => chunkObjects.current.set(idx, object.clone()))
        chunkObjects.current.forEach((object, idx) => {
            chunkScene.current.add(object)
            const boundingBox = new THREE.Box3().setFromObject(object)
            chunkObjectsBoundingBox.current.set(idx, boundingBox)
            if (object.name.toLowerCase().includes('ground')) {
                groundBoundingBox.current = boundingBox
                // Enable shadow for ground
                object.receiveShadow = true
            }
        })
        
        // PRE-COMPILE: Preload shaders and materials to avoid freezing later
        renderer.compile(scene, camera)
        // Precompute
        console.log(chunkScene.current)
        chunkScene.current.traverse((object: THREE.Mesh) => {
            // if (!object.name.toLowerCase().includes('ground')) {
            //     if (object.material) {
            //         // @ts-expect-error
            //         object.material.transparent = true
            //         // @ts-expect-error
            //         object.material.opacity = 0.1
            //     }
            // }
            if (object.geometry) {
                object.geometry.computeBoundingSphere()
            }
        })
        console.log('Chunk ', id, ' has been [Compiled]')
        // Ready to render
        setTimeout(() => ready(true), 100)
    }, [])

    // const { renderAll } = useControls('Map', { renderAll: false })

    // Render Chunk
    useEffect(() => {
        if (!chunkIsReady) { return }

        const startTime = { value: performance.now() }
        let rq = requestAnimationFrame(function render(time: number) {
            if (time - startTime.value > 500) {
                if (!groundBoundingBox.current) { return }
                renderChunkNew.current = calculateCollision(groundBoundingBox.current, closestGroundPoint)
                
                if (renderChunkCurrent.current) { 
                    chunkObjects.current.forEach((object, idx) => {
                        const objectBoundingBox = chunkObjectsBoundingBox.current.get(idx)
                        const isVisible = calculateCollision(objectBoundingBox, closestPoint)
                        if (isVisible) {
                            chunkScene.current.add(object)
                        } else {
                            chunkScene.current.remove(object)
                        }
                    })
                }

                // Check if chunk has changed a state
                if (renderChunkCurrent.current !== renderChunkNew.current) {
                    if (!renderChunkNew.current) {
                        console.log('Chunk ', id, ' Has beed [Disabled].')
                        chunkObjects.current.forEach(object => {
                            chunkScene.current.remove(object)
                        })
                    } else {
                        console.log('Chunk ', id, ' Has beed [Enabled].')
                    }
                    renderChunkCurrent.current = renderChunkNew.current
                }

                startTime.value = performance.now()
            }
            rq = requestAnimationFrame(render)
        })

        function calculateCollision(objectBoundingBox: THREE.Box3, closestPoint: THREE.Vector3) {
            const characterPosition = fighterNode.current.position
            objectBoundingBox.clampPoint(characterPosition, closestPoint)

            const relativePositionX = closestPoint.x - characterPosition.x;
            const relativePositionZ = closestPoint.z - characterPosition.z;

            // Check if object is in both 2 or 3 directions
            let isVisible =
                (relativePositionX < 0 && Math.abs(relativePositionX) <= maxDistanceBottom) || // Объект слева
                (relativePositionX > 0 && relativePositionX <= maxDistanceTop) || // Объект справа
                (relativePositionZ < 0 && Math.abs(relativePositionZ) <= maxDistanceLeft) || // Объект сверху
                (relativePositionZ > 0 && relativePositionZ <= maxDistanceRight); // Объект снизу

            // Check if object is in one direction
            if (relativePositionX > 0 && relativePositionZ > 0) {
                // Right & Bottom
                isVisible =
                    relativePositionX <= maxDistanceTop && relativePositionZ <= maxDistanceRight;
            } else if (relativePositionX < 0 && relativePositionZ > 0) {
                // Left & Bottom
                isVisible =
                    Math.abs(relativePositionX) <= maxDistanceBottom && relativePositionZ <= maxDistanceRight;
            } else if (relativePositionX < 0 && relativePositionZ < 0) {
                // Left & top
                isVisible =
                    Math.abs(relativePositionX) <= maxDistanceBottom && Math.abs(relativePositionZ) <= maxDistanceLeft;
            } else if (relativePositionX > 0 && relativePositionZ < 0) {
                // Right & top
                isVisible =
                    relativePositionX <= maxDistanceTop && Math.abs(relativePositionZ) <= maxDistanceLeft;
            }

            // Check of object is on both 4 directions
            if (
                Math.abs(relativePositionX) <= maxDistanceTop &&
                Math.abs(relativePositionX) <= maxDistanceBottom &&
                Math.abs(relativePositionZ) <= maxDistanceLeft &&
                Math.abs(relativePositionZ) <= maxDistanceRight
            ) {
                isVisible = true;
            }

            return isVisible
        }

        return () => {
            cancelAnimationFrame(rq)
            // chunkObjects.current.forEach((object) => {
            //     chunkScene.current.remove(object)
            // })
            chunkObjects.current.clear()
            chunkObjectsBoundingBox.current.clear() 
        }
    }, [chunkIsReady])

    return (
        <>
            {/* TODO: fix, eat fps, around 20 */}
            {/* <LockedCoordinates /> */}
            <group ref={chunkScene} />
        </>
    )
}


// Load Model
export const useModel = (name: string): GLTF => {
    return useMemo(() => {
        const models = useGLTFLoaderStore.getState().models.current
        const key = Object.keys(models).find(_ => (_.toLowerCase().includes(name)))
        if (key) return models[key]
        return null
    }, [name])
}


function LockedCoordinates() {
    const [lockedCoordinates] = useCloud(state => [state.lockedCoordinates])
    const updateOccupiedCoord = useCore(state => state.updateOccupiedCoord)
    useEffect(() => {
        if (!lockedCoordinates?.length) return
        lockedCoordinates.forEach(coordinate => {
            updateOccupiedCoord({ id: `${coordinate.x}_${coordinate.z}`, coordinates: coordinate }, 'add')
        })
        return () => {
            lockedCoordinates.forEach(coordinate => {
                updateOccupiedCoord({ id: `${coordinate.x}_${coordinate.z}`, coordinates: coordinate }, 'remove')
            })
        }
    }, [lockedCoordinates, updateOccupiedCoord])

    return null
}