import * as THREE from 'three'
import { Plane, Text } from "@react-three/drei"
import { RefObject, useEffect, useMemo, useRef, useState } from "react"
import { ThreeEvent } from '@react-three/fiber';
import SlotModel from 'views/Game/GamePlay/UserInterface3D/Slots/components/SlotModel'
import { memo } from 'react';
import { createBillboardMaterial } from 'views/Game/GamePlay/materials/createBillboardMaterial';
import { EventsType } from '../Slots';
import { ItemBox } from './ItemBox';

export interface BackpackItemMaps {
    COMMON: THREE.Texture
    IN_TRADE: THREE.Texture
    HOVER: THREE.Texture
}
interface Props {
    item: { qty: number; itemHash: string; itemAttributes: any; slot: number, inTrade: boolean }
    onClick?: (e: ThreeEvent<PointerEvent>) => void
    onPointerEnter?: (e: ThreeEvent<PointerEvent>) => void
    onPointerMove?: (e: ThreeEvent<PointerEvent>) => void
    onPointerLeave?: (e: ThreeEvent<PointerEvent>) => void
    onDoubleClick?: (e: ThreeEvent<PointerEvent>) => void
    onContextMenu?: (e: ThreeEvent<PointerEvent>) => void
    cellWidth: number
    cellHeight: number
    slots: RefObject<{[key: number]: THREE.Mesh}>
    enabledEvents: { transferTo: EventsType[], update: EventsType[], drop: EventsType[], doubleClick: EventsType[] },
    slotsId: string
    maps?: BackpackItemMaps
    itemBoxPlacement?: 'top' | 'bottom'
}

const BackpackItem = memo(function BackpackItem({ 
    item, 
    onClick, 
    onPointerEnter, 
    onPointerMove, 
    onPointerLeave, 
    onDoubleClick,
    onContextMenu,
    cellWidth,
    cellHeight,
    slots,
    enabledEvents: _enabledEvents,
    slotsId,
    itemBoxPlacement,
    maps
}: Props) {
    // console.log('[CPU CHECK]: Rerender <Backpack Item>')
    const [rerendered, rerender] = useState(false)
    useEffect(() => { setTimeout(() => rerender(true), 10) }, [])

    const itemPlaneRef = useRef<THREE.Mesh | null>(null)
    const itemRef = useRef<THREE.Mesh | null>(null)

    // Positioning
    const itemPlaneWidth = useMemo(() => cellWidth * item.itemAttributes.itemParameters.itemWidth, [item, cellWidth])
    const itemPlaneHeight = useMemo(() => cellHeight * item.itemAttributes.itemParameters.itemHeight, [item, cellHeight])

    const itemScale = useMemo(() => {
        return cellWidth * .8 * Math.max(item.itemAttributes.itemParameters.itemWidth, item.itemAttributes.itemParameters.itemHeight)
    }, [cellWidth, item])

    const itemPlanePosition = useMemo(() => {
        if (!slots.current) { return new THREE.Vector3(0, 0, 0) }
        const slotCell = slots.current[item.slot]
        if (!slotCell) { return new THREE.Vector3(0, 0, 0) }
        const slotRow = slotCell.parent
        const slotColumn = slotRow.parent
        const slotWrapper = slotColumn.parent
        const wrapper = slotWrapper.parent

        // Calc position based on all parents
        let x = slotCell.position.x + slotRow.position.x + slotColumn.position.x + slotWrapper.position.x + wrapper.position.x
        let y = slotCell.position.y + slotRow.position.y + slotColumn.position.y + slotWrapper.position.y + wrapper.position.y
        let z = slotCell.position.z + slotRow.position.z + slotColumn.position.z + slotWrapper.position.z + wrapper.position.z

        // Take into the account size of the element
        x += (item.itemAttributes.itemParameters.itemWidth - 1) * cellWidth / 2
        y -= (item.itemAttributes.itemParameters.itemHeight - 1) * cellHeight / 2

        return new THREE.Vector3(x, y, z)
    }, [ item, rerendered, slots])

    const billboardMaterial = useMemo(() => createBillboardMaterial(new THREE.MeshBasicMaterial({ transparent: true, depthTest: false })), [])

    const enabledEvents = useMemo(() => {
        const events = {}
        Object.keys(_enabledEvents).forEach(key => {
            events[key] = []
            _enabledEvents[key].forEach((e: EventsType) => {
                events[key].push({ type: e.type, id: e.id })
            })
        })
        return events
    }, [_enabledEvents])

    return (
        <Plane 
            name='backpack-item'
            ref={itemPlaneRef}
            position={itemPlanePosition} 
            userData={{ 
                currentPosition: itemPlanePosition, 
                item: item,
                type: 'backpack',
                enabledEvents,
                slotsId,
                maps: maps ? {
                    COMMON: maps.COMMON,
                    IN_TRADE: maps.IN_TRADE,
                    HOVER: maps.HOVER
                } : null,
            }}
            args={[itemPlaneWidth*1.3, itemPlaneHeight*1.3]}
            visible={rerendered}
        >
            { maps ? (
                <meshBasicMaterial map={maps.COMMON} transparent={true} opacity={item.inTrade ? .5 : 1} />
            ) : (
                <meshBasicMaterial visible={false} transparent={true} opacity={0} />
            ) }
            
            <Plane 
                name='backpack-item-events' 
                args={[itemPlaneWidth, itemPlaneHeight]} 
                visible={false} 
                onClick={!item.inTrade && onClick}
                onPointerMove={!item.inTrade &&onPointerMove}
                onPointerEnter={!item.inTrade &&onPointerEnter}
                onPointerLeave={onPointerLeave}
                onDoubleClick={!item.inTrade &&onDoubleClick}
                onContextMenu={!item.inTrade &&onContextMenu}
            >
            </Plane>
            <ItemBox position={[-itemPlaneWidth/2, itemBoxPlacement === 'bottom' ? itemPlaneHeight/2 : -itemPlaneHeight/2, 0]} placement={itemBoxPlacement} visible={!item.inTrade} item={item}/>
            <SlotModel visible={!item.inTrade} position={[0, 0, 100]} ref={itemRef} scale={[itemScale, itemScale, itemScale]} item={item} />
            {+item.qty > 1 ? (
                <Text 
                    visible={!item.inTrade}
                    position={[itemPlaneWidth/2 - 16, itemPlaneHeight/2 - 16, .001]}
                    color={0xFF8800} 
                    fillOpacity={1}
                    anchorX="center" 
                    anchorY="middle" 
                    fontSize={16}
                    material={billboardMaterial}
                >
                    { item.qty }
                </Text>
            ): null}
        </Plane>
    )
})

export default BackpackItem