import styled from "styled-components"
import { TextureBtn } from "../components/TextureBtn"
import { StyledText } from "../styles"

export const Indicator = () => {
    return (
        <StyledIndicator>
            <img src='/assets/party/player-party-color-map.png' width={207} height={80} alt="" />
            <div className="player-id">11</div>
            <StyledText className="name">Name</StyledText>
            <div className="close">
                <TextureBtn className="btn" texture='/assets/shared/close-button-color.png' width={16} height={16} />
            </div>
            <div className="indicators">
                <div className="player-icon">
                    <img src='/assets/party/player-icon.png' width={30} height={30} alt="" />
                </div>
                <div className="column">
                    <div className="health"><div></div></div>
                    <div className="mana"><div></div></div>
                </div>
            </div>
        </StyledIndicator>
    )
}

const StyledIndicator = styled.div`
    position: relative; 
    width: 207px;
    height: 80px;
    z-index: 1000;

    > img {
        position: absolute;
        top: 0; left: 0;    
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: contain;
    }

    .player-id {
        position: absolute;
        top: 10px; left: 10px;
        z-index: 1;
        text-align: center;
        text-shadow: 0px 0px 9px rgba(255, 200, 58, 0.75), 0px 0px 7.8px #FFC83A;
        font-family: "Acari Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background: var(--Radial, radial-gradient(26.67% 41.67% at 68% 50%, #FFEA2F 0%, #ECA34E 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .name {
        position: absolute;
        top: 12px; left: 40px;
        z-index: 1;
        font-size: 16px;
    }

    .close {
        position: absolute;
        top: 10px; right: 12px;
        z-index: 1;
        background: linear-gradient(180deg, #6D322C -16.67%, #3B0100 57%, #46150E 127.78%);
        border: 1px solid rgba(68, 68, 68, 0.25);
        box-shadow: 0px -2.908px 5.817px 0px rgba(255, 247, 226, 0.05) inset, 0px 2.908px 2.908px 0px rgba(255, 247, 226, 0.15) inset, 0px -3.857px 3.857px 0px rgba(79, 79, 79, 0.45) inset;
        width: 22px;
        height: 22px;
        pointer-events: all;
        .btn {
            position: absolute;
            top: 50%; left: 50%;
            transform: translate(-50%, -50%);
            width: 70%;
            height: 70%;
            object-fit: contain;
            z-index: 1;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 50%; left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .indicators {
        position: absolute;
        bottom: 15px; left: 10px;
        width: 100%;
        padding-right: 20px;
        z-index: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .column {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            flex: 1;
            gap: 5px;
        }

        .health, .mana {
            position: relative;
            width: 100%;
            height: 9px;
            border-bottom-right-radius: 24px;
            border-top-right-radius: 24px;
            border: 0.75px solid #2C2C2C;
            background: rgba(28, 29, 25, 0.55);

            > div {
                position: absolute;
                top: 0; left: 0;
                width: 100%;
                height: 100%;
                max-width: 30%;
                border-bottom-right-radius: 24px;
                border-top-right-radius: 24px;
            }
        }

        .health > div {
            background: radial-gradient(13141.76% 84.95% at 98.8% 100%, #DE0405 0%, #470100 99.5%);
            box-shadow: 0px 0px 22.5px 0px #700101, 0px 6px 6px 0px rgba(0, 0, 0, 0.25) inset;
            filter: blur(0.75px);
        }

        .mana > div {
            background: radial-gradient(13141.76% 84.95% at 98.8% 100%, #0059A3 0%, #002037 99.5%);
            box-shadow: 0px 0px 22.5px 0px #012D51, 0px 6px 6px 0px rgba(0, 0, 0, 0.25) inset;
            filter: blur(0.75px);
        }

        .player-icon {
            position: relative;
            width: 28px;
            height: 28px;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0; left: 0;
                transform: scale(1.5);
            }
        }
    }
`