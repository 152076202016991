import * as THREE from "three";
import React from "react";
import { Plane, Text } from "@react-three/drei";
import { createBillboardMaterial } from "../materials/createBillboardMaterial";
import { BackgroundPlane } from "./BackgroundPlane";
import { getMeshDimensions } from "../utils/getMeshDimensions";
import { useFrame } from "@react-three/fiber";

interface Props {
    name: string
    message: string
    offsetY: number
    backgroundColor?: number | string
}
const maxSymbols = 30
export const NameWithMessage = React.forwardRef(({ name, message: _message, offsetY, backgroundColor = 0x4C5D1A }: Props, outerRef: React.MutableRefObject<THREE.Group | null>) => {

    const textBillboardMaterial = React.useMemo(
        () => createBillboardMaterial(new THREE.MeshBasicMaterial()),
        []
    );
    const message = React.useMemo(() => {
        const m = _message || ''
        if (m.length > maxSymbols) {
            return m.slice(0, maxSymbols - 3) + '...'
        }
        return m
    }, [_message])
    const textRef = React.useRef<THREE.Mesh | null>(null)
    const bgRef = React.useRef<THREE.Mesh | null>(null)

    return (
        <group ref={outerRef}>
            <group position={[0, offsetY, 0]}>
                <BackgroundPlane
                    ref={bgRef}
                    color={backgroundColor}
                    target={textRef}
                    offsetX={0.1}
                    offsetY={0.1}
                    opacity={0.4}
                    anchorY="middle"
                />
                {/* <NamePlane
                    color={0x5D1A1A}
                    target={bgRef}
                    height={0.2}
                    message={message}
                    opacity={0.3}
                /> */}
                <Text
                    ref={textRef}
                    color={0xffffff}
                    fillOpacity={1}
                    anchorX="center"
                    anchorY="middle"
                    fontSize={0.15}
                    lineHeight={1}
                    whiteSpace='overflowWrap'
                    overflowWrap="break-word"
                    maxWidth={1}
                    material={textBillboardMaterial}
                    renderOrder={1}
                >
                    {`${name}\n${message}`}
                </Text>
            </group>
        </group>
    )
})

NameWithMessage.displayName = 'NameWithMessage'

interface NamePlaneProps {
    opacity?: number
    color?: number | string
    target: React.MutableRefObject<THREE.Mesh>
    height: number
    message: string
}
const NamePlane = ({ opacity = 0.8, color = 0xFFFFFF, height, target, message }: NamePlaneProps) => {
    const ref = React.useRef<THREE.Mesh | null>(null)
    const backgroundMaterial = React.useMemo(
        () =>
            createBillboardMaterial(
                new THREE.MeshBasicMaterial({
                    transparent: true,
                    color,
                    opacity,
                    depthWrite: false,
                    side: THREE.DoubleSide
                })
            ),
        [color, opacity]
    )
    useFrame(() => {
        if (target.current && ref.current) {
            const { width: tWidth, height: tHeight } = getMeshDimensions(target.current)
            if (message) {
                ref.current.scale.set(tWidth, height, 1)
                ref.current.position.y = tHeight - height/2
            } else {
                ref.current.scale.set(tWidth, tHeight, 1)
                ref.current.position.y = 0
            }
        }
    })

    return (
        <Plane
            ref={ref}
            material={backgroundMaterial}
            args={[1, 1, 1]}
        />
    )
}