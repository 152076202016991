import { ButtonHTMLAttributes } from "react"
import styled from "styled-components"

interface TextureBtnProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    texture: string
    width?: number
    height?: number
}

const StyledTextureBtn = styled.button`
    position: relative;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
        img {
            filter: contrast(200%);
        }
    }
`

export const TextureBtn = ({ 
    texture, 
    width = 24, 
    height = 24, 
    ...props 
}: TextureBtnProps) => {
    return (
        <StyledTextureBtn {...props}>
            <img 
                src={texture} 
                alt="" 
                width={width} 
                height={height} 
                style={{
                    objectFit: 'contain'
                }}
            />
        </StyledTextureBtn>
    )
}