import { Plane, useTexture } from "@react-three/drei"
import { ButtonPlane } from "../components/ButtonPlane"
import { useCloud } from "EventCloud/useCloud"
import { useUi } from "../useUi"
import { useCallback } from "react"

interface Props {
    args?: [number, number, number]
    position?: [number, number, number]
}
export const WingLeft = ({
    args = [181, 62, 1],
    position,
}: Props) => {

    const [bgMap] = useTexture(['/assets/bottom-menu/quickmenu-wing-left-bg-color.png'])
    const [menuMap, menuHoverMap] = useTexture(['/assets/bottom-menu/btn-menu-color.png', '/assets/bottom-menu/btn-menu-color-hover.png'])

    const requestVault = useCloud(state => state.requestVault)
    const [toggleBackpack] = useUi(state => [state.toggle])
    const [toggleVault, openVault] = useUi(state => [state.toggleVault, state.openVault])
    const [closeShop] = useUi(state => [state.closeShop])

    const handleVault = useCallback(() => {
        // const $state = useUi.getState()
        // if (!$state.isOpenedVault) {
        //     requestVault()
        // }
        // if ($state.isOpened && !$state.isOpenedVault) {
        //     openVault()
        //     closeShop()
        //     return
        // }

        // toggleVault()
        // toggleBackpack()
        // closeShop()
    }, [requestVault, toggleVault])


    return (
        <Plane position={position} args={args}>
            <meshBasicMaterial map={bgMap} transparent={true} />
            <ButtonPlane
                name="menu"
                args={[50, 50, 1]}
                position={[-10, 0, 1]}
                colorMap={menuMap}
                hoverColorMap={menuHoverMap}
                onClick={handleVault}
            />
        </Plane>
    )
}
