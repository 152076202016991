import { Environment as Env, Plane, useGLTF, useTexture } from "@react-three/drei"
import React from "react"
import { RepeatWrapping } from "three"

export const Environment = () => {
    const texture = useTexture('menu/sand.jpg')
    const { scene } = useGLTF('menu/background.glb')

    React.useMemo(() => {
        scene.traverse(object => {
            object.receiveShadow = true
            object.castShadow = true
        })
    }, [scene])

    texture.wrapS = RepeatWrapping
    texture.wrapT = RepeatWrapping
    texture.repeat.set(20, 20)

    return (
        <>
            <Plane
                receiveShadow
                rotation-x={Math.PI / -2}
                args={[30, 30, 1]}
            >
                <meshPhongMaterial map={texture} />
            </Plane>
            <primitive object={scene} scale={0.3} position-z={-2} />
            <Env files='christmas_photo_studio_07_1k.hdr' environmentIntensity={.5} />
        </>
    )
}