import React from "react"

import * as THREE from "three"
import { useFrame, useThree } from "@react-three/fiber"
import { Box, useTexture } from "@react-three/drei"

import { angleToVector } from "../utils/angleToVector"

import { useCore } from "../useCore"
import { useUi } from "views/Game/GamePlay/UserInterface3D/useUi"
import { useFighter } from "views/Game/GamePlay/Fighter/useFighter"
import { useCloud } from "EventCloud/useCloud"
import { useControls } from "./useControls"
import { MapBuilder } from "./MapBuilder"

const Controls = React.memo(function Controls() {
    const eventsNode = useUi(state => state.eventsNode)
    const move = useFighter(state => state.move)
    const [isOccupiedCoordinate] = useCore(state => [state.isOccupiedCoordinate])
    const canvas = useThree(state => state.gl.domElement)
    const isHolding = React.useRef(false)
    const isRightClick = React.useRef(false)
    const updateFighterDirection = useCloud(state => state.updateFighterDirection)
    const [calcDirection, setPointerCoordinate, setDirection] = useControls(state => [state.calcDirection, state.setPointerCoordinate, state.setDirection])

    const intersectionPlane = React.useRef(new THREE.Plane(new THREE.Vector3(0, 1, 0), 0))
    const intersection = React.useRef(new THREE.Vector3())

    const [worldCoordToMatrix, matrixCoordToWorld] = useCore(state => [state.worldCoordToMatrix, state.matrixCoordToWorld])
    const boxRef = React.useRef<THREE.Mesh>()
    const [alphaTargetMap, alphaTargetOutlineMap] = useTexture(['assets/target.png', 'assets/target-outline.png'])

    useFrame(({ raycaster, camera, pointer }) => {
        // if (useCore.getState().hoveredItems.length) { return }
        // if (useUi.getState().isOpened) { return }

        raycaster.setFromCamera(pointer, camera)
        const intersected = raycaster.ray.intersectPlane(intersectionPlane.current, intersection.current)
        if (intersected) { setPointerCoordinate(intersected) }

        if (isHolding.current && !isRightClick.current) {
            move(useControls.getState().pointerCoordinate)
        }

        updatePointerHelper()
    })

    React.useEffect(() => {
        if (!eventsNode.current) { return }
        eventsNode.current.addEventListener("mousedown", mouseDown)
        eventsNode.current.addEventListener("mousemove", mouseMove)
        eventsNode.current.addEventListener("mouseup", mouseUp)
        eventsNode.current.addEventListener("contextmenu", contextMenu)

        return () => {
            eventsNode.current.removeEventListener("mousedown", mouseDown)
            eventsNode.current.removeEventListener("mousemove", mouseMove)
            eventsNode.current.removeEventListener("mouseup", mouseUp)
            eventsNode.current.removeEventListener("contextmenu", contextMenu)
        }
    }, [eventsNode.current])
    const timeout = React.useRef<any>()
    const mouseDown = React.useCallback((e: MouseEvent) => {
        if (isRightClick.current) { return }
        if (e.target !== canvas) { return }
        if (useCore.getState().hoveredItems.length) { return }
        if (useCore.getState().hoveredUi.length) { return }

        // Dev highlight
        boxRef.current.scale.set(0.5, 0.5, 0.5)
        // 

        isHolding.current = true
    }, [])
    const mouseMove = React.useCallback(() => {
        if (useFighter.getState().isMoving) { return }
        // if (useCore.getState().hoveredItems.length) { return }
        const direction = calcDirection()
        if (!direction) { return }
        setDirection(direction)
        const { x, z } = angleToVector(direction)
        updateFighterDirection({ dx: x, dz: z })
    }, [])
    const updatePointerHelper = React.useCallback(() => {
        const coordinate = useControls.getState().pointerCoordinate
        if (!coordinate) { return }
        const matrixPointerCoordinate = worldCoordToMatrix(coordinate)
        const pointerCoordinate = matrixCoordToWorld(matrixPointerCoordinate)
        const color = isOccupiedCoordinate(matrixPointerCoordinate) ? 0xFF0000 : 0x00FF00
        const isItems = !!useCore.getState().hoveredItems.length || !!useCore.getState().hoveredUi.length
        boxRef.current.position.set(pointerCoordinate.x, 0.001, pointerCoordinate.z)
        if (isItems) {
            (boxRef.current.material as THREE.MeshBasicMaterial).color.setHex(0x000000)
        } else {
            (boxRef.current.material as THREE.MeshBasicMaterial).color.setHex(color)
        }
    }, [])

    const mouseUp = React.useCallback(() => {
        clearTimeout(timeout.current)
        isHolding.current = false;
        isRightClick.current = false
        timeout.current = setTimeout(() => {
            boxRef.current.scale.set(1, 1, 1)
        }, 300)
    }, [])
    const contextMenu = React.useCallback(() => { isRightClick.current = true }, [])


    return (
        <>
            <group>
                <Box ref={boxRef} args={[1, .01, 1]}>
                    <meshBasicMaterial alphaMap={alphaTargetMap} transparent={true} />
                    <Box args={[1, .01, 1]}>
                        <meshBasicMaterial alphaMap={alphaTargetOutlineMap} depthTest={false} transparent={true} opacity={2} />
                    </Box>
                </Box>
            </group>
            <MapBuilder />
        </>
    )

})

export default Controls

