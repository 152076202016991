import * as THREE from 'three'
import React, { useCallback } from "react"

import type { Fighter } from 'interfaces/fighter.interface'
import type { WorldCoordinate } from 'interfaces/coordinate.interface'

import Tween from 'views/Game/GamePlay/utils/tween/tween'

import { useCore } from '../../useCore'

import { useActions } from './hooks/useActions'
import { usePointerEvents } from './hooks/usePointerEvents'
import { useEquipmentChange } from '../hooks/useEquipmentChange'

import { useEvent } from 'views/Game/GamePlay/hooks/useEvent'
import { useSkillEffects } from '../hooks/useSkillEffects/useSkillEffects'

import { getMoveDuration } from 'views/Game/GamePlay/utils/getMoveDuration'
import { isEqualCoord } from 'views/Game/GamePlay/utils/isEqualCoord'

import { useUi } from 'views/Game/GamePlay/UserInterface3D/useUi'
import { PersonalPointLight } from '../../Light'
import { usePlayerSkin } from '../hooks/usePlayerSkin/usePlayerSkin'
import { ObjectElements } from '../../components/ObjectElements'
import { useSpawn } from '../hooks/useSpawn'
import { useDeathEventSound } from 'core/SoundManager/SoundEvents'

interface Props { fighter: Fighter }
const Player = React.memo(function OtherFighter({ fighter }: Props) {
    // Used to set spawn coord without tweening from x:0,z:0
    const {
        model,
        animations,
        uniforms,
        updatePoses
    } = usePlayerSkin(fighter)

    const spawned = React.useRef<boolean>(false)
    const matrixCoordToWorld = useCore(state => state.matrixCoordToWorld)
    const fighterRef = React.useRef<THREE.Mesh | null>(null)
    const elementsRef = React.useRef<THREE.Group | null>(null)
    const [playSpawn] = useSpawn(uniforms)


    // const isMoving = React.useRef<boolean>(false)
    const [isMoving, setIsMoving] = React.useState(false)
    const { setAction, action } = useActions(animations, fighterRef)

    const {
        nameColor,
        handlePointerEnter,
        handlePointerLeave,
        handleLeftClick,
    } = usePointerEvents(fighter)

    const handleRightClick = useCallback((e: PointerEvent) => {
        const $ui = useUi.getState()
        $ui.toggleContextMenu()
        $ui.setContextMenuFighter(fighter)
        $ui.setContextMenuCoords({ x: e.screenX - window.innerWidth/2, y: -(e.screenY - window.innerHeight/2) })
    }, [])

    const { effects, play: playSkillEffect } = useSkillEffects()
    const playDeathSound = useDeathEventSound(fighter)

    // Fill changed fighter properties
    React.useEffect(() => {
        if (!fighterRef.current) { return }
        // console.log(`[NPC]: npc with id '${npc?.id}' updated`, npc)
        if (fighter?.isDead) {
            spawned.current = false
            setAction('die', fighter)
            playDeathSound()
            // handlePointerLeave()
            return 
        }
        if (fighter?.coordinates) {
            if (!spawned.current) {
                spawned.current = true
                playSpawn()
                setPosition(matrixCoordToWorld(fighter?.coordinates), fighterRef.current)
                setAction('stand', fighter)
            } 
            move(matrixCoordToWorld(fighter?.coordinates), fighterRef.current)
        }
        if (fighter?.direction) {
            fighterRef.current.rotation.y = Math.atan2(fighter.direction.dx, fighter.direction.dz)
            elementsRef.current.rotation.y = -fighterRef.current.rotation.y
        }
    }, [ fighter ])

    const actionTimeout = React.useRef<any>(0)
    const move = React.useCallback((to: WorldCoordinate, ref: THREE.Mesh) => {
        if (isMoving) { return }

        if (isEqualCoord(ref.position, to)) { return }

        clearTimeout(actionTimeout.current)
        setAction('run', fighter)
        // isMoving.current = true
        setIsMoving(true)
        const current = { x: ref.position.x, z: ref.position.z }
        Tween.to(current, to,
            {
                duration: getMoveDuration(fighter.movementSpeed, current, to),
                onChange: (state: { value: WorldCoordinate }) => void setPosition(state.value, ref),
                onComplete: () =>  { 
                    // isMoving.current = false
                    setIsMoving(false)
                    actionTimeout.current = setTimeout(() => { action.current.includes('run') && setAction('stand', fighter) }, 50) 
                } 
            }
        )
    }, [isMoving])
    const setPosition = React.useCallback((to: WorldCoordinate, ref: THREE.Mesh) => {
        ref.position.x = to.x
        ref.position.z = to.z
    }, [])

    // Change Animations On Equipment Change
    useEquipmentChange(fighter, (changes) => {
        if (changes.changedSlots.includes(6) || changes.changedSlots.includes(7)) {
            if (action.current.includes('stand')) { setAction('stand', fighter) }
        }
    })

    // Animate Skills on Server Event
    useEvent(fighter, 'skill', (event, removeEvent) => {
        playSkillEffect(event)
        setAction('attack', fighter)
        removeEvent(event)
    })

    return (
        <group name="other-fighter">
            <group ref={fighterRef as any} scale={1}>
                <primitive object={model} />
                <ObjectElements 
                    ref={elementsRef}
                    fighter={fighter}
                    heatBox
                    heatBoxEvents={{
                        onPointerMove: handlePointerEnter,
                        onPointerLeave: handlePointerLeave,
                        onPointerDown: handleLeftClick,
                        onContextMenu: handleRightClick
                    }}
                    nameWithMessage
                />
                {effects.map((_, i) => <primitive object={_} key={i} />)}
            </group>
            {/* <PersonalPointLight fighterNode={fighterRef} /> */}
        </group>
    )
})

export default Player