import { useFrame } from "@react-three/fiber"

import React, { useEffect, useMemo } from "react"
import { useControls as useLevaControls } from "leva"
import { useControls } from "./useControls"
import * as THREE from "three"
import { Text } from "@react-three/drei"
import { useCloud } from "EventCloud/useCloud"
import { useCore } from "../useCore"

export const MapBuilder = () => {
    const ref = React.useRef<THREE.Mesh>()
    const [rerendered, rerender] = React.useState(0)
    const coordinateRef = React.useRef<{ x: number, z: number }>()
    const { enable } = useLevaControls('Map Builder', { enable: false })
    const mapAddLockedCoord = useCloud(state => state.mapAddLockedCoord)
    const showRef = React.useRef(enable)
    useEffect(() => {
        showRef.current = enable
    }, [enable])
    const coordinate = useMemo(() => {
        const pointerCoordinate = useControls.getState().pointerCoordinate
        if (!pointerCoordinate) return null
        coordinateRef.current = { x: Math.round(pointerCoordinate.x), z: Math.round(pointerCoordinate.z) }
        return coordinateRef.current
    }, [rerendered, enable])
    useFrame(() => {
        if (!showRef.current) return
        if (!coordinateRef.current) return
        const pointerCoordinate = useControls.getState().pointerCoordinate
        const coordinate = { x: Math.round(pointerCoordinate.x), z: Math.round(pointerCoordinate.z) }
        if (coordinate.x !== coordinateRef.current.x || coordinate.z !== coordinateRef.current.z) {
            rerender(prev => prev + 1)
        }
    })
    const timeoutRef = React.useRef<any>()
    const allowRef = React.useRef(true)
    const handleContextMenu = () => {
        if (!allowRef.current) return
        allowRef.current = false
        timeoutRef.current = setTimeout(() => {
            allowRef.current = true
        }, 300)
        // console.log('contextmenu', coordinate)
        mapAddLockedCoord(coordinate)
    }
    if (!enable) return null
    if (!coordinate) return null
    return (
        <>
            <LockedCoordinates />
            <group
                onContextMenu={handleContextMenu}
            >
                <mesh ref={ref} rotation-x={-Math.PI / 2} position={[coordinate.x, 0.01, coordinate.z]}>
                    <planeGeometry args={[1, 1]} />
                    <meshBasicMaterial color="yellow" depthWrite={false} depthTest={false} />
                    <Text renderOrder={999} fontSize={0.2} position-y={0} rotation-z={-Math.PI / 4}>
                        <meshBasicMaterial color="black" depthWrite={false} depthTest={false} />
                        {`x: ${coordinate.x}, z: ${coordinate.z}`}
                    </Text>
                </mesh>
            </group>
        </>
    )
}


function LockedCoordinates() {
    const [lockedCoordinates] = useCloud(state => [state.lockedCoordinates])

    if (!lockedCoordinates?.length) return null

    return <group>
        {lockedCoordinates.map((coordinate, idx) => (
            <mesh key={idx} rotation-x={-Math.PI / 2} position={[coordinate.x, 0.01, coordinate.z]}>
                <planeGeometry args={[1, 1]} />
                <meshBasicMaterial color="red" depthWrite={false} depthTest={false} />
                <Text renderOrder={9999} color="white" fontSize={0.2} position-y={0} rotation-z={-Math.PI / 4}>
                    <meshBasicMaterial color="white" depthWrite={false} depthTest={false} />
                    {`x: ${coordinate.x}, z: ${coordinate.z}`}
                </Text>
            </mesh>
        ))}
    </group>
}