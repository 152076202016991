import * as THREE from 'three'
import { useEffect, useRef, useMemo, memo, useCallback } from "react"
import Tween from '../utils/tween/tween'
import { Coordinate } from "interfaces/coordinate.interface"
import { getMoveDuration } from '../utils/getMoveDuration'
import type { Fighter } from 'interfaces/fighter.interface'
import { getShaderedModel } from '../Models/utils/getShaderedModel'
import { useCore } from '../useCore'
import { isEqualCoord } from 'views/Game/GamePlay/utils/isEqualCoord'

import { useActions } from './hooks/useActions'
import { useEvent } from 'views/Game/GamePlay/hooks/useEvent'
import { usePointerEvents } from './hooks/usePointerEvents'
import { useFrame } from '@react-three/fiber'

import { useUi } from 'views/Game/GamePlay/UserInterface3D/useUi'
import { ObjectElements } from '../components/ObjectElements'
import { useActionSound, useDeathEventSound } from 'core/SoundManager/SoundEvents'
import { useCloud } from 'EventCloud/useCloud'
import { useSpawn } from '../Fighter/hooks/useSpawn'


interface Props { npc: Fighter }
const Npc = memo(function Npc({ npc }: Props) {
    // Used to set spawn coord without tweening from x:0,z:0
    const spawned = useRef<boolean>(false)
    const dead = useRef<boolean>(false)
    const [matrixCoordToWorld, setSceneObject] = useCore(state => [state.matrixCoordToWorld, state.setSceneObject])
    const _uniforms = useRef({ uTime: { value: 0 } })
    const npcRef = useRef<THREE.Mesh | null>(null)
    const elementsRef = useRef<THREE.Group | null>(null)
    const { scene: model, animations, uniforms } = useMemo(() => getShaderedModel('npc_'+npc.name, npc), [])
    useMemo(() => { model.traverse(_ => { _.castShadow = _.receiveShadow = true }) }, [model])
    const isMoving = useRef<boolean>(false)
    const { setAction, action } = useActions(animations, npcRef)
    const [playSpawn] = useSpawn(uniforms)
    const playDeathSound = useDeathEventSound(npc)

    const {
        nameColor,
        handlePointerEnter,
        handlePointerLeave,
        handleLeftClick,
        handleRightClick,
        hovered
    } = usePointerEvents(npc, model)
    // Fill changed npc properties
    useEffect(() => {
        if (!npcRef.current) { return }

        if (npc?.isDead) {
            if (!dead.current) {
                spawned.current = false
                dead.current = true
                setAction('die')
                playDeathSound()
                handlePointerLeave()
            }
            return
        }

        if (npc?.coordinates) {
            if (!spawned.current) {
                playSpawn()
                spawned.current = true
                dead.current = false
                setNpcPosition(matrixCoordToWorld(npc?.coordinates), npcRef.current)
                setAction('stand')
                return
            }
            moveNpc(matrixCoordToWorld(npc?.coordinates), npcRef.current)
        }
        if (npc?.direction) {
            npcRef.current.rotation.y = Math.atan2(npc.direction.dx, npc.direction.dz)
            elementsRef.current.rotation.y = -npcRef.current.rotation.y
        }
    }, [npc])

    const moveNpc = useCallback((to: Coordinate, ref: THREE.Mesh) => {
        if (isMoving.current) { return }

        if (isEqualCoord(ref.position, to)) { return }

        // Used over here instead isMoving useEffect cuz it rms a little delay which looks weird
        setAction('run')
        // 
        const current = { x: ref.position.x, z: ref.position.z }
        // Check if the distance more than 2 squares, what means we no need animation, just immidiate move
        if (Math.abs(current.x - to.x) > 2 || Math.abs(current.z - to.z) > 2) {
            setNpcPosition(to, ref)
            return
        }

        isMoving.current = true

        Tween.to(current, to,
            {
                duration: getMoveDuration(npc.movementSpeed, current, to),
                onChange: (state: { value: Coordinate }) => void setNpcPosition(state.value, ref),
                onComplete: () => void (isMoving.current = false),
            }
        )
    }, [])
    const setNpcPosition = useCallback((to: Coordinate, ref: THREE.Mesh) => {
        ref.position.x = to.x
        ref.position.z = to.z
    }, [])

    // Add delay to prevent animation from stop between different chained tweens
    const timeout = useRef<any>(0)
    useEffect(() => {
        clearTimeout(timeout.current)
        if (dead.current) { return }
        if (isMoving.current) return
        timeout.current = setTimeout(() => void setAction('stand'), 50)
    }, [isMoving.current])


    useEvent(npc, 'skill', (event, removeEvent) => {
        if (dead.current) { return }
        setAction('attack')
        removeEvent(event)
    })

    useActionSound(npc, action)

    // Save ref to object to store & rm on unmount
    // useEffect(() => {
    //     if (dead.current) { return }
    //     if (npcRef.current) {
    //         setSceneObject(npc.id, npcRef.current, 'add')
    //     }
    //     return () => {
    //         setSceneObject(npc.id, npcRef.current, 'remove')
    //     }
    // }, [npcRef.current, npc])


    // const heatbox = useRef(getHeatbox(model))
    useFrame(({ clock }) => {
        _uniforms.current.uTime.value = clock.getElapsedTime()
        if (hovered.current) {
            // heatbox.current && (heatbox.current.visible = true)
            if (useUi.getState().pressedKeys.has('metaleft') || useUi.getState().pressedKeys.has('altleft')) {
                handlePointerLeave()
                return
            }
            return
        }
    })

    // Handle heatbox events
    const handleHeatboxEvents = useMemo(() => {
        if (npc.canFight) {
            return {
                onPointerMove: handlePointerEnter,
                onPointerLeave: handlePointerLeave,
                onPointerDown: handleLeftClick,
                onContextMenu: handleRightClick
            }
        }
        if (npc.name === 'Vault') {
            return {
                onPointerMove: handlePointerEnter,
                onPointerLeave: handlePointerLeave,
                onPointerDown: () => {
                    const $ui = useUi.getState()
                    useCloud.getState().requestVault()
                    $ui.openVault()
                    $ui.open()
                },
            }
        }
        if (npc.name === 'Potion Girl') {
            return {
                onPointerMove: handlePointerEnter,
                onPointerLeave: handlePointerLeave,
                onPointerDown: () => {
                    const $ui = useUi.getState()
                    useCloud.getState().requestShop()
                    $ui.openShop()
                    $ui.open()
                },
            }
        }
        return undefined
    }, [npc, handlePointerEnter, handlePointerLeave, handleLeftClick, handleRightClick])

    return (
        <group
            name='npc'
        >
            <primitive
                ref={npcRef}
                object={model}
            >
                <ObjectElements
                    ref={elementsRef}
                    fighter={npc}
                    heatBox
                    // makeHeatBoxVisible
                    heatBoxEvents={handleHeatboxEvents}
                    name={!npc.isDead}
                    nameBackgroundColor={npc.canFight ? 0x5D1A1A : 0x4C5D1A}
                    healthBar={npc.canFight && !npc.isDead}
                />
            </primitive>
        </group>
    )
})

export default Npc