export const npc = [
    {
        name: 'npc_spider',
        url: 'models/npc/spider.glb'
    },
    {
        name: 'npc_sproat_dragon',
        url: 'models/npc/sproat_dragon.glb'
    },
    {
        name: 'npc_bull_fighter',
        url: 'models/npc/bull_fighter.glb'
    },
    {
        name: 'npc_elite_bull_fighter',
        url: 'models/npc/elite_bull_fighter.glb'
    },
    {
        name: 'npc_vault',
        url: 'models/npc/vault.glb'
    },
    {
        name: 'npc_potion_girl',
        url: 'models/npc/potion_girl.glb'
    },
]