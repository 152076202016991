import React from "react"

import * as THREE from 'three'
import { useTexture } from "@react-three/drei"

import { getShaderedModel } from "../../Models/utils/getShaderedModel"
import type { ItemDroppedEvent } from "interfaces/item.interface"

export const useShaderedItem = (item: ItemDroppedEvent) => {

    const { map } = useTexture({ map: 'assets/notexture.png' })

    const [model, uniforms] = React.useMemo(() => {
        const gltf = getShaderedModel('inventory '+item.item.name, item)

        if (!gltf?.scene) {
            return [
                new THREE.Mesh(
                    new THREE.BoxGeometry(+item.item.itemParameters.itemWidth / 4 || .5, +item.item.itemParameters.itemHeight / 4 || .5, +item.item.itemParameters.itemWidth / 4 || .5),
                    new THREE.MeshStandardMaterial({ color: 'pink', map })
                ),
                {}
            ]
        }
        return [gltf.scene, gltf.uniforms]
    }, [item, map])

    return { model, uniforms }
}