import { Plane, useTexture } from "@react-three/drei"
import { Aling } from "../components/Align"
import { useRef } from "react"
import { useCloud } from "EventCloud/useCloud"
import { useUi } from "../useUi"
import { Slots } from "../Slots/Slots"
import { useBackpackItemMaps, useBackpackItemMaps2, useBackpackSlotsMaps, useEquipmentSlotsMaps } from "./useBackpackSlotsMaps"
import { UiText } from "../components/UiText"
import { CloseButton } from "../components/CloseButton"
import * as THREE from 'three'
import { InventorySlot } from "interfaces/inventory.interface"
import { useBackpackEventSound } from "core/SoundManager/SoundEvents"

export const Backpack = () => {
    const [backpack, equipment] = useCloud(state => [state.backpack, state.equipment])
    const [isOpenedBackpack, closeBackpack] = useUi(state => [state.isOpened, state.close])

    const [bgMap] = useTexture(['/assets/backpack-menu/backpack-bg-color.png'])
    const relatedNode = useRef<THREE.Group>()
    const relatedNode2 = useRef<THREE.Mesh>()

    const [playEquipSound, playUnequipSound, playUpgradeSound] = useBackpackEventSound()

    // TODO: change location for handler
    const [updateBackpackItemPosition, dropBackpackItem, unequipBackpackItem, equipBackpackItem] = useCloud(state => 
        [state.updateItemBackpackPosition, state.dropBackpackItem, state.unequipBackpackItem, state.equipBackpackItem]
    )
    const [consumeBackpackItem] = useCloud(state => [state.consumeBackpackItem])
    // Events for dropping Jewels
    const [upgradeItemLevel, upgradeItemOption] = useCloud(state => [state.upgradeItemLevel, state.upgradeItemOption])
    const handleDropOnEvent = (item: InventorySlot, dropOnItem: InventorySlot) => {
        if (item.itemAttributes.itemAttributes.isJewel) {
            if (['sefirah of haya'].includes(item.itemAttributes.name.toLowerCase())) {
                if (['armour', 'helm', 'boots', 'pants', 'gloves', 'ring', 'pendant', 'wings'].includes(dropOnItem.itemAttributes.itemParameters.type)) {
                    upgradeItemOption(dropOnItem.itemHash, item.itemHash)
                    playUpgradeSound()
                }
                return
            }
            if (['sefirah of ruach', 'sefirah of neshamah'].includes(item.itemAttributes.name.toLowerCase())) {
                if (['armour', 'helm', 'boots', 'pants', 'gloves', 'ring', 'pendant', 'wings'].includes(dropOnItem.itemAttributes.itemParameters.type)) {
                    upgradeItemLevel(dropOnItem.itemHash, item.itemHash)
                    playUpgradeSound()
                }
                return
            }
        }
    }


    // Equipment Events
    const [dropEquippedItem] = useCloud(state => [state.dropEquippedItem])

    // Vault Events
    const [moveItemFromBackpackToVault] = useCloud(state => [state.moveItemFromBackpackToVault])
    const [unequipVaultItem] = useCloud(state => [state.unequipVaultItem])

    // Trade Events
    const [addTradeItem, addTradeItemToPosition] = useCloud(state => [state.addTradeItem, state.addTradeItemToPosition])

    const slotsMaps = useBackpackSlotsMaps()
    const bpItemMaps = useBackpackItemMaps()
    const bgItemsMaps2 = useBackpackItemMaps2()
    const slotsEqMaps = useEquipmentSlotsMaps()

    const equipmentProps = {
        type: 'equipment',
        enableRender: isOpenedBackpack,
        events: [
            { id: 'ID_TRADE_MINE', type: 'transferTo', handler: (props) => addTradeItemToPosition(props.item.itemHash, props.position) },
            { id: 'ID_BACKPACK', type: 'transferTo', handler: (props) => { unequipBackpackItem(props.item.itemHash, props.position); playUnequipSound() } },
            { id: 'ID_VAULT', type: 'transferTo', handler: (props) => { unequipVaultItem(props.item.itemHash, props.position); playUnequipSound() } },
            { id: '', type: 'drop', handler: (props) => { dropEquippedItem(props.item.itemHash, props.coordinate); } },
            { id: '', type: 'rightClick', handler: (props) => useUi.getState().isOpenedTrade && addTradeItem(props.item.itemHash) }
        ],
        relatedNodes: [relatedNode, relatedNode2 as any],
        itemMaps: bgItemsMaps2,
    }
    const getEquipmentSlotProps = (slot: number) => {
        return {
            items: equipment.items[slot] ? { [slot]: equipment.items[slot] } : {},
            grid: { [slot]: equipment.is_equipped[slot] }
        }
    }


    if (!equipment || !backpack) return null
    return (
        <Aling visible={isOpenedBackpack} ref={relatedNode} alignX='right' hoverId="backpackAlign">
            <Plane position={[-240, 40, 0]} args={[420, 826, 1]} ref={relatedNode2}>
                <meshBasicMaterial map={bgMap} transparent={true} />
                <CloseButton 
                    name="backpack"
                    position={[140, 345, 10]}
                    onClick={closeBackpack}
                />
                <UiText position={[0, 345, 10]} color={'#d48932'}>Inventory</UiText>
                <UiText anchorX="right" position={[110, -352, 10]} color={'#dc801f'}>{ backpack.gold.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</UiText>
                <group position-z={1}>
                    <group name="equipment">
                        {/* @ts-expect-error */}
                        <Slots
                            id='ID_EQUIPMENT_NECKLES'
                            cellWidth={74}
                            cellHeight={40}
                            position={[-150, 300, 0]}
                            maps={slotsEqMaps.neckles}
                            {...getEquipmentSlotProps(10)}
                            {...equipmentProps}
                        />
                        {/* @ts-expect-error */}
                        <Slots
                            id='ID_EQUIPMENT_HELMET'
                            cellWidth={74}
                            cellHeight={74}
                            position={[-70, 300, 0]}
                            maps={slotsEqMaps.helmet}
                            {...getEquipmentSlotProps(1)}
                            {...equipmentProps}
                        />
                        {/* @ts-expect-error */}
                        <Slots
                            id='ID_EQUIPMENT_WINGS'
                            cellWidth={158}
                            cellHeight={74}
                            position={[10, 300, 0]}
                            maps={slotsEqMaps.wings}
                            {...getEquipmentSlotProps(11)}
                            {...equipmentProps}
                        />
                        {/* @ts-expect-error */}
                        <Slots
                            id='ID_EQUIPMENT_SWORD'
                            cellWidth={74}
                            cellHeight={105}
                            position={[-150, 210, 0]}
                            maps={slotsEqMaps.sword}
                            {...getEquipmentSlotProps(7)}
                            {...equipmentProps}
                        />
                        {/* @ts-expect-error */}
                        <Slots
                            id='ID_EQUIPMENT_ARMOUR'
                            cellWidth={74}
                            cellHeight={105}
                            position={[-70, 210, 0]}
                            maps={slotsEqMaps.armour}
                            {...getEquipmentSlotProps(2)}
                            {...equipmentProps}
                        />
                        {/* @ts-expect-error */}
                        <Slots
                            id='ID_EQUIPMENT_SHIELD'
                            cellWidth={74}
                            cellHeight={105}
                            position={[10, 210, 0]}
                            maps={slotsEqMaps.shield}
                            {...getEquipmentSlotProps(6)}
                            {...equipmentProps}
                        />
                        {/* @ts-expect-error */}
                        <Slots
                            id='ID_EQUIPMENT_GLOVES'
                            cellWidth={74}
                            cellHeight={74}
                            position={[90, 210, 0]}
                            maps={slotsEqMaps.gloves}
                            {...getEquipmentSlotProps(4)}
                            {...equipmentProps}
                        />
                        {/* @ts-expect-error */}
                        <Slots
                            id='ID_EQUIPMENT_RING_1'
                            cellWidth={38}
                            cellHeight={38}
                            position={[-130, 90, 0]}
                            maps={slotsEqMaps.ring}
                            {...getEquipmentSlotProps(8)}
                            {...equipmentProps}
                        />
                        {/* @ts-expect-error */}
                        <Slots
                            id='ID_EQUIPMENT_PANTS'
                            cellWidth={74}
                            cellHeight={74}
                            position={[-70, 90, 0]}
                            maps={slotsEqMaps.pants}
                            {...getEquipmentSlotProps(3)}
                            {...equipmentProps}
                        />
                        {/* @ts-expect-error */}
                        <Slots
                            id='ID_EQUIPMENT_BOOTS'
                            cellWidth={74}
                            cellHeight={74}
                            position={[10, 90, 0]}
                            maps={slotsEqMaps.boots}
                            {...getEquipmentSlotProps(5)}
                            {...equipmentProps}
                        />
                        {/* @ts-expect-error */}
                        <Slots
                            id='ID_EQUIPMENT_RING_2'
                            cellWidth={38}
                            cellHeight={38}
                            position={[110, 90, 0]}
                            maps={slotsEqMaps.ring}
                            {...getEquipmentSlotProps(9)}
                            {...equipmentProps}
                        />
                    </group>
                    <Slots
                        id='ID_BACKPACK'
                        type='backpack'
                        enableRender={isOpenedBackpack}
                        cellWidth={35}
                        cellHeight={35}
                        grid={backpack.grid}
                        items={backpack.items}
                        width={backpack.grid[0].length}
                        height={backpack.grid.length}
                        position={[-120, -60, 0]}
                        events={[
                            { id: 'ID_BACKPACK', type: 'update', handler: (props) => updateBackpackItemPosition(props.item.itemHash, props.position) },
                            { id: 'ID_EQUIPMENT', type: 'transferTo', handler: (props) => { equipBackpackItem(props.item.itemHash, props.slot); playEquipSound() } },
                            { id: 'ID_VAULT', type: 'transferTo', handler: (props) => { moveItemFromBackpackToVault(props.item.itemHash, props.position); } },
                            { id: 'ID_TRADE_MINE', type: 'transferTo', handler: (props) => addTradeItemToPosition(props.item.itemHash, props.position) },
                            { id: '', type: 'drop', handler: (props) => dropBackpackItem(props.item.itemHash, props.coordinate) },
                            { id: '', type: 'doubleClick', handler: (props) => { props.item.itemAttributes.itemAttributes.isConsumable && consumeBackpackItem(props.item.itemHash) } },
                            { id: '', type: 'rightClick', handler: (props) => useUi.getState().isOpenedTrade && addTradeItem(props.item.itemHash) },
                            { id: '', type: 'dropOnOtherObject', handler: (props) => handleDropOnEvent(props.item, props.dropOnItem) },
                        ]}
                        maps={slotsMaps}
                        itemMaps={bpItemMaps}
                        relatedNodes={[relatedNode, relatedNode2 as any]}
                    />
                </group>
            </Plane>
        </Aling>
    )
}