import { useEffect } from "react"
import { useSound } from "./useSound"
import { sounds } from "./soundsConfig"
import { useGame } from "core/useGame"

// TODO: for now just start background, later change based on location and handle in different components
export const SoundBackground = () => {
    const location = useGame(state => state.location)
    const soundManager = useSound(state => state.soundManager)
    useEffect(() => {
        if (!soundManager) return
        if (location === 'menu') {   
            soundManager.switch([sounds.BACKGROUND_WORLD], [sounds.BACKGROUND_MENU], 3000, 3000)
            return
        } 
        if (location === 'world') {
            soundManager.switch([sounds.BACKGROUND_MENU], [sounds.BACKGROUND_WORLD], 3000, 3000)
            return
        }
    }, [soundManager, location])

    return null
}