// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GoldEnter_GoldEnter__kIGr5 {
  padding: 16px;
  background-color: rgb(19, 19, 19);
  border-radius: 4px;
}
.GoldEnter_GoldEnter__kIGr5 p {
  color: white;
  font-size: 20px;
  margin-bottom: 16px;
}
.GoldEnter_GoldEnter__kIGr5 input {
  background-color: black;
  border-radius: 4px;
  border: 1px solid rgb(255, 140, 0);
  width: 300px;
  height: 50px;
  font-size: 20px;
  color: rgb(255, 140, 0);
  padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/views/Game/GamePlay/UserInterface2D/GoldEnter/GoldEnter.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iCAAA;EACA,kBAAA;AACJ;AACI;EACI,YAAA;EACA,eAAA;EACA,mBAAA;AACR;AAEI;EACI,uBAAA;EACA,kBAAA;EACA,kCAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,uBAAA;EACA,aAAA;AAAR","sourcesContent":[".GoldEnter {\n    padding: 16px;\n    background-color: rgb(19, 19, 19);\n    border-radius: 4px;\n\n    p {\n        color: white;\n        font-size: 20px;\n        margin-bottom: 16px;\n    }\n\n    input {\n        background-color: black;\n        border-radius: 4px;\n        border: 1px solid rgb(255, 140, 0);\n        width: 300px;\n        height: 50px;\n        font-size: 20px;\n        color: rgb(255, 140, 0);\n        padding: 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GoldEnter": `GoldEnter_GoldEnter__kIGr5`
};
export default ___CSS_LOADER_EXPORT___;
