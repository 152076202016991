import { Box } from "@react-three/drei"
import { MeshProps } from "@react-three/fiber"

interface Props extends MeshProps {
    width: number
    height: number
    offset: [number, number, number]
    visible?: boolean
}
export const HeatBox = ({ width, height, offset, visible = false, ...props }: Props) => {
    return (
        <Box visible={visible} {...props} name='player-heatbox' position={offset} args={[width, height, width]}>
            <meshBasicMaterial transparent={true} opacity={.4} />
        </Box>
    )
}