import { Plane, useTexture } from "@react-three/drei"
import { useEffect, useRef } from "react"

interface Props {
    min?: number
    max: number
    colorStop1?: { r: number, g: number, b: number }
    colorStop2?: { r: number, g: number, b: number }
    args?: any,
    barArgs?: any,
    position?: [number, number, number],
    name?: string
    current: number
}
export const StaminaBar = ({
    min = 0,
    max,
    colorStop1 = { r: 158, g: 79, b: 16 },
    colorStop2 = { r: 199, g: 166, b: 29 },
    current,
    position = [0, 0, 0],
    name = 'stamina-bar',
    args = [353, 14, 1],
    barArgs = [233, 8, 1],
    ...props
}: Props) => {
    const [bgMap] = useTexture(['assets/bottom-menu/stamina-bar-bg.png'])
    const [colorMap, alphaMap, overlayMap] = useTexture(['assets/bottom-menu/experience-bar.png', 'assets/bottom-menu/experience-bar-alpha.png', 'assets/bottom-menu/stamina-bar-overlay.png'])
    const uniforms = useRef({
        uMin: { value: min },
        uMax: { value: max },
        uCurrent: { value: current },
        uColorStop1: { value: colorStop1 },
        uColorStop2: { value: colorStop2 },
        uOverlayMap: { value: overlayMap }
    })
    useEffect(() => {
        uniforms.current.uMin.value = min
        uniforms.current.uMax.value = max
        uniforms.current.uCurrent.value = current
        uniforms.current.uColorStop1.value = colorStop1
        uniforms.current.uColorStop2.value = colorStop2
    }, [min, max, current, colorStop1, colorStop2])

    return (
        <Plane 
            args={args}
            name={name}
            position={position}
            {...props}
        >
            <meshBasicMaterial map={bgMap} transparent={true} />
            <Plane args={barArgs} position={[0, -2, 1]}>
                <meshBasicMaterial
                    transparent={true}
                    map={colorMap}
                    alphaMap={alphaMap}
                    onBeforeCompile={(shader) => {
                        shader.uniforms = {
                            ...shader.uniforms,
                            ...uniforms.current,
                        }
                        // Add Uniforms
                        shader.fragmentShader = `
                            uniform float uMin;
                            uniform float uMax;
                            uniform float uCurrent;
                            uniform vec3 uColorStop1;
                            uniform vec3 uColorStop2;
                            uniform sampler2D uOverlayMap; 
                        `+shader.fragmentShader
                        // Remove alphamap
                        shader.fragmentShader = shader.fragmentShader.replace(`#include <alphamap_fragment>`, ``)
                        // Add Our Color & reuse alpha map
                        shader.fragmentShader = shader.fragmentShader.replace(`#include <dithering_fragment>`, `
                            float alphaMask = texture2D( alphaMap, vAlphaMapUv ).g;
                            vec3 overlayColor = texture2D( uOverlayMap, vAlphaMapUv ).rgb;
                            float delta = uCurrent / (uMax - uMin);
                            float edge = 0.01;
                            float clampHeight = 1. - smoothstep(delta - edge, delta + edge, vAlphaMapUv.x);
                            vec3 gradientColor = mix(uColorStop1, uColorStop2, vAlphaMapUv.x-.5);
                            vec3 color = vec3(gradientColor.r / 255., gradientColor.g / 255., gradientColor.b / 255.) * alphaMask * clampHeight;
                            gl_FragColor.rgb += color;
                            gl_FragColor.rgb *= overlayColor;
                            #include <dithering_fragment>
                        `)
                    }}
                />
            </Plane>
        </Plane>
        
    )
}