import { InventorySlot } from "interfaces/inventory.interface"
import { getModelMesh } from "views/Game/GamePlay/Models/utils/getModelMesh"
import { shader_level } from "views/Game/GamePlay/Models/shaders/shader_level"
import { isExcellent } from "views/Game/GamePlay/utils/isExcellent"
import { useSettings } from "views/Game/GamePlay/UserInterface2D/Settings/useSettings"
import { shader_golden } from "../shaders/shader_golden"
import { SkeletonUtils } from "three-stdlib"
import { shader_common } from "../shaders/shader_common"
import { Fighter } from "interfaces/fighter.interface"
import { ItemDroppedEvent } from "interfaces/item.interface"
import { MutableRefObject } from "react"
import { shader_spawn } from "../shaders/shader_spawn"

export const getShaderedModel = (name: string, item: InventorySlot | Fighter | ItemDroppedEvent, outerUniforms: MutableRefObject<{ [key: string]: { value: any } }> = { current: {} }) => {
    const enableDynamicShadows = useSettings.getState().enableShadows
    const gltf = getModelMesh(name)
    if (!gltf?.scene) { return null }
    const model = SkeletonUtils.clone(gltf.scene)
    const uniforms: MutableRefObject<{ [key: string]: { value: any } }> = { current: {} }

    const shaders = {
        common: shader_common(),
        // @ts-expect-error
        level: shader_level({ uLevel: item?.itemAttributes?.itemLevel || 0, uIsExellent: isExcellent(item?.itemAttributes || {}) }),
        // @ts-expect-error
        golden: shader_golden({ uIsGolden: !!item?.itemAttributes?.name?.toLowerCase()?.includes('golden') }),
        spawn: shader_spawn()
    }
    const injectUniforms = {
        ...shaders.level.uniforms, 
        ...shaders.spawn.uniforms, 
        ...shaders.golden.uniforms,
        // Keep common uniforms last
        ...shaders.common.uniforms,
        ...outerUniforms.current
    }
    // // @ts-expect-error
    // console.log('uniforms', item?.itemAttributes?.itemLevel, item?.itemAttributes)
    model.traverse((object: any) => {
        object.castShadow = enableDynamicShadows
        object.revieveShadow = enableDynamicShadows
        if (object.isMesh) {
            const material = object.material.clone()
            material.transparent = true
            material.onBeforeCompile = (_shader: any) => {
                _shader.uniforms = { ..._shader.uniforms, ...injectUniforms }
                uniforms.current = _shader.uniforms

                // Vertex
                _shader.vertexShader = _shader.vertexShader.replace('#include <common>', `
                    #include <common>
                    ${shaders.common.injectVertexShader.header}
                `)
                _shader.vertexShader = _shader.vertexShader.replace('#include <fog_vertex>', `
                    #include <fog_vertex>
                    ${shaders.common.injectVertexShader.footer}
                `)
        
                // Fragment
                _shader.fragmentShader = _shader.fragmentShader.replace('#include <common>', `
                    #include <common>
                    ${shaders.common.injectFragmentShader.header}
                    ${shaders.level.injectFragmentShader.header}
                    ${shaders.spawn.injectFragmentShader.header}
                    ${shaders.golden.injectFragmentShader.header}
                `)
                _shader.fragmentShader = _shader.fragmentShader.replace('#include <dithering_fragment>', `
                    #include <dithering_fragment>
                    ${shaders.common.injectFragmentShader.footer}
                    ${shaders.level.injectFragmentShader.footer}
                    ${shaders.spawn.injectFragmentShader.footer}
                    ${shaders.golden.injectFragmentShader.footer}
                    // gl_FragColor
                    ${shaders.common.injectFragmentShader.glFragColor}
                `)
                // console.log(name, _shader.vertexShader, _shader.fragmentShader)
            }
            object.material = material
            object.material.needsUpdate = true
        }
    })

    return { scene: model, animations: gltf.animations, uniforms }
}