import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { useUi } from 'views/Game/GamePlay/UserInterface3D/useUi';

export interface UseStats {
    // Open/close functionality
    isOpened: boolean
    open: () => void
    close: () => void
    toggle: () => void

    // Buttons
    subscribeStats: () => void
    unsubscribeStats: () => void
    _handleKeypress: (e: KeyboardEvent) => void
}

export const useStats = createWithEqualityFn<UseStats>((set, get) => ({
    isOpened: false,
    open: () => set({ isOpened: true }),
    close: () => set({ isOpened: false }),
    toggle: () => set(state => ({ isOpened: !state.isOpened })),

    subscribeStats: () => {
        const target = useUi.getState().eventsNode.current
        if (!target) { console.error('[useCommandLine]: target event node not found') }
        target?.addEventListener('keypress', get()._handleKeypress)
    },
    unsubscribeStats() {
        const target = useUi.getState().eventsNode.current
        target?.removeEventListener('keypress', get()._handleKeypress)
    },
    _handleKeypress: (e) => {
        if (e.code.toLowerCase() === 'keyc') {
            e.preventDefault()
            get().toggle()
        }
    }
}), shallow)