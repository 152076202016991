import { Fighter } from "interfaces/fighter.interface";
import { useMemo } from "react";

function getRandomCoordinate() {
    return 60 + Math.random() * 10 - 5; // Generates a random number between -5 and 5
}

function createFighter(id, equip) {
    return {
        "id": id.toString(),
        "class": "Warrior",
        "maxHealth": 200,
        "maxMana": 0,
        "name": `fighter_${id}`,
        "isNpc": false,
        "isDead": false,
        "canFight": true,
        "healthAfterLastDmg": 200,
        "tokenId": id,
        "location": "lorencia",
        "damageDealt": null,
        "ownerAddress": "0x561436809B487249F797E8FAFA45378190b16b4f",
        "coordinates": {
            "x": getRandomCoordinate(),
            "z": getRandomCoordinate()
        },
        "movementSpeed": 270,
        "skill": 0,
        "spawnCoords": {
            "x": 0,
            "z": 0
        },
        "strength": 42,
        "agility": 21,
        "energy": 5,
        "vitality": 20,
        "available_stats": 0,
        "currentHealth": 200,
        "currentMana": 0,
        "damage": 23,
        "attackRate": 0,
        "damageRate": 0,
        "wizardryDamage": 0,
        "skillDamage": 0,
        "defence": 10,
        "defenceRate": 0,
        "attackSpeed": 0,
        "hpRegenerationRate": 0,
        "hpRegenerationBonus": 0,
        "criticalDmgRate": 0,
        "excellentDmgRate": 0,
        "doubleDmgRate": 0,
        "ignoreDefRate": 0,
        "level": 0,
        "level_progress": 0,
        "experience": 0,
        "direction": {
            "dx": 1,
            "dz": 0
        },
        "skills": {
            "0": {
                "skillId": 0,
                "name": "Malee",
                "activeDistance": 1,
                "multihit": false,
                "attackSuccessRate": 100,
                "hitAngle": 180,
                "disabled": false
            },
            "1": {
                "skillId": 1,
                "name": "Slash",
                "activeDistance": 1,
                "multihit": false,
                "attackSuccessRate": 100,
                "hitAngle": 180,
                "disabled": false
            },
            "2": {
                "skillId": 2,
                "name": "Falling Slash",
                "activeDistance": 2,
                "multihit": false,
                "attackSuccessRate": 100,
                "hitAngle": 120,
                "disabled": false
            },
            "3": {
                "skillId": 3,
                "name": "Tripple Shot",
                "activeDistance": 7,
                "multihit": true,
                "attackSuccessRate": 100,
                "hitAngle": 120,
                "disabled": false
            },
            "4": {
                "skillId": 4,
                "name": "Dark Spirits",
                "activeDistance": 7,
                "multihit": true,
                "attackSuccessRate": 100,
                "hitAngle": 360,
                "disabled": false
            }
        },
        "skill_bindings": {
            "1": null,
            "2": null,
            "3": null,
            "4": null,
            "5": null
        },
        "consumables": {
            "E": null,
            "Q": null,
            "R": null,
            "W": null
        },
        "backpack": {
            "grid": [
                [false, false, false, false, false, false, false, false],
                [false, false, false, false, false, false, false, false],
                [false, false, false, false, false, false, false, false],
                [false, false, false, false, false, false, false, false],
                [false, false, false, false, false, false, false, false],
                [false, false, false, false, false, false, false, false],
                [false, false, false, false, false, false, false, false],
                [false, false, false, false, false, false, false, false]
            ],
            "items": {},
            "gold": 0
        },
        "equipment": equip ?
        {
            "items": {
                "1": {
                    "itemAttributes": {
                        "name": "Legendary Helm",
                        "tokenId": 76,
                        "itemLevel": 15,
                        "additionalDamage": 0,
                        "additionalDefense": 0,
                        "fighterId": 100021,
                        "packSize": 1,
                        "luck": false,
                        "skill": false,
                        "createdAt": 1724324203404127000,
                        "itemAttributes": {
                            "name": "Legendary Helm",
                            "maxLevel": 15,
                            "itemRarityLevel": 1,
                            "isPackable": false,
                            "isBox": false,
                            "isWeapon": false,
                            "isArmour": true,
                            "isJewel": false,
                            "isWings": false,
                            "isMisc": false,
                            "isConsumable": false,
                            "inShop": false,
                            "binding": "",
                            "price": 0
                        },
                        "itemParameters": {
                            "durability": 60,
                            "classRequired": "Warrior",
                            "strengthRequired": 30,
                            "agilityRequired": 0,
                            "energyRequired": 0,
                            "vitalityRequired": 0,
                            "itemWidth": 2,
                            "itemHeight": 2,
                            "acceptableSlot1": 1,
                            "acceptableSlot2": 0,
                            "minPhysicalDamage": 0,
                            "maxPhysicalDamage": 0,
                            "minMagicDamage": 0,
                            "maxMagicDamage": 0,
                            "defense": 30,
                            "attackSpeed": 0
                        },
                        "excellentItemAttributes": {
                            "IsExcellent": false,
                            "increaseAttackSpeedPoints": 0,
                            "reflectDamagePercent": 0,
                            "restoreHPChance": 0,
                            "restoreMPChance": 0,
                            "doubleDamageChance": 0,
                            "ignoreOpponentDefenseChance": 0,
                            "lifeAfterMonsterIncrease": 0,
                            "manaAfterMonsterIncrease": 0,
                            "excellentDamageProbabilityIncrease": 0,
                            "attackSpeedIncrease": 0,
                            "attackLvl20": 0,
                            "attackIncreasePercent": 0,
                            "defenseSuccessRateIncrease": 0,
                            "goldAfterMonsterIncrease": 0,
                            "reflectDamage": 0,
                            "maxLifeIncrease": 0,
                            "maxManaIncrease": 0,
                            "hpRecoveryRateIncrease": 0,
                            "mpRecoveryRateIncrease": 0,
                            "decreaseDamageRateIncrease": 0
                        }
                    },
                    "itemHash": "2ab94148488ca0b1f62cfac59d7440b9e8cfa1d1cd0d81663e1d1f0a9c51519e",
                    "qty": 1,
                    "inTrade": false
                },
                "2": {
                    "itemAttributes": {
                        "name": "Legendary Armour",
                        "tokenId": 73,
                        "itemLevel": 15,
                        "additionalDamage": 0,
                        "additionalDefense": 0,
                        "fighterId": 100021,
                        "packSize": 1,
                        "luck": false,
                        "skill": false,
                        "createdAt": 1724324203404612000,
                        "itemAttributes": {
                            "name": "Legendary Armour",
                            "maxLevel": 15,
                            "itemRarityLevel": 2,
                            "isPackable": false,
                            "isBox": false,
                            "isWeapon": false,
                            "isArmour": true,
                            "isJewel": false,
                            "isWings": false,
                            "isMisc": false,
                            "isConsumable": false,
                            "inShop": false,
                            "binding": "",
                            "price": 0
                        },
                        "itemParameters": {
                            "durability": 60,
                            "classRequired": "Warrior",
                            "strengthRequired": 30,
                            "agilityRequired": 0,
                            "energyRequired": 0,
                            "vitalityRequired": 0,
                            "itemWidth": 2,
                            "itemHeight": 2,
                            "acceptableSlot1": 2,
                            "acceptableSlot2": 0,
                            "minPhysicalDamage": 0,
                            "maxPhysicalDamage": 0,
                            "minMagicDamage": 0,
                            "maxMagicDamage": 0,
                            "defense": 30,
                            "attackSpeed": 0
                        },
                        "excellentItemAttributes": {
                            "IsExcellent": false,
                            "increaseAttackSpeedPoints": 0,
                            "reflectDamagePercent": 0,
                            "restoreHPChance": 0,
                            "restoreMPChance": 0,
                            "doubleDamageChance": 0,
                            "ignoreOpponentDefenseChance": 0,
                            "lifeAfterMonsterIncrease": 0,
                            "manaAfterMonsterIncrease": 0,
                            "excellentDamageProbabilityIncrease": 0,
                            "attackSpeedIncrease": 0,
                            "attackLvl20": 0,
                            "attackIncreasePercent": 0,
                            "defenseSuccessRateIncrease": 0,
                            "goldAfterMonsterIncrease": 0,
                            "reflectDamage": 0,
                            "maxLifeIncrease": 0,
                            "maxManaIncrease": 0,
                            "hpRecoveryRateIncrease": 0,
                            "mpRecoveryRateIncrease": 0,
                            "decreaseDamageRateIncrease": 0
                        }
                    },
                    "itemHash": "4b8213c30201516d151a6fcfea06053ccbd0b5df434b24c12cef3ef4922274ea",
                    "qty": 1,
                    "inTrade": false
                },
                "3": {
                    "itemAttributes": {
                        "name": "Legendary Pants",
                        "tokenId": 72,
                        "itemLevel": 15,
                        "additionalDamage": 0,
                        "additionalDefense": 0,
                        "fighterId": 100021,
                        "packSize": 1,
                        "luck": false,
                        "skill": false,
                        "createdAt": 1724324203405935000,
                        "itemAttributes": {
                            "name": "Legendary Pants",
                            "maxLevel": 15,
                            "itemRarityLevel": 1,
                            "isPackable": false,
                            "isBox": false,
                            "isWeapon": false,
                            "isArmour": true,
                            "isJewel": false,
                            "isWings": false,
                            "isMisc": false,
                            "isConsumable": false,
                            "inShop": false,
                            "binding": "",
                            "price": 0
                        },
                        "itemParameters": {
                            "durability": 60,
                            "classRequired": "Warrior",
                            "strengthRequired": 30,
                            "agilityRequired": 0,
                            "energyRequired": 0,
                            "vitalityRequired": 0,
                            "itemWidth": 2,
                            "itemHeight": 2,
                            "acceptableSlot1": 3,
                            "acceptableSlot2": 0,
                            "minPhysicalDamage": 0,
                            "maxPhysicalDamage": 0,
                            "minMagicDamage": 0,
                            "maxMagicDamage": 0,
                            "defense": 30,
                            "attackSpeed": 0
                        },
                        "excellentItemAttributes": {
                            "IsExcellent": false,
                            "increaseAttackSpeedPoints": 0,
                            "reflectDamagePercent": 0,
                            "restoreHPChance": 0,
                            "restoreMPChance": 0,
                            "doubleDamageChance": 0,
                            "ignoreOpponentDefenseChance": 0,
                            "lifeAfterMonsterIncrease": 0,
                            "manaAfterMonsterIncrease": 0,
                            "excellentDamageProbabilityIncrease": 0,
                            "attackSpeedIncrease": 0,
                            "attackLvl20": 0,
                            "attackIncreasePercent": 0,
                            "defenseSuccessRateIncrease": 0,
                            "goldAfterMonsterIncrease": 0,
                            "reflectDamage": 0,
                            "maxLifeIncrease": 0,
                            "maxManaIncrease": 0,
                            "hpRecoveryRateIncrease": 0,
                            "mpRecoveryRateIncrease": 0,
                            "decreaseDamageRateIncrease": 0
                        }
                    },
                    "itemHash": "ddf38cace7dd74443d132f7c638f6d509fc2a2df33d2790ba9ffa2e29905a611",
                    "qty": 1,
                    "inTrade": false
                },
                "4": {
                    "itemAttributes": {
                        "name": "Legendary Gloves",
                        "tokenId": 75,
                        "itemLevel": 15,
                        "additionalDamage": 0,
                        "additionalDefense": 0,
                        "fighterId": 100021,
                        "packSize": 1,
                        "luck": false,
                        "skill": false,
                        "createdAt": 1724324203408117000,
                        "itemAttributes": {
                            "name": "Legendary Gloves",
                            "maxLevel": 15,
                            "itemRarityLevel": 2,
                            "isPackable": false,
                            "isBox": false,
                            "isWeapon": false,
                            "isArmour": true,
                            "isJewel": false,
                            "isWings": false,
                            "isMisc": false,
                            "isConsumable": false,
                            "inShop": false,
                            "binding": "",
                            "price": 0
                        },
                        "itemParameters": {
                            "durability": 60,
                            "classRequired": "Warrior",
                            "strengthRequired": 30,
                            "agilityRequired": 0,
                            "energyRequired": 0,
                            "vitalityRequired": 0,
                            "itemWidth": 2,
                            "itemHeight": 2,
                            "acceptableSlot1": 4,
                            "acceptableSlot2": 0,
                            "minPhysicalDamage": 0,
                            "maxPhysicalDamage": 0,
                            "minMagicDamage": 0,
                            "maxMagicDamage": 0,
                            "defense": 30,
                            "attackSpeed": 0
                        },
                        "excellentItemAttributes": {
                            "IsExcellent": false,
                            "increaseAttackSpeedPoints": 0,
                            "reflectDamagePercent": 0,
                            "restoreHPChance": 0,
                            "restoreMPChance": 0,
                            "doubleDamageChance": 0,
                            "ignoreOpponentDefenseChance": 0,
                            "lifeAfterMonsterIncrease": 0,
                            "manaAfterMonsterIncrease": 0,
                            "excellentDamageProbabilityIncrease": 0,
                            "attackSpeedIncrease": 0,
                            "attackLvl20": 0,
                            "attackIncreasePercent": 0,
                            "defenseSuccessRateIncrease": 0,
                            "goldAfterMonsterIncrease": 0,
                            "reflectDamage": 0,
                            "maxLifeIncrease": 0,
                            "maxManaIncrease": 0,
                            "hpRecoveryRateIncrease": 0,
                            "mpRecoveryRateIncrease": 0,
                            "decreaseDamageRateIncrease": 0
                        }
                    },
                    "itemHash": "810f32285fbb49b8e5df36e885f29131136e8487af578cafc780135a9d3c5c5f",
                    "qty": 1,
                    "inTrade": false
                },
                "5": {
                    "itemAttributes": {
                        "name": "Legendary Boots",
                        "tokenId": 74,
                        "itemLevel": 15,
                        "additionalDamage": 0,
                        "additionalDefense": 0,
                        "fighterId": 100021,
                        "packSize": 1,
                        "luck": false,
                        "skill": false,
                        "createdAt": 1724324203407924000,
                        "itemAttributes": {
                            "name": "Legendary Boots",
                            "maxLevel": 15,
                            "itemRarityLevel": 1,
                            "isPackable": false,
                            "isBox": false,
                            "isWeapon": false,
                            "isArmour": true,
                            "isJewel": false,
                            "isWings": false,
                            "isMisc": false,
                            "isConsumable": false,
                            "inShop": false,
                            "binding": "",
                            "price": 0
                        },
                        "itemParameters": {
                            "durability": 60,
                            "classRequired": "Warrior",
                            "strengthRequired": 30,
                            "agilityRequired": 0,
                            "energyRequired": 0,
                            "vitalityRequired": 0,
                            "itemWidth": 2,
                            "itemHeight": 2,
                            "acceptableSlot1": 5,
                            "acceptableSlot2": 0,
                            "minPhysicalDamage": 0,
                            "maxPhysicalDamage": 0,
                            "minMagicDamage": 0,
                            "maxMagicDamage": 0,
                            "defense": 30,
                            "attackSpeed": 0
                        },
                        "excellentItemAttributes": {
                            "IsExcellent": false,
                            "increaseAttackSpeedPoints": 0,
                            "reflectDamagePercent": 0,
                            "restoreHPChance": 0,
                            "restoreMPChance": 0,
                            "doubleDamageChance": 0,
                            "ignoreOpponentDefenseChance": 0,
                            "lifeAfterMonsterIncrease": 0,
                            "manaAfterMonsterIncrease": 0,
                            "excellentDamageProbabilityIncrease": 0,
                            "attackSpeedIncrease": 0,
                            "attackLvl20": 0,
                            "attackIncreasePercent": 0,
                            "defenseSuccessRateIncrease": 0,
                            "goldAfterMonsterIncrease": 0,
                            "reflectDamage": 0,
                            "maxLifeIncrease": 0,
                            "maxManaIncrease": 0,
                            "hpRecoveryRateIncrease": 0,
                            "mpRecoveryRateIncrease": 0,
                            "decreaseDamageRateIncrease": 0
                        }
                    },
                    "itemHash": "deb34f783c0f1c8cbae4c9eef468ef1e30ddb153f89f35d7fa10e59cd18759ad",
                    "qty": 1,
                    "inTrade": false
                }
            },
            "is_equipped": {
                "1": true,
                "2": true,
                "3": true,
                "4": true,
                "5": true,
                "6": false,
                "7": false,
                "8": false,
                "9": false,
                "10": false,
                "11": false
            }
        } :
        {
            "items": {},
            "is_equipped": {
                "1": false,
                "2": false,
                "3": false,
                "4": false,
                "5": false,
                "6": false,
                "7": false,
                "8": false,
                "9": false,
                "10": false,
                "11": false
            }
        },
        "lastChatMessage": "",
        "lastDmgTimestamp": Date.now(),
        "lastMoveTimestamp": Date.now(),
        "lastChatMsgTimestamp": 0,
        "credits": 0
    };
}

export const useTestFighters = (amount: number, equip: boolean): Fighter[] => {
    return useMemo(() => Array.from({ length: amount }, (_, index) => createFighter(100022 + 10 + index, equip)) as any, [amount])
}

