import styled from "styled-components"
import { Input } from "./Input"
import { Button } from "./Button"
import { useNavigate } from "react-router";
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from "react";
import { Login } from "./Login";
import { Register } from "./Register";

export const Modal = () => {
    const [type] = usePageType()
    return (
        <StyledWrapper>
            <img src="/auth/inventory.png" alt="" />
            <p className="message">Welcome back</p>
            <h1>MRIUS-D</h1>
            <h2>Dive in this mighty journey, hero.</h2>
            { type === 'login' ? <Login/> : <Register/> }
        </StyledWrapper>
    )
}

const usePageType = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [type, setType] = useState<'login' | 'register'>('login')
    useEffect(() => {
        const _type = searchParams.get('type')
        if (!_type || (_type !== 'login' && _type !== 'register')) {
            navigate({
                search: `?type=${type}`
            })
            return
        }
        setType(_type as 'login' | 'register')
    }, [searchParams])
    return [type, setType]
}

const StyledWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 420px;
    height: 830px;
    z-index: 1;
    color: white;
    padding-top: 57px;
    padding-bottom: 80px;

    img {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
    }

    .message {
        font-size: 13px;
        text-align: center;
        background: linear-gradient(192deg, #926837 0%, #c8aa87 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h1 {
        font-family: var(--second-family);
        margin-top: 52px;
        font-size: 44px;
        background: linear-gradient(192deg, #ffffff 0%, #ffffff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0 0 9px rgba(255, 200, 58, 0.2), 0 0 15px rgba(0, 0, 0, 0.55);
    }

    h2 {
        font-weight: bold;
        margin-top: 22px;
        font-size: 20px;
        filter: blur(0.3px);
        text-shadow: 0 0 20px #578cf4;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        color: #7d7d7d;
        filter: blur(0.3px);
        text-shadow: 0 0 20px #578cf4;
        text-align: center;
    }
`

export const StyledForm = styled.form`
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    z-index: 1;

    .button {
        width: 100%;
    }

`

export const StyledFooter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-top: auto;
    width: 306px;

    .button {
        opacity: 0.5;
    }

`