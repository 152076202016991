export const preset = {
    'boots': {
        inTrade: false,
        itemAttributes: {name: 'man_standard_boots', itemLevel: 0 },
        itemHash: "-1",
        qty: 1
    },
    'armour': {
        inTrade: false,
        itemAttributes: {name: 'man_standard_armour', itemLevel: 0 },
        itemHash: "-2",
        qty: 1
    },
    'gloves': {
        inTrade: false,
        itemAttributes: {name: 'man_standard_gloves', itemLevel: 0 },
        itemHash: "-3",
        qty: 1
    },
    'helm': {
        inTrade: false,
        itemAttributes: {name: 'man_standard_helm', itemLevel: 0 },
        itemHash: "-4",
        qty: 1
    },
    'pants': {
        inTrade: false,
        itemAttributes: {name: 'man_standard_pants', itemLevel: 0 },
        itemHash: "-5",
        qty: 1
    },
}

export const requirements = ['boots', 'armour', 'gloves', 'helm', 'pants']
export const optionals = ['sword', 'wings']