import { createGlobalStyle } from "styled-components";

const fonts = `
@font-face {
        font-family: 'Diablo';
        src: url('DiabloHeavy.woff2') format('woff2'),
            url('DiabloHeavy.woff') format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Acari Sans';
        src: url('/fonts/AcariSans-ExtraBold.woff2') format('woff2'),
            url('/fonts/AcariSans-ExtraBold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Acari Sans';
        src: url('/fonts/AcariSans-Bold.woff2') format('woff2'),
            url('/fonts/AcariSans-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Acari Sans';
        src: url('/fonts/AcariSans-Black.woff2') format('woff2'),
            url('/fonts/AcariSans-Black.woff') format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Acari Sans';
        src: url('/fonts/AcariSans-ExtraLight.woff2') format('woff2'),
            url('/fonts/AcariSans-ExtraLight.woff') format('woff');
        font-weight: 200;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Acari Sans';
        src: url('/fonts/AcariSans-Light.woff2') format('woff2'),
            url('/fonts/AcariSans-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Acari Sans';
        src: url('/fonts/AcariSans-Regular.woff2') format('woff2'),
            url('/fonts/AcariSans-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Acari Sans';
        src: url('/fonts/AcariSans-Medium.woff2') format('woff2'),
            url('/fonts/AcariSans-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
`

export const GlobalStyles = createGlobalStyle`
    ${fonts}

    :root {
        --font-family: 'Acari Sans', sans-serif;
        --second-family: 'Diablo', sans-serif;
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
    }

    html,
    body {
        position: relative;
        width: 100vw;
        height: 100vh;
        background: black;
        overflow: hidden;
        scroll-behavior: auto;
        touch-action: pan-x;
        -ms-scroll-chaining: none;
        overscroll-behavior: none;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: transparent;
        background: black;
    }

    body {
        margin: 0;
        font-family: 'Acari Sans', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    img, video {
        user-select: none;
    }

    /* Leva Panel */
    .leva-c-kWgxhW {
        position: absolute !important;
        left: 32px !important;
        top: 245px !important;
        z-index: 1000000;
        pointer-events: all;
    }

    /* Stats */
    .stats {
        top: 120px !important;
        left: 32px !important;
        opacity: .3 !important;
        z-index: 0 !important;
    }

    /* Game */
    .Game {
        position: relative;
        width: 100vw;
        height: 100vh;
    }

    /* Remove background color for Chrome, Safari, and Edge */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        /* transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: inherit !important;
        color: white !important; */
        ~ svg path {
            fill: black;
        }
    }

    #root {
        min-height: 100vh;
    }
`