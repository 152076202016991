import React from "react"
import { useCore } from "../../useCore"

interface Props { id: string, children: any, visible?: boolean }
export const UIHoverControl = ({ id, visible = true, children }: Props) => {
    const handlePointerEnter = () => {
        if (!id) { return }
        if (visible)
            useCore.getState().setHoveredUi(id, 'add')
    }
    const handlePointerLeave = () => {
        if (!id) { return }
        useCore.getState().setHoveredUi(id, 'remove')
    }
    React.useEffect(() => { 
        if (!visible) 
            setTimeout(() => {
                handlePointerLeave() 
            }, 10)
    }, [visible])

    return <group
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
    >
        {children}
    </group>
}