import * as THREE from "three"
import React, { MutableRefObject, useEffect, useRef } from "react"

import { useFighter } from "./Fighter/useFighter"
import { useFrame, useThree } from "@react-three/fiber"

import { useControls } from "leva"
import { useSettings } from "./UserInterface2D/Settings/useSettings"
import { Environment, SpotLight } from "@react-three/drei"

const Light = React.memo(function Light() {
    const fighterNode = useFighter(state => state.fighterNode)
    const shadowlightRef = React.useRef<THREE.DirectionalLight | null>(null)
    const lightPosition = React.useMemo(() => new THREE.Vector3(0, 30, 17), [])

    // Set Shadows Mode
    // TODO: Issue with baking
    const get = useThree(state => state.get)
    React.useEffect(() => {
        const enableDynamicShadows = useSettings.getState().enableShadows
        get().gl.shadowMap.needsUpdate = true  

        if (!enableDynamicShadows) {
            get().gl.shadowMap.needsUpdate = false  
        }
    }, [])

    // Move shadow light shadow
    React.useEffect(() => {
        if (!fighterNode.current || !shadowlightRef.current) { return }
        shadowlightRef.current.target = fighterNode.current 
    }, [fighterNode.current, shadowlightRef.current])
    useFrame(() => {
        if (!fighterNode.current || !shadowlightRef.current) { return }
        shadowlightRef.current.position.set(fighterNode.current.position.x, fighterNode.current.position.y, fighterNode.current.position.z).add(lightPosition)
    })

    return (
        <group name="light">
            <ambientLight color={'#152365'} intensity={12} />
            <directionalLight 
                intensity={2}
                color="#fff6d5" 
                ref={shadowlightRef}
                castShadow
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}
                shadow-camera-left={-25}
                shadow-camera-right={25}
                shadow-camera-top={25}
                shadow-camera-bottom={-25}
                shadow-bias={-0.0001}
            />
            <PersonalPointLight fighterNode={fighterNode} />
            <Environment files='christmas_photo_studio_07_1k.hdr' environmentIntensity={.5} />
        </group>
    )
})

export default Light

export const PersonalPointLight = ({ fighterNode }: { fighterNode: MutableRefObject<THREE.Mesh> }) => {
    const pointlightRef = React.useRef<THREE.PointLight | null>(null)
    const pointLightPosition = React.useMemo(() => new THREE.Vector3(0, 6, 0), [])
    useFrame(() => {
        if (!fighterNode.current || !pointlightRef.current) { return }
        pointlightRef.current.position.set(fighterNode.current.position.x, fighterNode.current.position.y, fighterNode.current.position.z).add(pointLightPosition)
    })
    return (
        <pointLight
            ref={pointlightRef}
            intensity={200}
            decay={1.5}
            distance={9}
            color="#faff99"
        />
    )

}