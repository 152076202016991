import styled from "styled-components";

export const StyledTitle = styled.h2`
    position: absolute;
    text-align: center;
    text-shadow: 0px 0px 20px #986F40;
    font-family: "Acari Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.7px;
    background: linear-gradient(252deg, #926837 40.02%, #C8AA87 82.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

export const StyledText = styled.p`
    text-align: right;
    text-shadow: 0px 0px 20px #7A7A7A;
    font-family: "Acari Sans";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: linear-gradient(252deg, #D4D4D4 40.02%, #9A9A9A 82.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: blur(0.15000000596046448px);
`