import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useFighter } from "views/Game/GamePlay/Fighter/useFighter"
import { UiText } from "../../components/UiText"
import Button from "../../components/Button"
import { Plane } from "@react-three/drei"
import { useCloud } from "EventCloud/useCloud"
import { useUi } from "../../useUi"
import { Skill } from "interfaces/skill.interface"
import { SkillSlot } from "./SkillSlot"
import { useSkillsTextures } from "./useSkillsTextures"
import { SkillSelect } from "./SkillSelect"
import * as THREE from 'three'
import { UIHoverControl } from "../../components/UIHoverControl"

interface Props { position?: [number, number, number] }
export const Skills = ({ position }: Props) => {
    const slotsRef = useRef<THREE.Group | null>(null)
    const selectRef = useRef<THREE.Group | null>(null)

    const [slotChange, setSlotChange] = useState<null | number>(null)
    const fighter = useFighter(state => state.fighter)

    const skillSlots: string[] = useMemo(() => Object.keys(fighter.skill_bindings), [fighter])
    const binding = useMemo(() => fighter.skill_bindings, [fighter])
    const skills = useMemo(() => [...Object.values(fighter.skills)].filter(_ => _.skillId !== 0), [fighter])

    const selectedSkill = useCloud(state => state.selectedSkill)
    const skillsTextures = useSkillsTextures()

    const setSlot = useCallback((slot: number) => {
        if (slotChange === +slot) {
            setSlotChange(null)
            return
        }

        setSlotChange(slot)
        const skillNode = slotsRef.current.getObjectByName("button_skill_slot_" + slot)
        if (!skillNode) { return }
        selectRef.current.position.setX(skillNode.position.x)
    }, [slotChange])

    const setSkill = useCallback((skillId: number) => {
        useCloud.getState().bindSkill(skillId, slotChange)
        setSlotChange(null)
    }, [slotChange, setSlotChange])

    useEffect(() => {
        const node = useUi.getState().eventsNode.current
        if (!node) { return console.warn('[Skills]: eventsNode not found') }
        node.addEventListener('keydown', subscribeKeys)
        function subscribeKeys(event: KeyboardEvent) {
            const { setSelectedSkill } = useCloud.getState()
            const bindings = useFighter.getState().fighter.skill_bindings
            const key = event.code.toLowerCase()
            if (key === 'digit1') {
                bindings[1]?.skillId && setSelectedSkill(bindings[1].skillId)
                return
            }
            if (key === 'digit2') {
                bindings[2]?.skillId && setSelectedSkill(bindings[2].skillId)
                return
            }
            if (key === 'digit3') {
                bindings[3]?.skillId && setSelectedSkill(bindings[3].skillId)
                return
            }
            if (key === 'digit4') {
                bindings[4]?.skillId && setSelectedSkill(bindings[4].skillId)
                return
            }
            if (key === 'digit5') {
                bindings[5]?.skillId && setSelectedSkill(bindings[5].skillId)
                return
            }
        }
        return () => node.removeEventListener('keydown', subscribeKeys)
    }, [])

    return (
        <group ref={slotsRef} name="skills-quick-menu" position={position}>
            {skillSlots.map((slot, idx) => (
                <SkillSlot
                    id={slot}
                    key={slot}
                    width={45}
                    height={45}
                    position={[45 * idx, 0, 0]}
                    onClick={() => setSlot(+slot)}
                    active={selectedSkill === (binding[slot] as Skill)?.skillId}
                    selectedSkill={{ skill: binding[slot], map: binding[slot]?.skillId ? (skillsTextures?.[binding[slot].skillId] || skillsTextures.unknown) : null }}
                />
            ))}
            <group ref={selectRef} name="skills-popup-select" position={[0, 63, 0]}>
                <>
                    {
                        typeof slotChange === 'number' ? skills.map((skill, idx) => (
                            <SkillSelect
                                id={"select_skill_" + skill.skillId}
                                name={skill.name}
                                key={skill.skillId}
                                width={45}
                                height={45}
                                position={[0, 45 * idx, 10]}
                                onClick={() => setSkill(skill.skillId)}
                                skillMap={skillsTextures[skill.skillId] || skillsTextures.unknown}
                            />
                        )) : null
                    }
                </>
            </group>
        </group>
    )
}