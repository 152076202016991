import * as THREE from "three";
import React from "react";
import { Text } from "@react-three/drei";
import { createBillboardMaterial } from "../materials/createBillboardMaterial";
import { BackgroundPlane } from "./BackgroundPlane";

interface Props {
    value: string
    offsetY: number
    backgroundColor?: number | string
}

const Name = React.forwardRef(({ value, offsetY, backgroundColor = 0x4C5D1A }: Props, outerRef: React.MutableRefObject<THREE.Group | null>) => {
    const textBillboardMaterial = React.useMemo(
        () => createBillboardMaterial(new THREE.MeshBasicMaterial()),
        []
    );

    const textRef = React.useRef<THREE.Mesh | null>(null)

    return (
        <group ref={outerRef}>
            <group position={[0, offsetY, 0]}>
                <BackgroundPlane
                    color={backgroundColor}
                    target={textRef}
                    offsetX={0.1}
                    opacity={0.4}
                    anchorY="bottom"
                />
                <Text
                    ref={textRef}
                    color={0xffffff}
                    fillOpacity={1}
                    anchorX="center"
                    anchorY="bottom"
                    fontSize={0.15}
                    material={textBillboardMaterial}
                >
                    {value}
                </Text>
            </group>
        </group>
    )
})

export default Name;
