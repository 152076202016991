// Warrior Sets
export const warrior = [
    {
        name: 'warrior_standard_set',
        url: 'models/sets/warrior/warrior_standard_set.glb'
    },
    {
        name: 'warrior_bronze_set',
        url: 'models/sets/warrior/warrior_bronze_set.glb'
    },
    {
        name: 'warrior_leather_set',
        url: 'models/sets/warrior/warrior_leather_set.glb'
    },
    {
        name: 'inventory_warrior_bronze_set',
        url: 'models/sets/warrior/inventory_warrior_bronze_set.glb'
    },
    {
        name: 'inventory_warrior_leather_set',
        url: 'models/sets/warrior/inventory_warrior_leather_set.glb'
    },
]