import SoundManager from './SoundManager';
import { create } from 'zustand';
import { maxSoundDistance, sounds } from './soundsConfig';
import { useRef, MutableRefObject, useMemo, useEffect } from 'react';
import { useFighter } from 'views/Game/GamePlay/Fighter/useFighter';
import { Fighter } from 'interfaces/fighter.interface';
import { useLoop } from 'views/Game/GamePlay/hooks/useLoop';
import { ItemDroppedEvent } from 'interfaces/item.interface';

interface SoundState {
    soundManager: SoundManager | null;
    initializeSoundManager: () => void;
}

export const useSound = create<SoundState>((set) => ({
    soundManager: null,
    initializeSoundManager: () => {
        const soundManager = SoundManager.getInstance();
        soundManager.loadQueuedSounds();
        set({ soundManager });
    },
}));

export interface useSoundManagerInterface {
    play: (id: string, fadeIn?: number) => number;
    stop: (id: string, fadeOut?: number, _soundId?: number) => void;
    setDistanceVolume: (id: string, soundId: number, distance: number, volume: number, minDistance: number, maxDistance: number) => void;
}
export const useSoundManager = (fighter?: Fighter | ItemDroppedEvent): [useSoundManagerInterface, typeof sounds, MutableRefObject<Map<string, number>>] => {
    const soundManager = useSound((state) => state.soundManager);
    // TODO: changed the way i use sound, so i should refactor instanceSounds to make it global
    const instanceSounds = useRef(new Map())
    const fighterRef = useRef(fighter)
    useEffect(() => { fighterRef.current = fighter }, [fighter])
    const manager = useMemo(() => ( {
        play: (id: string, fadeIn?: number) => {
            const soundId = soundManager.play(id, fadeIn)

            // Clean up old soundId if it exists
            if (instanceSounds.current.has(id)){
                manager.stop(id, 300)
            }

            instanceSounds.current.set(id, soundId)
            return soundId
        },
        stop: (id: string, fadeOut?: number) => {
            const soundId = instanceSounds.current.get(id)
            instanceSounds.current.delete(id)
            return soundManager.stop(id, fadeOut, soundId)
        },
        setDistanceVolume: (id: string, soundId: number, distance: number, volume: number, minDistance: number, maxDistance: number) => {
            return soundManager.setDistanceVolume(id, soundId, distance, volume, minDistance, maxDistance)
        },
    }), [soundManager])
    useLoop(() => {
        instanceSounds.current.forEach((soundId, id) => {
            updateSoundVolumes(fighterRef, soundManager, id, soundId);

            // TODO: complete service worker
            // // Send position updates to service worker
            // soundManager?.serviceWorker?.postMessage({
            //     type: 'UPDATE_POSITIONS',
            //     fighterPosition: currentPosition
            // });
        });
    }, { framerate: 100 })
    if (!soundManager) throw new Error("SoundManager not initialized");
    return [manager, sounds, instanceSounds];
};


function updateSoundVolumes(fighter: MutableRefObject<Fighter | ItemDroppedEvent>, soundManager: SoundManager, id: string, soundId: number) {
    const fighterNode = useFighter.getState().fighterNode.current

    const currentPosition = { x: fighterNode.position.x, z: fighterNode.position.z }
    // @ts-expect-error
    const distance = calculateDistance(fighter.current?.coordinates || fighter.current?.coords || currentPosition, currentPosition)
    soundManager.setDistanceVolume(id, soundId, distance, 1.0, 0.0, maxSoundDistance)
}

function calculateDistance(pos1: { x: number; z: number }, pos2: { x: number; z: number }) {
    return Math.sqrt(
        Math.pow(pos2.x - pos1.x, 2) +
        Math.pow(pos2.z - pos1.z, 2)
    )
}
