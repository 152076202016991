import React from 'react';
import styled from 'styled-components';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    disabled?: boolean;
    onClick?: () => void;
    children: React.ReactNode;
}

const StyledButton = styled.button<{ primary?: boolean; disabled?: boolean }>`
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.00) !important;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.68) 0%, rgba(0, 0, 0, 0.68) 100%), radial-gradient(26.67% 41.67% at 68% 50%, rgba(245, 199, 62, 0.60) 50%, rgba(236, 163, 78, 0.60) 100%) !important;
    box-shadow: 0px 0px 10.767px 0px rgba(255, 255, 255, 0.26) inset, 0px -3.214px 3.214px 0px rgba(255, 255, 255, 0.05) inset, 0px 0px 8.035px 0px rgba(0, 0, 0, 0.65) !important;
    
    > span {
        text-align: center;
        font-family: "Acari Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 166.9%;
        background: var(--Radial, radial-gradient(26.67% 41.67% at 68% 50%, #FFEA2F 0%, #ECA34E 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.68) 0%, rgba(0, 0, 0, 0.68) 100%), radial-gradient(26.67% 41.67% at 68% 50%, rgba(245, 199, 62, 0.80) 50%, rgba(236, 163, 78, 0.80) 100%) !important;
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

export const ShrinkButton: React.FC<ButtonProps> = ({ disabled = false, children, onClick, ...props }) => {
    return (
        <StyledButton disabled={disabled} onClick={onClick} {...props}>
            <span>
                {children}
            </span>
        </StyledButton>
    );
};