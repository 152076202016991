import React from 'react';
import Select, { Props as ReactSelectProps, StylesConfig } from 'react-select';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
`;

const customStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#25252561',
    borderRadius: '5px',
    border: '0.5px solid #56565687',
    boxShadow: '0px -4px 4px 0px rgba(79, 79, 79, 0.45) inset',
    height: '28px',
    minHeight: '28px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px 4px',
    paddingLeft: '10px',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#252525',
    borderRadius: '5px',
    ":active": {
      backgroundColor: '#3a3a3a',
    },
    ":focus": {
      backgroundColor: '#3a3a3a',
    },
  }),
  input: (provided) => ({
    ...provided,
    color: '#D4D4D4',
  }),
  container: (provided) => ({
    ...provided,
    padding: '0px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0px',
    paddingRight: '8px',
    svg: {
      width: '15px',
      height: '15px',
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#D4D4D4',
  }),
  option: (provided, state) => ({
    ...provided,
    color: '#D4D4D4',
    backgroundColor: state.isSelected ? '#191919' : '#252525',
    ':active': {
      backgroundColor: '#3a3a3a',
    },
    '&:hover': {
      backgroundColor: '#3a3a3a',
    },
    '&:active': {
      backgroundColor: '#3a3a3a',
    },
    '&:focus': {
      backgroundColor: '#3a3a3a',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#D4D4D4',
  }),
};

interface CustomSelectProps extends ReactSelectProps {
}
export const CustomSelect: React.FC<CustomSelectProps> = (props) => {
  return (
    <StyledSelect
      {...props}
      styles={customStyles}
      classNamePrefix="filter-select"
    />
  );
};
