import styled from "styled-components"
import { useMarketplace } from "./useMarketplace"
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react"
import { useCloud } from "EventCloud/useCloud"
import { StyledTitle } from "../styles"
import { Input } from "../components/Input"
import { MarketplaceItem, WarehouseItem } from "interfaces/marketplace.interface"
import { getRandomColor } from "./Table/TableItem"
import { ShrinkButton } from "../components/ShrinkButton"

const StyledItemModal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: all;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: -1;
    }
`

const StyledItemTitle = styled(StyledTitle)`
    left: 50%;
    transform: translateX(-50%);
    top: 52px;
`

const StyledModal = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 594px;
    color: white;
    padding: 32px;

    .modal-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    button {
        cursor: pointer;
        background: none;
        border: none;
        color: red;
        font-size: 20px;
        font-weight: 700;
        pointer-events: all;
    }
`
const StyledForm = styled.div`
    position: absolute;
    top: 210px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 44px;
`
const StyledRow = styled.div`
    display: flex;
    justify-content: space-between;

    p {
        text-shadow: 0px 0px 20px #7A7A7A;
        font-family: "Acari Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: linear-gradient(252deg, #D4D4D4 40.02%, #9A9A9A 82.23%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: blur(0.15000000596046448px);
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .price-input {
        width: 130px;
    }

    .gold-input {
        width: 230px;
    }

    .x {
        font-family: "Acari Sans";
        margin-left: auto;
        margin-right: 5px;
        font-size: 10px;
        color: #9A9A9A;
        opacity: 0.5;
    }
`
const StyledItemInfo = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    gap: 12px;
    align-items: center;
    top: 120px;
    left: 45px;

    .view {
        position: relative;
        width: 51px;
        height: 51px;

        canvas {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    .name {
        display: flex;
        flex-direction: column;

        p {
            font-size: 10px;
            font-weight: 200;
        }
        h3 {
            font-size: 16px;
            font-weight: 400;
        }
    }
`
const StyledShrinkButton = styled(ShrinkButton)`
    position: absolute;
    top: 505px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 88px);
`

export const ItemModal = () => {
    const [itemToSell, itemToBuy, setItemToSell, setItemToBuy] = useMarketplace(state => [state.itemToSell, state.itemToBuy, state.setItemToSell, state.setItemToBuy])
    const [listItemsToMarketplace] = useCloud(state => [state.listItemToMarketplace])
    const visible = useMemo(() => itemToSell || itemToBuy, [itemToSell, itemToBuy])
    const type = useMemo(() => itemToSell ? 'sell' : 'buy', [itemToSell, itemToBuy])
    const [price, setPrice] = useState({
        price_gold: 0,
        price_nefesh: 0,
        price_ruach: 0,
        price_neshamah: 0,
        price_haya: 0
    })
    useEffect(() => {
        if (itemToBuy) {
            setPrice({
                price_gold: itemToBuy.price_gold,
                price_nefesh: itemToBuy.price_nefesh,
                price_ruach: itemToBuy.price_ruach,
                price_neshamah: itemToBuy.price_neshamah,
                price_haya: itemToBuy.price_haya
            })
            return
        }
        setPrice({
            price_gold: 0,
            price_nefesh: 0,
            price_ruach: 0,
            price_neshamah: 0,
            price_haya: 0
        })
    }, [itemToBuy, itemToSell])
    if (!visible) return null
    return (
        <StyledItemModal style={{ display: visible ? 'block' : 'none' }}>
            {
                type === 'sell' ? (
                    <StyledModal>
                        <img className="modal-background" src={`/assets/marketplace/item-modal-background.png`} alt="" width={420} height={630} />
                        <StyledItemTitle>Sell Item</StyledItemTitle>
                        <ItemInfo item={itemToSell} />
                        <Form price={price} />
                        <StyledShrinkButton onClick={() => {
                            listItemsToMarketplace(itemToSell.itemHash, { ...price })
                            setItemToSell(null)
                            setItemToBuy(null)
                        }}>Add to selling list</StyledShrinkButton>
                    </StyledModal>
                ) : (
                    <StyledModal>
                        <img className="modal-background" src={`/assets/marketplace/item-modal-background.png`} alt="" width={420} height={630} />
                        <StyledItemTitle>Buy Item</StyledItemTitle>
                        <ItemInfo item={itemToBuy} />
                        <Form price={price} disabled={true} />
                        <StyledShrinkButton onClick={() => {
                            // listItemsToMarketplace(itemToSell.itemHash, { ...price })
                            setItemToSell(null)
                            setItemToBuy(null)
                        }}>Buy Item</StyledShrinkButton>
                    </StyledModal>
                )
            }
            <div
                className="background"
                onClick={() => {
                    setItemToSell(null)
                    setItemToBuy(null)
                }}
            />
        </StyledItemModal>
    )
}

const Form = ({ price, disabled }: { price: { price_gold: number, price_nefesh: number, price_ruach: number, price_neshamah: number, price_haya: number }, disabled?: boolean }) => {
    return (
        <StyledForm>
            <StyledRow>
                <p>
                    <img src="/assets/shared/sefirah_of_nefesh.png" alt="Nefesh" width={16} height={16} />
                    Sefirah of Nefesh:
                </p>
                {/* <span className="x">x</span> */}
                <Input className="price-input" value={price.price_nefesh.toString()} onChange={e => price.price_nefesh = parseInt(e.target.value)} placeholder="0" disabled={disabled} />
            </StyledRow>
            <StyledRow>
                <p>
                    <img src="/assets/shared/sefirah_of_ruach.png" alt="Ruach" width={16} height={16} />
                    Sefirah of Ruach:
                </p>
                {/* <span className="x">x</span> */}
                <Input className="price-input" value={price.price_ruach.toString()} onChange={e => price.price_ruach = parseInt(e.target.value)} placeholder="0" disabled={disabled} />
            </StyledRow>
            <StyledRow>
                <p>
                    <img src="/assets/shared/sefirah_of_haya.png" alt="Haya" width={16} height={16} />
                    Sefirah of Haya:
                </p>
                {/* <span className="x">x</span> */}
                <Input className="price-input" value={price.price_haya.toString()} onChange={e => price.price_haya = parseInt(e.target.value)} placeholder="0" disabled={disabled}  />
            </StyledRow>
            <StyledRow>
                <p>
                    <img src="/assets/shared/sefirah_of_neshamah.png" alt="Neshamah" width={16} height={16} />
                    Sefirah of Neshamah:
                </p>
                {/* <span className="x">x</span> */}
                <Input className="price-input" value={price.price_neshamah.toString()} onChange={e => price.price_neshamah = parseInt(e.target.value)} placeholder="0" disabled={disabled}  />
            </StyledRow>
            <StyledRow>
                <p>
                    <img src="/assets/shared/gold.png" alt="Gold" width={16} height={16} />
                    Gold:
                </p>
                <Input className="gold-input" value={price.price_gold.toString()} onChange={e => price.price_gold = parseInt(e.target.value)} placeholder="0" disabled={disabled} />
            </StyledRow>
        </StyledForm>
    )
}

const ItemInfo = ({ item }: { item: MarketplaceItem | WarehouseItem }) => {
    return (
        <StyledItemInfo>
            <div className="view">
                <img src="/assets/marketplace/market-item-color.png" alt="" width={51} height={51} />
                <img src={`/assets/shared/items/item-wings.png`} alt="" width={51} height={51} />
            </div>
            <div className="name">
                <p>{ (item as MarketplaceItem)?.item?.itemParameters?.type || (item as WarehouseItem)?.itemAttributes?.itemParameters?.type }</p>
                <h3 style={{ color: getRandomColor() }}>{ (item as MarketplaceItem)?.item?.name || (item as WarehouseItem)?.itemAttributes?.name }  { Number((item as MarketplaceItem)?.item?.itemLevel || (item as WarehouseItem)?.itemAttributes?.itemLevel) > 0 ? '+'+Number((item as MarketplaceItem)?.item?.itemLevel || (item as WarehouseItem)?.itemAttributes?.itemLevel) : ''}</h3>
            </div>
        </StyledItemInfo>
    )
}