import VideoPlayer from "core/VideoPlayer/VideoPlayer"
import styled from "styled-components"
import { Modal } from "./Modal"

export const Auth = () => {
    return (
        <>
            <StyledWrapper>
                <VideoPlayer src="/auth/video.mp4" poster="/auth/poster.webp" className="video" />
                <img src="/auth/pixel.png" alt="" width={1920} height={1080} className="fade"/>
                <Modal/>
            </StyledWrapper>
        </>
    )
}

const StyledWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-height: 100lvh;

    .background {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
    }

    .video {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
    }

    .fade {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
    }

    .container {

    }
`