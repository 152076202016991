import { useFrame, useThree } from "@react-three/fiber"
import { useEffect, useRef } from "react"

export const DrawCalls = () => {
    const drawCallsSlot = useRef<HTMLElement | null>(null)
    const drawTriangledSlot = useRef<HTMLElement | null>(null)
    const drawPointsSlot = useRef<HTMLElement | null>(null)
    useEffect(() => {
        drawCallsSlot.current = document.querySelector('#drawCalls')
        drawTriangledSlot.current = document.querySelector('#drawTriangles')
        drawPointsSlot.current = document.querySelector('#drawPoints') 
    }, [])
    useFrame(({ gl }) => {
        drawCallsSlot.current.innerHTML = gl.info.render.calls+''
        drawTriangledSlot.current.innerHTML = gl.info.render.triangles+''
        drawPointsSlot.current.innerHTML = gl.info.render.points+''
    })

    return null
}