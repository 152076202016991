import { useTexture } from "@react-three/drei"
import { useMemo } from "react"

export const useBackpackSlotsMaps = () => {
    const u = '/assets/backpack-menu/'
    const textures = useTexture([
        u+'backpack-slot-color-map.png',
        u+'backpack-slot-allowed-color-map.png',
        u+'backpack-slot-disallowed-color-map.png',
        u+'backpack-slot-placeholder-color-map.png',
    ])
    const maps = {
        slotMap: textures[0],
        slotAllowedMap: textures[1],
        slotDisallowedMap: textures[2],
        slotPlaceholderMap: textures[3],
    }

    return useMemo(() => ({
        COMMON: maps.slotMap,
        INSERT_ALLOWED: maps.slotAllowedMap,
        INSERT_DISALLOWED: maps.slotDisallowedMap,
        LAST_PLACEHOLDER: maps.slotPlaceholderMap, 
    }), [])
}

export const useBackpackItemMaps = () => {
    const u = '/assets/backpack-menu/'
    const textures = useTexture([
        u+'backpack-slot-occupied-color-map.png',
        u+'backpack-slot-occupied-hover-color-map.png',
        u+'backpack-slot-occupied-intrade-color-map.png',
    ])
    const maps = {
        common: textures[0],
        hover: textures[1],
        intrade: textures[2],
    }

    return useMemo(() => ({
        COMMON: maps.common,
        HOVER: maps.hover,
        IN_TRADE: maps.intrade,
    }), [])
}

export const useBackpackItemMaps2 = () => {
    const u = '/assets/backpack-menu/'
    const textures = useTexture([
        u+'slot-occupied-color.png',
        u+'slot-occupied-hover-color.png',
        u+'slot-occupied-intrade-color.png',
    ])
    const maps = {
        common: textures[0],
        hover: textures[1],
        intrade: textures[2],
    }

    return useMemo(() => ({
        COMMON: maps.common,
        HOVER: maps.hover,
        IN_TRADE: maps.intrade,
    }), [])
}

export const useEquipmentSlotsMaps = () => {
    const u = '/assets/backpack-menu/'
    const typesTextures = useTexture([
        u+'equipment-slot-armour-color.png',
        u+'equipment-slot-boots-color.png',
        u+'equipment-slot-gloves-color.png',
        u+'equipment-slot-helmet-color.png',
        u+'equipment-slot-neckles-color.png',
        u+'equipment-slot-pants-color.png',
        u+'equipment-slot-ring-color.png',
        u+'equipment-slot-shield-color.png',
        u+'equipment-slot-sword-color.png',
        u+'equipment-slot-wings-color.png',
    ])
    const typesAllowedTextures = useTexture([
        u+'equipment-slot-armour-allowed-color.png',
        u+'equipment-slot-boots-allowed-color.png',
        u+'equipment-slot-gloves-allowed-color.png',
        u+'equipment-slot-helmet-allowed-color.png',
        u+'equipment-slot-neckles-allowed-color.png',
        u+'equipment-slot-pants-allowed-color.png',
        u+'equipment-slot-ring-allowed-color.png',
        u+'equipment-slot-shield-allowed-color.png',
        u+'equipment-slot-sword-allowed-color.png',
        u+'equipment-slot-wings-allowed-color.png',
    ])
    const typesDisallowedTextures = useTexture([
        u+'equipment-slot-armour-disallowed-color.png',
        u+'equipment-slot-boots-disallowed-color.png',
        u+'equipment-slot-gloves-disallowed-color.png',
        u+'equipment-slot-helmet-disallowed-color.png',
        u+'equipment-slot-neckles-disallowed-color.png',
        u+'equipment-slot-pants-disallowed-color.png',
        u+'equipment-slot-ring-disallowed-color.png',
        u+'equipment-slot-shield-disallowed-color.png',
        u+'equipment-slot-sword-disallowed-color.png',
        u+'equipment-slot-wings-disallowed-color.png',
    ])
    const tMaps = {
        armourMap: typesTextures[0],
        bootsMap: typesTextures[1],
        glovesMap: typesTextures[2],
        helmetMap: typesTextures[3],
        necklesMap: typesTextures[4],
        pantsMap: typesTextures[5],
        ringMap: typesTextures[6],
        shieldMap: typesTextures[7],
        swordMap: typesTextures[8],
        wingsMap: typesTextures[9],
    }
    const taMaps = {
        armourMap: typesAllowedTextures[0],
        bootsMap: typesAllowedTextures[1],
        glovesMap: typesAllowedTextures[2],
        helmetMap: typesAllowedTextures[3],
        necklesMap: typesAllowedTextures[4],
        pantsMap: typesAllowedTextures[5],
        ringMap: typesAllowedTextures[6],
        shieldMap: typesAllowedTextures[7],
        swordMap: typesAllowedTextures[8],
        wingsMap: typesAllowedTextures[9],
    }
    const tdMaps = {
        armourMap: typesDisallowedTextures[0],
        bootsMap: typesDisallowedTextures[1],
        glovesMap: typesDisallowedTextures[2],
        helmetMap: typesDisallowedTextures[3],
        necklesMap: typesDisallowedTextures[4],
        pantsMap: typesDisallowedTextures[5],
        ringMap: typesDisallowedTextures[6],
        shieldMap: typesDisallowedTextures[7],
        swordMap: typesDisallowedTextures[8],
        wingsMap: typesDisallowedTextures[9],
    }

    return useMemo(() => ({
        armour: {
            COMMON: tMaps.armourMap,
            INSERT_ALLOWED: taMaps.armourMap,
            INSERT_DISALLOWED: tdMaps.armourMap,
            LAST_PLACEHOLDER: tdMaps.armourMap, 
        },
        boots: {
            COMMON: tMaps.bootsMap,
            INSERT_ALLOWED: taMaps.bootsMap,
            INSERT_DISALLOWED: tdMaps.bootsMap,
            LAST_PLACEHOLDER: tdMaps.bootsMap, 
        },
        gloves: {
            COMMON: tMaps.glovesMap,
            INSERT_ALLOWED: taMaps.glovesMap,
            INSERT_DISALLOWED: tdMaps.glovesMap,
            LAST_PLACEHOLDER: tdMaps.glovesMap, 
        },
        helmet: {
            COMMON: tMaps.helmetMap,
            INSERT_ALLOWED: taMaps.helmetMap,
            INSERT_DISALLOWED: tdMaps.helmetMap,
            LAST_PLACEHOLDER: tdMaps.helmetMap, 
        },
        neckles: {
            COMMON: tMaps.necklesMap,
            INSERT_ALLOWED: taMaps.necklesMap,
            INSERT_DISALLOWED: tdMaps.necklesMap,
            LAST_PLACEHOLDER: tdMaps.necklesMap, 
        },
        pants: {
            COMMON: tMaps.pantsMap,
            INSERT_ALLOWED: taMaps.pantsMap,
            INSERT_DISALLOWED: tdMaps.pantsMap,
            LAST_PLACEHOLDER: tdMaps.pantsMap, 
        },
        ring: {
            COMMON: tMaps.ringMap,
            INSERT_ALLOWED: taMaps.ringMap,
            INSERT_DISALLOWED: tdMaps.ringMap,
            LAST_PLACEHOLDER: tdMaps.ringMap, 
        },
        shield: {
            COMMON: tMaps.shieldMap,
            INSERT_ALLOWED: taMaps.shieldMap,
            INSERT_DISALLOWED: tdMaps.shieldMap,
            LAST_PLACEHOLDER: tdMaps.shieldMap, 
        },
        sword: {
            COMMON: tMaps.swordMap,
            INSERT_ALLOWED: taMaps.swordMap,
            INSERT_DISALLOWED: tdMaps.swordMap,
            LAST_PLACEHOLDER: tdMaps.swordMap, 
        },
        wings: {
            COMMON: tMaps.wingsMap,
            INSERT_ALLOWED: taMaps.wingsMap,
            INSERT_DISALLOWED: tdMaps.wingsMap,
            LAST_PLACEHOLDER: tdMaps.wingsMap, 
        },
    }), [])
}

export const useToWarehouseSlotsMaps = () => {
    const u = '/assets/backpack-menu/'
    const textures = useTexture([
        u+'add-to-warehouse-color.png',
        u+'add-to-warehouse-hover-color.png',
    ])
    const maps = {
        slotMap: textures[0],
        slotAllowedMap: textures[1],
        slotDisallowedMap: textures[1],
        slotPlaceholderMap: textures[0],
    }

    return useMemo(() => ({
        COMMON: maps.slotMap,
        INSERT_ALLOWED: maps.slotAllowedMap,
        INSERT_DISALLOWED: maps.slotDisallowedMap,
        LAST_PLACEHOLDER: maps.slotPlaceholderMap, 
    }), [])
}