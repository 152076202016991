const getElementDocumentCoords = (domElement: HTMLElement) => {
    if (!domElement) {
        console.error('getElementDocumentCoords: no domElement found')
        return {
            top: null,
            bottom: null,
            left: null,
            right: null,
            height: null,
            width: null
        }
    }
    return {
        top: domElement.getBoundingClientRect().top + window.scrollY,
        bottom: domElement.getBoundingClientRect().bottom + window.scrollY,
        left: domElement.getBoundingClientRect().left + window.scrollX,
        right: domElement.getBoundingClientRect().right + window.scrollX,
        height: domElement.getBoundingClientRect().height,
        width: domElement.getBoundingClientRect().width,
    }
}

 export const isElementVisible = (domElement: HTMLElement) => {
    const domElementCoords = getElementDocumentCoords(domElement)
    if (domElementCoords.top === null || domElementCoords.bottom === null ||
        domElementCoords.left === null || domElementCoords.right === null ||
        domElementCoords.height === null || domElementCoords.width === null) {
        console.error("isElementVisible: No domElement found")
        return {
            partable: {
                x: false,
                y: false
            },
            fully: {
                x: false,
                y: false
            }
        }
    }
    return {
        partable: {
            x: domElementCoords.right >= 0 && domElementCoords.left <= window.innerWidth,
            y: domElementCoords.bottom >= window.scrollY && domElementCoords.top <= window.scrollY + window.innerHeight
        },
        fully: {
            x: domElementCoords.right <= window.innerWidth && domElementCoords.left >= 0,
            y: domElementCoords.bottom <= window.scrollY + window.innerHeight && domElementCoords.top >= window.scrollY
        }
    }
}