// import { OrbitControls } from "@react-three/drei"

export const Lights = () => {
    return (
        <>
                <ambientLight />
                {/* <OrbitControls /> */}
                <directionalLight
                    intensity={2}
                    color="#fff6d5"
                    position={[0, 1, 5]}
                    castShadow
                    shadow-mapSize-width={1024}
                    shadow-mapSize-height={1024}
                    shadow-camera-left={-20}
                    shadow-camera-right={20}
                    shadow-camera-top={20}
                    shadow-camera-bottom={-20}
                    shadow-bias={-0.0001}
                />
                <pointLight
                    intensity={200}
                    decay={1.5}
                    distance={9}
                    color="#faff99"
                    position={[0, 5, 0]}
                />
        </>
    )
}